import React, { useEffect, useState } from "react";
import { popup } from "utils/popup";
import axios from "axios";
import toast from "../utils/toast";
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  NavDropdown,
  Form
} from "react-bootstrap";
import { BASE_URL } from "apiUrl";

function Settings() {
    

    const [profileData,setProfileData]=useState({})
  const [updatedData,setUpdatedData]=useState({})
  const [formErros,setFormErrors]=useState({})
  const [showLoading,setShowLoading] = useState(false)
  const [loaded,setLoaded] = useState(0)
  const [changeCompanyLogo,setChangeCompanyLogo] = useState(null)
  const [twoFactorAuh,setTwoFactorAuth] = useState(false)
  const [updatedCompanyLogo,setUpdatedCompanyLogo] = useState(null)
   
  const [currentInput, setCurrentInput] = useState(1);
  const inputRefs = React.useRef([]);
 

  const changeProfileData= (e) =>{
        console.log([e.target.name],e.target.value)
    
        setUpdatedData({
          ...updatedData,
          [e.target.name]:e.target.value
        })

        setProfileData({
          ...profileData,
          [e.target.name]:e.target.value
        })
  }




  const formValidation = ()=>{
    
    if(profileData.currency.length<=0){
        setFormErrors({
          ...formErros,
          currency:{
            error:true,
            message:"Please fill the currency"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
    }
    
    
    
    else if(!isNaN(profileData.currency)){
      setFormErrors({
        ...formErros,
        currency:{
          error:true,
          message:"Only character allowed"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else{
     
      delete formErros.currency
      
    }
    
    
  


  //validating for smtp_email


  if(!profileData.smtp_email){
    setFormErrors({
      ...formErros,
      smtp_email:{
        error:true,
        message:"Please fill the smtp email"
      }
    })
    popup.error("Form not submited ! Please fill all required fields")
    return false
  }else if(!validateEmail(profileData.smtp_email)){
  setFormErrors({
    ...formErros,
    smtp_email:{
      error:true,
      message:"Please enter a valid smtp email"
    }
  })
  popup.error("Form not submited ! Please fill all required fields")
  return false
  }else{
       
  delete formErros.smtp_email
  
  }
  
  //validate smtp_email_password
      
      
      if(!profileData.smtp_email_password){
          setFormErrors({
            ...formErros,
            smtp_email_password:{
              error:true,
              message:"Please fill the smtp email password"
            }
          })
          popup.error("Form not submited ! Please fill all required fields")
          return false
      }
      
      
      else if(profileData.smtp_email_password.trim().length<3){
        setFormErrors({
          ...formErros,
          smtp_email_password:{
            error:true,
            message:"Please carefully fill the smtp password"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      }
      else{
       
        delete formErros.smtp_email_password
        
      }
    return true
    }


  const updateProfile =async (e)=>{
        e.preventDefault()
    
    
        if(formValidation()){
            setShowLoading(true)
            let form_data = new FormData();
            console.log(profileData.currency)
            form_data.append('currency',profileData.currency)
            form_data.append('smtp_email',profileData.smtp_email)
            form_data.append('two_factor_auth',twoFactorAuh)
            form_data.append('smtp_email_password',profileData.smtp_email_password)
                 
            if(updatedCompanyLogo){
              form_data.append('company_logo', updatedCompanyLogo, updatedCompanyLogo.name)
            }     
    
            const token = localStorage.getItem('token')
            const JWTToken = JSON.parse(token)
            const url =`${BASE_URL}/api/user/profile/`
    


            const config = {
       
            headers:{
                "Content-Type": "multipart/form-data",
                "Authorization" : "Bearer "+JWTToken
            },
            onUploadProgress: (p) => {
                // console.log(Math.ceil((p.loaded/p.total)*100))
                // setLoaded(Math.ceil((p.loaded/p.total)*100))
                setLoaded(p.loaded)
            }
        }
        try{

            const response = await axios.put(url,form_data,config)

            if(response.status===200){
                popup.success("Settings updated successfully");
                setProfileData(response.data)
                localStorage.setItem('currency', response.data.currency);
                setTwoFactorAuth(response.data.two_factor_auth)                           
            }
            else if (response.status===400){
                popup.error("Settings not updated successfully")            
            }

            else if (response.status === 401){
              localStorage.clear()
              window.location.href = '/'
            }
            setShowLoading(false)
            setLoaded(0)
        }
        catch(err){
            console.log(err)
            setShowLoading(false)
            setLoaded(0)
         }
    }

  }

  const validateEmail=(email)=> {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    useEffect(()=>{
        const token = localStorage.getItem('token')
        const JWTToken = JSON.parse(token)
       
        fetch(`${BASE_URL}/api/user/profile/`,{
          headers:{
              "Authorization" : "Bearer "+JWTToken
          }
          })
        .then((response) =>{
           if(response.status===200){
            response.json().then((resp)=>{
              console.log(resp)
              setProfileData(resp)
              setTwoFactorAuth(resp.two_factor_auth) 
              
            })
           }
           else if(response.status===401){
             //alert('not authenticated')
             localStorage.clear()
             window.location.href = '/'
           }
           })
      },[])
      
      const addCompanyLogo = (e)=>{
    
        setUpdatedCompanyLogo(e.target.files[0])
        setChangeCompanyLogo(URL.createObjectURL(e.target.files[0]))
        
      }
      console.log(twoFactorAuh)


       const handleKeyDown = (event, index) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      let nextIndex = index + 1;
      if (nextIndex < inputRefs.current.length) {
        try{
          inputRefs.current[nextIndex].focus();
        }catch{
          nextIndex = nextIndex+1;
          inputRefs.current[nextIndex].focus();
        }
        setCurrentInput(nextIndex);
        return 
      }
      // here submit the form
      console.log('Submiting.....');
      updateProfile(event);
      return 
    }else if (event.key === 'Backspace') {
      const currentValue = event.target.value;
      if (currentValue === '' && index > 0) {
        let prevIndex = index - 1;
        try{
          inputRefs.current[prevIndex].focus();
        }catch{
          prevIndex = prevIndex -1;
          inputRefs.current[prevIndex].focus();
        }
        setCurrentInput(prevIndex);
      }
     return 
    }
  };

  return (
    <>
      <Container fluid >
        <Row>
          <Col md="4" className="order-md-2">
          <Card className="card-user">
              <div className="card-image">
                <img
                  alt="..."
                  src={
                    require("assets/img/photo-1431578500526-4d9613015464.jpeg")
                      .default
                  }
                ></img>
              </div>
              <Card.Body>
                <div className="author">
                
                    <img
                      alt="..."
                      className="avatar border-gray"
                      src={changeCompanyLogo?changeCompanyLogo:BASE_URL+profileData.company_logo}
                      onError={({ currentTarget }) => {
                        console.log('on error')
                        currentTarget.onerror = null; 
                        currentTarget.src= require("assets/img/logo.png").default
                      }}
                    />
                    
                    
            


 
                <Form.Group>
                       
                       <label htmlFor="file-upload-logo" className="custom-file-upload-logo">
                           <i style={{fontWeight:900,fontSize:"15px"}} className="fas fa-pencil-alt"></i> 
                       </label>
                       <input name="company_logo" accept="image/*" id="file-upload-logo" type="file" onChange={(e)=>{addCompanyLogo(e)}}/>
                 </Form.Group>


                    <h5 style={{textTransform:"capitalize"}} className="title"><b> {profileData.company_name}</b></h5>
                    <p style={{fontSize:"12px"}}>*Change your company logo from here.</p>
                  </div>
                
                
              </Card.Body>

              
              
              
            </Card>

          </Col>
           <Col md="8" className="order-md-1">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Settings <i className="fas fa-cogs mt-1"></i></Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>

                  <Row>
                    <Col md="12" className="ml-2">
                    
                    <Form.Group>
                        <label>Currency</label>
                        <Form.Control
                          ref={(el) => (inputRefs.current[0] = el)}
                          className={formErros.currency?"errors":""}
                          value={profileData.currency}
                          placeholder="Currency"
                          type="text"
                          name="currency"
                          onChange={(e)=>{changeProfileData(e)}} 
                          onKeyDown={(event) => handleKeyDown(event, 0)}
                        ></Form.Control>
                        {formErros.currency?<p style={{color:"red"}}>*{formErros.currency.message}</p>:""}
                        
                        
                    </Form.Group>
                    
                    </Col>
                     
                  </Row>
                  <Row>
                    <Col  md="6">
                      <Form.Group>
                        <label>SMTP EMAIL</label>
                        <Form.Control
                          ref={(el) => (inputRefs.current[1] = el)}
                          className={formErros.smtp_email?"errors":""}
                          value={profileData.smtp_email}
                          placeholder="SMTP Email"
                          type="email"
                          name="smtp_email"
                          onChange={(e)=>changeProfileData(e)}
                          autoComplete={false}
                          onKeyDown={(event) => handleKeyDown(event, 1)}
                        ></Form.Control>
                        {formErros.smtp_email?<p style={{color:"red"}}>*{formErros.smtp_email.message}</p>:""}
                        
                      </Form.Group>
                    </Col>
                    <Col  md="6">
                      <Form.Group>
                        <label>SMTP Email App Password</label>
                        <Form.Control
                          ref={(el) => (inputRefs.current[2] = el)}
                          className={formErros.smtp_email_password?"errors":""}
                          value={profileData.smtp_email_password}
                          placeholder="SMTP Email App Password"
                          type="password"
                          name="smtp_email_password"
                          onChange={(e)=>changeProfileData(e)}
                          autoComplete="new-password"
                          onKeyDown={(event) => handleKeyDown(event, 2)}
                        ></Form.Control>
                        {formErros.smtp_email_password?<p style={{color:"red"}}>*{formErros.smtp_email_password.message}</p>:""}
                        
                      </Form.Group>
                    </Col>

                    <Col md="12" className="ml-3">
                      <Form.Group>
                        <label>Enable Two Factor Authentication</label>
                        <input 
                        ref={(el) => (inputRefs.current[3] = el)}
                        className="mt-2 ml-3" 
                        type="checkbox"
                        name="two_factor_auth"
                        checked={twoFactorAuh}
                        onChange={(e)=>{
                            if(e.target.checked){
                              setTwoFactorAuth(true)
                            }else{
                              setTwoFactorAuth(false)
                            }
                        }}
                        onKeyDown={(event) => handleKeyDown(event, 3)}
                        />
                       
                      </Form.Group>
                    </Col>

                  </Row>
                  <br></br>
                 
                  <Button
                    className="btn-fill pull-right"
                    type="submit"
                    variant="info"
                    onClick={(e)=>{window.scrollTo(0, 0);updateProfile(e);}}
                  >
                    Update Profile
                  </Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          
        </Row>
      </Container>   
    </>
  );
}

export default Settings
