import React, { useState } from "react";
import { popup } from "utils/popup";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table
} from "react-bootstrap";
import Sidebar from "components/Sidebar/Sidebar";
import toast from "../utils/toast";
import sidebarImage from "assets/img/sidebar-3.jpg";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import { Link } from "react-router-dom";
import routes from "routes.js";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { BASE_URL } from "apiUrl";

function participants() {

  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const [data, setdata] = useState([]);
  const mainPanel = React.useRef(null);
  const [submited, setSubmited] = useState(false)
  let history = useHistory();
  let { id } = useParams();
  console.log(id)



  React.useEffect(() => {
    meetingParticipantsDetails()
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);



  const meetingParticipantsDetails = () => {

    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/meeting-management/meeting-participants/${id}/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setdata(data)

          })
        }
        else if (response.status === 401) {
          // alert('not authenticated')
          localStorage.clear()
          window.location.href = '/'
        }
      })


  }

  const deleteParticipant = (id) => {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
    let url = `${BASE_URL}/api/meeting-management/meeting-participants/${id}/`
    fetch(url, {
      method: "DELETE",
      headers: {

        "Authorization": "Bearer " + JWTToken
      }
    }).then(response => {
      if (response.status === 200) {

        popup.success("Participant deleted successfully");
        meetingParticipantsDetails()
      }
      else if (response.status === 400) {
        popup.error("Participant not deleted successfully");
      }
      else if(response.status===401){
          localStorage.clear()
          window.location.href = '/'
      }
    })

  }

  return (
    <>
      <div className="wrapper">
        <Sidebar color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">

            <Container fluid>
              <Row>
                <Col md="12">
                  <Card className="strpied-tabled-with-hover">
                    <Card.Header>
                      <Card.Title as="h4">Meeting Participants</Card.Title>
                    </Card.Header>
                    <Card.Body className="table-full-width table-responsive px-0">
                      <Table className="table-hover table-striped">
                        <thead>
                          <tr>
                            <th className="border-0">Employee/Client</th>
                            <th className="border-0">Full Name</th>
                            <th className="border-0">CNIC</th>
                            <th className="border-0">Mobile No </th>
                            <th className="border-0">Remove from meeting</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((item) =>
                            <tr>
                              <td style={{ textTransform: "capitalize" }}>{item.participant_type}</td>
                              <td style={{ textTransform: "capitalize" }}>{item.participant_type == "employee" ? item.employee.first_name + " " + item.employee.last_name : item.client.first_name + " " + item.client.last_name}</td>
                              <td style={{ textTransform: "capitalize" }}>{item.participant_type == "employee" ? item.employee.cnic : item.client.cnic}</td>
                              <td style={{ textTransform: "capitalize" }}>{item.participant_type == "employee" ? item.employee.mobile_number : item.client.phone_no}</td>
                              <td style={{ textTransform: "capitalize" }}><button className="btn btn-danger" onClick={() => deleteParticipant(item.id)} > Remove</button></td>
                            </tr>
                          )}


                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>

              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </div>


    </>
  );
}

export default participants;
