import React, { useState } from "react";
import { popup } from "utils/popup";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import Sidebar from "components/Sidebar/Sidebar";
import sidebarImage from "assets/img/sidebar-3.jpg";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import { Link } from "react-router-dom";
import toast from "../utils/toast";
import { useParams } from "react-router-dom";
import routes from "routes.js";
import { BASE_URL } from "apiUrl";

function EditBank() {

  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const mainPanel = React.useRef(null);


  const [data, setdata] = useState({});
  const [formErros, setFormErrors] = useState({})

  const [currentInput, setCurrentInput] = useState(1);
  const inputRefs = React.useRef([]);

  let { id } = useParams();
  console.log(id)

  async function getBankdata() {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
    let item = id
    let url = `${BASE_URL}/api/bank-management/bank/${item}/`
    let result = await fetch(url, {
      method: "GET",
      headers: {

        "Authorization": "Bearer " + JWTToken
      }
    })

    result = await result.json();
    setdata(result);

  }

  React.useEffect(() => {
    getBankdata();
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);


  const formValidation = () => {


    //bank name validation 
    if (!data.bank_name) {
      setFormErrors({
        ...formErros,
        bank_name: {
          error: true,
          message: "Please fill the bank name"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    } else if (data.bank_name.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        bank_name: {
          error: true,
          message: "Please fill the bank name"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else if (!isNaN(data.bank_name)) {
      setFormErrors({
        ...formErros,
        bank_name: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else {

      delete formErros.bank_name

    }

    //validate account no 

    if (!data.account_no) {
      setFormErrors({
        ...formErros,
        account_no: {
          error: true,
          message: "Please fill the account no "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else if (data.account_no.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        account_no: {
          error: true,
          message: "Please fill the account no "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else if (isNaN(data.account_no)) {
      setFormErrors({
        ...formErros,
        account_no: {
          error: true,
          message: "Only digits allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else {

      delete formErros.account_no

    }


    //validate account titile

    if (!data.account_title) {
      setFormErrors({
        ...formErros,
        account_title: {
          error: true,
          message: "Please fill the account title "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else if (data.account_title.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        account_title: {
          error: true,
          message: "Please fill the account title "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else if (!isNaN(data.account_title)) {
      setFormErrors({
        ...formErros,
        account_title: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else {

      delete formErros.account_title

    }

    //branch name
    if ((!data.branch_name) || data.branch_name.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        branch_name: {
          error: true,
          message: "Please fill branch name"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else if (!isNaN(data.branch_name)) {
      setFormErrors({
        ...formErros,
        branch_name: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else {

      delete formErros.branch_name

    }
    return true


  }



  function updatebank(e) {
    e.preventDefault();

    if (formValidation()) {
      const token = localStorage.getItem('token')
      const JWTToken = JSON.parse(token)

      const body = JSON.stringify(data)
      const url = `${BASE_URL}/api/bank-management/bank/${id}/`
      fetch(url, {
        method: 'PUT',
        headers: {
          "Authorization": "Bearer " + JWTToken,
          'Content-Type': 'application/json'

        },
        body: body

      }).then(response => {
        if (response.status === 200) {
          response.json().then(data => {
            setdata(data)
            popup.success("Bank updated successfully");
          })
        }
        else if (response.status === 400) {
          popup.success("Bank not updated successfully");
        }
      })
    }


  }

  const updateData = (e) => {
    setdata({
      ...data,
      [e.target.name]: e.target.value
    })
  }


   const handleKeyDown = (event, index) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.current.length) {
        inputRefs.current[nextIndex].focus();
        setCurrentInput(nextIndex);
        return 
      }
      // here submit the form
      console.log('Submiting.....');
      updatebank(event);
      return 
    }else if (event.key === 'Backspace') {
      const currentValue = event.target.value;
      if (currentValue === '' && index > 0) {
        const prevIndex = index - 1;
        inputRefs.current[prevIndex].focus();
        setCurrentInput(prevIndex);
      }
     return 
    }
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar activeLink="/bank" color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Container fluid>
              <Row>
                <Col md="8">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">Edit Bank</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={(e) => updatebank(e)}>

                        <Row>
                        </Row>

                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <label>Bank Name</label>

                              <Form.Control
                                ref={(el) => (inputRefs.current[0] = el)}
                                className={formErros.bank_name ? "errors" : ""}
                                placeholder="Bank Name"
                                defaultValue={data.bank_name}
                                type="text"
                                name="bank_name"
                                onChange={(e) => updateData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 0)}
                              >

                              </Form.Control>
                              {formErros.bank_name ? <p style={{ color: "red" }}>*{formErros.bank_name.message}</p> : ""}

                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group>
                              <label>Account no</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[1] = el)}
                                className={formErros.account_no ? "errors" : ""}
                                placeholder="Account no"
                                defaultValue={data.account_no}
                                type="text"
                                name="account_no"
                                onChange={(e) => updateData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 1)}
                              ></Form.Control>
                              {formErros.account_no ? <p style={{ color: "red" }}>*{formErros.account_no.message}</p> : ""}

                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="12" sx="12" md="6">
                            <Form.Group>
                              <label>Account title</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[2] = el)}
                                className={formErros.account_title ? "errors" : ""}
                                placeholder="Account title"
                                type="text"
                                defaultValue={data.account_title}
                                name="account_title"
                                onChange={(e) => updateData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 2)}
                              ></Form.Control>
                              {formErros.account_title ? <p style={{ color: "red" }}>*{formErros.account_title.message}</p> : ""}

                            </Form.Group>
                          </Col>

                          <Col md="6">
                            <Form.Group>
                              <label>Bank Branch</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[3] = el)}
                                className={formErros.branch_name ? "errors" : ""}
                                placeholder="Bank Branch"
                                defaultValue={data.branch_name}
                                type="text"
                                name="branch_name"
                                onChange={(e) => updateData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 3)}
                              ></Form.Control>
                              {formErros.branch_name ? <p style={{ color: "red" }}>*{formErros.branch_name.message}</p> : ""}

                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                        </Row>
                        <br />
                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                          onClick={updatebank}
                        >
                          Edit Bank
                        </Button>
                        <div className="clearfix"></div>

                      </Form>
                    </Card.Body>
                  </Card>
                </Col>

              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </div>


    </>
  );
}

export default EditBank;
