import React, { useEffect, useState } from "react";
import toast from "../utils/toast";

import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  NavDropdown,
  Form,
} from "react-bootstrap";
import { BASE_URL } from "apiUrl";
import { } from "react-router-dom"

function Talent() {

  const [subscriptionDetails, setSubscriptionDetails] = useState({})

  useEffect(() => {
    getSubscriptionDetails()
  }, [])

  const getSubscriptionDetails = () => {


    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/subscriptions/details/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status == 200) {
          response.json().then(data => {
            setSubscriptionDetails(data)
            console.log(data)
          })
        }
        else if (response.status == 400) {
          response.json().then(data => {
            console.log(data)
          })
        }
        else if(response.status == 401){
          localStorage.clear()
          window.location.href = '/'
        }



      }).catch(err => {

      })

  }

  const storeToken = (token) => {
    document.cookie = `token=${token}; expires=${getCookieExpirationDate()}; domain=officehassle.com; path=/`
  }

  const getCookieExpirationDate = () => {
    return undefined
  }

  const redirectToProjectManagment = (target) => {
    storeToken(JSON.parse(localStorage.getItem('token')))
    window.open("https://talent.officehassle.com/talent", target)
  }

  return (
    <>

      <Container fluid>
        <Row>

          <Col md="12 mt-3">
            <h3 className="text-center">Access Talent By &copy;OfficeHassle.com</h3>
            <div className="text-center mt-3">
              {
                subscriptionDetails?.subscribe ? <>

                  <Button className="btn-warning m-1" onClick={() => redirectToProjectManagment("_self")}>Open In Same Window</Button>


                  <Button className="btn-success m-1" onClick={() => redirectToProjectManagment("_blank")}>Open In Separate  Window</Button>

                </> : <></>
              }
            </div>
          </Col>

        </Row>
      </Container>

    </>
  );
}

export default Talent;
