
import React, { Component, useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { BASE_URL } from "apiUrl";
import logo from "assets/img/reactlogo.png";

function Sidebar({ color, image, routes, activeLink = null }) {
  const location = useLocation();
  const [profileData, setProfileData] = useState(null)
  useEffect(() => {
    console.log('side bar')
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/user/profile/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((resp) => {
            setProfileData(resp)
            console.log(profileData)
          })
        }
        else if (response.status === 401) {
          //alert('not authenticated')
        }
      })
  }, [])

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  return (
    <div className="sidebar" data-image={image} >
      <div
        className="sidebar-background"
        style={{
          backgroundSize: "cover!important",
          background: "linear-gradient( " + color + " 100%, rgba(0, 0, 0, 0.5)100%),url(" + image + ")",
        }}
      />
      <div className="sidebar-wrapper">
        {profileData ? <div className="logo d-flex align-items-center justify-content-start">
          <a
            className="simple-text logo-mini mx-1"
          >
            <div className="logo-img">
              {/* <img
                height="100"
                src={BASE_URL+profileData.photo}
                alt="..."
              /> */}
            </div>
          </a>
          <a className="simple-text ml-4" >
            {profileData.company_name}
          </a>
        </div> : ""}
        <Nav>
          {routes.map((prop, key) => {
            if (!prop.redirect && prop.path !== "/projectManagement/milestones" && prop.path !== "/projectManagement/tasks")

              return (
                <li
                  style={prop.name === "User Profile" ? { display: "none" } : {}}
                  className={
                    prop.path === activeLink ? "active" :
                      prop.upgrade
                        ? "active active-pro"
                        : activeRoute(prop.layout + prop.path)
                  }
                  key={key}
                >
                  <NavLink
                    to={prop.layout + prop.path}
                    className={prop.path === activeLink ? "active nav-link" : "nav-link"}
                    activeClassName="active"

                  >
                    <i className={prop.icon} />
                    <p  >{prop.name}</p>
                  </NavLink>
                </li>
              );
            return null;
          })}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
