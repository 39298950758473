import "../assets/css/register.css"
import { Link } from "react-router-dom";
const accountVarieficationSuccess=()=>{
    
    
    return(
        <div className="container-fluid bod">

  <div className="container-fluid">
    <div className="container-fluid register">
    <div className="row">
      <div className="col-md-5 register-left">
        <img src={require("assets/img/office_hassle_512x512.png").default} alt="" />
        <h3>Welcome</h3>
        <p>Management System <br/> &copy; OfficeHassle.com {new Date().getFullYear()}</p>
        <p></p>
        </div>
      <div className="col-md-7   register-right">
       <div className='mt-5'>
        <ul className="nav nav-tabs text-right nav-justified" id="myTab" role="tablist">
                            
                            <li className="nav-item text-center mt-4">
                            
                            <i className="fas fa-check-circle" style={{fontSize:"80px",color:"#00c6ff"}}></i>
                            
                            <p className="mt-4" style={{color:"black"}}>Account veriefication successfully completed.</p>
                            <p style={{color:"black",marginTop:"-14px"}}>Please contact with admin to activate your account.</p>
                            
                            <Link  className="ml-5" to="/login/" style={{color:"#0062cc"}}><b> LOGIN </b></Link>
                            
                            </li>
                            
                            
        </ul>
           
        </div>
       
      </div>
    </div>
  </div>
</div>

</div>
    )
}
export default accountVarieficationSuccess;