import React, { useState, useEffect } from "react";
import { popup } from "utils/popup";
import axios from "axios";
import SweetAlert from 'react-bootstrap-sweetalert';
import { BASE_URL } from "apiUrl";
import { useHistory } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import moment from "moment";
const MyPaginate = styled(ReactPaginate).attrs({

})`
  margin-bottom: 2rem;
  margin-top:30px;
  display:flex;
  justify-content: center;
  list-style-type: none;
  padding: 0 5rem;
  li a {
    margin: 4px;
    float:left;
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.selected a {
    background-color: #1DC7EA;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;


import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table,
  NavDropdown
}
  from "react-bootstrap";
import Sidebar from "components/Sidebar/Sidebar";
import sidebarImage from "assets/img/sidebar-3.jpg";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import { Link } from "react-router-dom";
import toast from "../utils/toast";
import GenerateTaxSheetModel from "./GenerateTaxSheetModel";
import routes from "routes.js";
import LockedContent from "components/LockedContent/LockedContent";

function tax() {

  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const [data, setdata] = useState([])
  const [allData, setAllData] = useState([])
  const [deleted, setDeleted] = useState(false)
  const [currency, setCurrency] = useState('')
  const mainPanel = React.useRef(null);
  const [pressDelete, setPressDelete] = useState(false)
  const [totalTaxes, setTotalTaxes] = useState(0)
  const [totalPaidTaxes, setTotalPaidTaxes] = useState(0)
  const [id, setId] = useState(null)
  const [reload, setReload] = useState(false)
  const [haveSubscription, setHaveSubscription] = useState(false)
  const history = useHistory()


  function deletePaidTax(id) {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
    let item = id
    let url = `${BASE_URL}/api/tax-management/paid-tax/${item}/`
    fetch(url, {
      method: "DELETE",
      headers: {

        "Authorization": "Bearer " + JWTToken
      }
    }).then(response => {
      if (response.status === 200) {

        popup.success("Paid tax deleted successfully");
        setReload(!reload)
        setDeleted(!deleted)
      }
      else if (response.status === 400) {
        popup.success("Paid tax not deleted successfully");
      }
    })

  }


  const getCurrency = () => {

    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/user/profile/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          setHaveSubscription(response.headers.get('have-subscription') === "True" ? true : false)
          response.json().then((resp) => {

            setCurrency(resp.currency)

          })
        }
        else if (response.status === 401) {
          localStorage.clear()
          window.location.href = '/'
        }
      })
  }



  React.useEffect(() => {

    getAllPaidTaxes()
    getCurrency()


  }, [deleted, reload]);



  const getAllPaidTaxes = () => {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/tax-management/paid-taxes/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setdata(data.taxes)
            setTotalPaidTaxes(data.total_paid_taxes)
            setTotalTaxes(data.total_taxes)
            setAllData(data.taxes)
            console.log(data)

          })
        }
        else if (response.status === 401) {
          //alert('not authenticated')
          localStorage.clear()
          window.location.href = '/'
        }
      })
  }



  function setAsPayable(id) {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/tax-management/paid-tax/${id}/`, {
      method: 'PUT',
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            popup.success("Succesfully set as payable.")
            setReload(!reload)
          })
        }
        else {
          popup.error("Not succesfully set as payable.")
        }
      })
  }

  function setAsPaid() {

    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
    let now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    let currentDateTime = now.toISOString().slice(0, 16)
    let rawDate = new Date(currentDateTime + 'Z')
    const body = JSON.stringify({
      paid_at: rawDate.toISOString()
    })
    fetch(`${BASE_URL}/api/tax-management/all-pending-taxes-payment/`, {
      method: 'POST',
      headers: {
        "Authorization": "Bearer " + JWTToken,
        'Content-Type': 'application/json',
      },
      body: body
    }).then(res => {
      if (res.status == 200) {
        setReload(!reload)
        popup.success("Taxes paid successfully!")
      } else if (res.status == 400) {
        popup.error("Some error occur.")
      }

    }).catch(err => {
      popup.error("Some error occur.")
    })
  }

  const Taxes = ({ currentItems }) => {
    const navDropdownTitle = (<i className="fas fa-ellipsis-v"></i>);

    return <>
      {currentItems.map((item) =>
        <tr>
          <td style={{ textTransform: "capitalize" }}>{item.employee.first_name} {item.employee.last_name}</td>
          <td style={{ textTransform: "capitalize" }}>{item.status}</td>
          <td style={{ textTransform: "capitalize" }}>{currency} {item.amount}</td>
          <td>{item?.created_at ? moment(item.created_at.split('Z')[0]).format('MMMM DD, YYYY h:mm:ss A') : ''}</td>
          <td>{item?.paid_at ? moment(item.paid_at.split('Z')[0]).format('MMMM DD, YYYY h:mm:ss A') : ''}</td>


          <NavDropdown title={navDropdownTitle} id="basic-nav-dropdown">

            <NavDropdown.Item onClick={() => setAsPayable(item.id)}>Set As Payable</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={() => { setPressDelete(true); setId(item.id) }}>Delete</NavDropdown.Item>

          </NavDropdown>

        </tr>
      )}

    </>
  }

  const PaginateTaxes = ({ itemsPerPage }) => {
    const [currentItems, setCurrentItems] = useState(data);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(data.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % data.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <div class="tableWrap">
          <Table className="table-hover table-striped">
            <thead>
              <tr>
                <th className="border-0">Tax By(Employee)</th>
                <th className="border-0">Status</th>
                <th className="border-0">Amount</th>
                <th className="border-0">Created At</th>
                <th className="border-0">Paid At</th>
                <th className="border-0">Actions</th>
              </tr>
            </thead>
            <tbody>

              <Taxes currentItems={currentItems} />

            </tbody>
          </Table>
        </div>
        <MyPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />

      </>
    );
  }

  const searchPaidTax = (e) => {
    console.log(e.target.value)
    let query = e.target.value.trim()
    if (query !== "") {

      let matched = data.filter((tax) => tax.employee.first_name.toUpperCase() === query.toUpperCase() || tax.employee.last_name.toUpperCase() === query.toUpperCase() || tax.employee.first_name.toUpperCase() + " " + tax.employee.last_name.toUpperCase() === query.toUpperCase())
      console.log(matched)
      if (matched.length > 0) {
        setdata(matched)
      } else {
        setdata(allData)
      }
    }
  }


  const downloadSheet = () => {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
    axios({
      url: `${BASE_URL}/api/tax-management/download-pay-able-tax-sheet/`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + JWTToken
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'tax_sheet.xlsx');
      document.body.appendChild(link);
      link.click();

    }).catch(err => {
      console.log(err)
      popup.error("Some error occur.")
    })
  }


  return (
    <>
      {
        pressDelete ?
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            title="Are you sure?"
            onConfirm={() => { deletePaidTax(id); setPressDelete(false); setId(null) }}
            onCancel={() => { setPressDelete(false); setId(null) }}
            focusCancelBtn
          >
            You will not be able to recover tax data!
          </SweetAlert> : ""
      }
      <Container fluid>

        <Row>
          <Col lg="6" sm="12" >
            {!haveSubscription && <LockedContent fontSize={18} top={'40%'} left={'50%'} />}
            <Card className={`card-stats ${!haveSubscription && " blury-effect"}`} style={{ height: "80%" }}>
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fas fa-coins text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7" >
                    <div className={`numbers`}>
                      <p className="card-category">Payable Tax</p>
                      <Card.Title as="h4"> {currency} {totalTaxes - totalPaidTaxes <= 0 ? "0.00" : eval(totalTaxes - totalPaidTaxes)}  </Card.Title>

                      {
                        totalTaxes - totalPaidTaxes <= 0 ? "" : <Button className="btn-sm btn-success" onClick={() => setAsPaid()}>Set As Paid</Button>
                      }

                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>

              </Card.Footer>
            </Card>
          </Col>
          <Col lg="6" sm="12">
            {!haveSubscription && <LockedContent fontSize={18} top={'40%'} left={'50%'} />}
            <Card className={`card-stats ${!haveSubscription && " blury-effect"}`} style={{ height: "80%" }}>
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fas fa-coins text-success"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Total Tax Paid</p>
                      <Card.Title as="h4">{currency} {totalPaidTaxes ? totalPaidTaxes : 0}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>

              </Card.Footer>
            </Card>
          </Col>

        </Row>
        <Row>
          <Col md="12">
            {!haveSubscription && <LockedContent fontSize={18} top={'40%'} left={'50%'} />}
            <Card className={`strpied-tabled-with-hover ${!haveSubscription && " blury-effect"}`}>
              <Card.Header>
                <Card.Title as="h4">List of Paid Taxes</Card.Title>
                <div className="text-right">
                  <Link onClick={(e) => {
                    e.preventDefault()
                    downloadSheet()
                  }}>
                    {
                      totalTaxes - totalPaidTaxes <= 0 ? "" : <Button className="btn btn-primary">Generate Payable Tax Sheet</Button>
                    }

                  </Link>
                </div>
                <br />
                <Form.Control
                  defaultValue=""
                  placeholder="Search Tax By Employee Name...🔍"
                  type="text"
                  onChange={(e) => { searchPaidTax(e) }}
                >

                </Form.Control>

              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <PaginateTaxes itemsPerPage={15} />
              </Card.Body>
            </Card>
          </Col>

        </Row>
      </Container>

    </>

  )
}

export default tax;
