import React, { useState } from "react";
import axios from "axios";
import { popup } from "utils/popup";

import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col
}
  from "react-bootstrap";
import Sidebar from "components/Sidebar/Sidebar";
import sidebarImage from "assets/img/sidebar-3.jpg";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import { Link } from "react-router-dom";
import toast from "../utils/toast";
import { useHistory } from "react-router-dom";
import routes from "routes.js";
import { BASE_URL } from "apiUrl";
import ClientDropdown from "./ClientDropdown";


function AddInvoice() {

  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const mainPanel = React.useRef(null);

  const history = useHistory()

  const [clientType, setClientType] = useState('existing');
  const [projectType, setProjectType] = useState('existing');
  const [allClients, setAllClients] = useState([]);
  const [clientData, setClientData] = useState({
    firstName : '',
    lastName : '',
    mobileNumber : '',
    address : '',
    id : '',
  });


  const [formErros, setFormErrors] = useState({});
  const [selectedClient, setSelectedClient] = useState({});
  const [itemCount, setItemCount] = useState(1);
  
  const [invoiceItems, setInvoiceItems] = useState([{
    id : 1, 
    description : '',
    quantity : 0,
    pricePerUnit : 0,
    errors : {
      'description' : null,
      'quantity' : null,
      'pricePerUnit' : null
    }
  }, ]);

  const [selectedExistingProject, setSelectedExistingProject] = useState('');
  const [selectedCustomProject, setSelectedCustomProject] = useState('')
  const [customProject, setCustomProject] = useState('');
  const [transactionMethod, setTransactionMethod] = useState('PayPal');
  const [salesTax, setSalesTax] = useState(0)
  const [dueDate, setDueDate] = useState(null)


  const handleClientSelect = (clientId)=>{
    setSelectedClient(()=>{
      return allClients.find((cl)=> cl.id==clientId);
    })
    setSelectedExistingProject('');
    setSelectedCustomProject('');
  }

  

  React.useEffect(() => {


    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);


  

  React.useEffect(()=>{
    getAllClients()
   },[])
 
 
   const getAllClients = ()=>{
       
     const token = localStorage.getItem('token')
     const JWTToken = JSON.parse(token)
    
     fetch(`${BASE_URL}/api/client-management/clients/`,{
       headers:{
           "Authorization" : "Bearer "+JWTToken
       }
       })
     .then((response) =>{
        if(response.status===200){
         response.json().then((data)=>{
           setAllClients(data)
           console.log(data)
         })
        }
        else if(response.status===401){
         // alert('not authenticated')
          localStorage.clear()
          window.location.href = '/'
        }
        })
 
        
   }

  const formValidation = () => {
    
    if(clientType==='custom'){
      if (!clientData.firstName) {
        setFormErrors({
          ...formErros,
          firstName: {
            error: true,
            message: "Please fill the first name"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      }
  
  
      else if (clientData.firstName.trim().length < 3) {
        setFormErrors({
          ...formErros,
          firstName: {
            error: true,
            message: "First name must be contain upto 3 characters"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      }
      else if (!isNaN(clientData.firstName)) {
        setFormErrors({
          ...formErros,
          firstName: {
            error: true,
            message: "Only character allowed"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      }
      else {
  
        delete formErros.firstName
  
      }
      console.log(formErros)
  
      //last name validation
      if (!clientData.lastName) {
        setFormErrors({
          ...formErros,
          lastName: {
            error: true,
            message: "Please fill the last name"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      } else if (clientData.lastName.trim().length < 3) {
        setFormErrors({
          ...formErros,
          lastName: {
            error: true,
            message: "Last name must be contain upto 3 characters"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      }
      else if (!isNaN(clientData.lastName)) {
        setFormErrors({
          ...formErros,
          lastName: {
            error: true,
            message: "Only character allowed"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      }
      else {
  
        delete formErros.lastName
  
      }
  
  
      //validating for email
  
  
      if (!clientData.email) {
        setFormErrors({
          ...formErros,
          email: {
            error: true,
            message: "Please fill the email"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      } else if (!validateEmail(clientData.email)) {
        setFormErrors({
          ...formErros,
          email: {
            error: true,
            message: "Please enter a valid email"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      } else {
  
        delete formErros.email
  
      }
  
  
      
  
      //validation for mobile number 
  
      if (!clientData.mobileNumber) {
        setFormErrors({
          ...formErros,
          mobileNumber: {
            error: true,
            message: "Please fill the mobile number"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(clientData.mobileNumber)) {
        setFormErrors({
          ...formErros,
          mobileNumber: {
            error: true,
            message: "Please enter a valid mobile number e.g. +923001234567"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      } else {
  
        delete formErros.mobileNumber
  
      }
  
  
      //validation for cnic
  
  
      if (!clientData.id) {
        setFormErrors({
          ...formErros,
          id: {
            error: true,
            message: "Please fill the client ID"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      } else if (!/^\w+$/.test(clientData.id)) {
        setFormErrors({
          ...formErros,
          id: {
            error: true,
            message: "Client ID must be alphanumeric"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      } else {
  
        delete formErros.id
  
      }
  
  
      //validate address
      if ((!clientData.address) || clientData.address.trim().length <= 0) {
        setFormErrors({
          ...formErros,
          address: {
            error: true,
            message: "Please fill address"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      }
      else if (!isNaN(clientData.address)) {
        setFormErrors({
          ...formErros,
          address: {
            error: true,
            message: "Only character allowed"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      }
  
      else {
  
        delete formErros.address
  
      }
  
    }
    else{
      delete formErros.firstName
      delete formErros.lastName
      delete formErros.email
      delete formErros.mobileNumber
      delete formErros.id
      delete formErros.address
    }


    if(clientType==='existing'){
      if(!selectedClient?.id){
        setFormErrors({
          ...formErros,
          selectedClient: {
            error: true,
            message: "Please select client"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      }else{
        delete formErros.selectedClient
      }
    }else{
      delete formErros.selectedClient
    }


    if(clientType==='existing' && projectType==='custom'){
      if (! selectedCustomProject ) {
        setFormErrors({
          ...formErros,
          selectedCustomProject: {
            error: true,
            message: "Please select the project"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      }else{
        delete formErros.selectedCustomProject
      }
    }else{
      delete formErros.selectedCustomProject
    }

    if( clientType==='existing' && projectType==='existing'){
      if(! selectedExistingProject ){
        setFormErrors({
          ...formErros,
          selectedExistingProject: {
            error: true,
            message: "Please select project"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      }else{
        delete formErros.selectedExistingProject
      }
    }else{
      delete formErros.selectedExistingProject
    }


    if(clientType==='custom'){
      if (! customProject ) {
        setFormErrors({
          ...formErros,
          customProject: {
            error: true,
            message: "Please fill the project name"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      } else if ( customProject.trim().length < 6 ) {
        setFormErrors({
          ...formErros,
          customProject: {
            error: true,
            message: "Project name must be contain upto 6 characters"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      }
      else if (!isNaN(customProject)) {
        setFormErrors({
          ...formErros,
          customProject: {
            error: true,
            message: "Only character allowed"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      }else{
        delete formErros.customProject
      }
    }else{
      delete formErros.customProject
    }

    let invoiceItemsError = false

    for(let invoiceItem in invoiceItems){
      let item = invoiceItems[invoiceItem];
      let invoiceItemId = item.id;
      
      if (! item.description ) {
        
        setInvoiceItems((invoiceItems)=>{
          return invoiceItems.map(item => item.id==invoiceItemId ? {...item, errors:{...item.errors, description: "Please fill the description" }} : item)
        })
        
        invoiceItemsError = true
      } else if ( item.description.trim().length < 6 ) {
       
        setInvoiceItems((invoiceItems)=>{
          return invoiceItems.map(item => item.id==invoiceItemId ? {...item, errors:{...item.errors, description: "Description must be contain upto 6 characters" }} : item)
        })
        invoiceItemsError = true
      }else if (!isNaN(item.description)) {
        setInvoiceItems((invoiceItems)=>{
          return invoiceItems.map(item => item.id==invoiceItemId ? {...item, errors:{...item.errors, description: "Only character allowed" }} : item)
        })
        invoiceItemsError = true
      }else{
        setInvoiceItems((invoiceItems)=>{
          return invoiceItems.map(item => item.id==invoiceItemId ? {...item, errors:{...item.errors, description: null }} : item)
        })
        invoiceItemsError = false
      }


      if (! item.quantity > 0 ) {
        setInvoiceItems((invoiceItems)=>{
          return invoiceItems.map(item => item.id==invoiceItemId ? {...item, errors:{...item.errors, quantity: "Quantity must be greater than 0" }} : item)
        })
        invoiceItemsError = true
      } else{
       
        setInvoiceItems((invoiceItems)=>{
          return invoiceItems.map(item => item.id==invoiceItemId ? {...item, errors:{...item.errors, quantity: null}} : item)
        })
        invoiceItemsError = false
      }


      if (! item.pricePerUnit > 0 ) {
        setInvoiceItems((invoiceItems)=>{
          return invoiceItems.map(item => item.id==invoiceItemId ? {...item, errors:{...item.errors, pricePerUnit: "Price per unit must be greater than 0" }} : item)
        })
        invoiceItemsError = true
      } else{
        setInvoiceItems((invoiceItems)=>{
          return invoiceItems.map(item => item.id==invoiceItemId ? {...item, errors:{...item.errors, pricePerUnit: null}} : item)
        })
        invoiceItemsError = false
      }

    }
    
    if(invoiceItemsError){
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
   

    if (! dueDate ) {
      setFormErrors({
        ...formErros,
        dueDate: {
          error: true,
          message: "Please select the due date"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else {
      delete formErros.dueDate
    }

    return true

  }



  

  const add_Invoice = async (e) => {
    e.preventDefault()
    if (formValidation()) {


      const token = localStorage.getItem('token')
      const JWTToken = JSON.parse(token)
      //const rawDate = new Date(dateTime + 'Z')
      //console.log(rawDate)
      const body = JSON.stringify({
      
        client_type : clientType,
        project_type : projectType,
        client_data : clientData,
        selected_client : selectedClient?.id ? selectedClient.id : null,
        selected_existing_project : selectedExistingProject?.id ? selectedExistingProject.id : null,
        selected_custom_project : selectedCustomProject,
        custom_project_name : customProject,
        invoice_items : invoiceItems,
        sales_tax : salesTax,
        transaction_method : transactionMethod,
        due_date : dueDate,

      })
      console.log(body)

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + JWTToken
        }
      }

      const url = `${BASE_URL}/api/invoice-management/invoice/add/`


      try {
        const resp = await axios.post(url, body, config)
        console.log(resp)
        if (resp.status === 201) {
          popup.success("Invoice added successfully");
          history.push('/admin/invoices')
        }
        else if (resp.status === 400) {
          popup.success("Invoice not added successfully");
        }
        else if(resp.status === 401){
          localStorage.clear()
          window.location.href = '/'
        }

      }
      catch (err) {
        console.log(err)
      }
    }
  }



   const changeClientData = (e)=>{
    setClientData({...clientData, [e.target.name] : e.target.value})
   }

   const handleInvoiceItemChange = (e)=>{
      console.log(e.target.id);
      let invoiceItemId = e.target.id;
      setInvoiceItems((invoiceItems)=>{
        return invoiceItems.map(item => item.id==invoiceItemId ? {...item, [e.target.name] : e.target.value} : item)
      })
   }

   const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  return (
    <>
      <div className="wrapper">
        <Sidebar activeLink="/invoices" color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Container fluid>
              <Row>





                <Col md="8" className="order-md-1">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">Add Invoice</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={(e) => add_Invoice(e)}>




                        <Row>
                          <Col md="12">
                            <Form.Group className="">
                              <label>Client Type</label>
                             
                              <div className="ml-3">
                                <Form.Check
                                  inline
                                  label="Existing"
                                  value='existing'
                                  name="clientType"
                                  type={'radio'}
                                  onClick={(e)=>{setClientType(e.target.value)}}
                                  checked={clientType==='existing'}
                                />
                                <Form.Check
                                  inline
                                  label="Custom"
                                  value='custom'
                                  name="clientType"
                                  type={'radio'}
                                  onClick={(e)=>{setClientType(e.target.value)}}
                                  checked={clientType==='custom'}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        {
                          clientType==='existing' ? <Row>
                            <Col md='12'>
                              <Form.Group>
                                <label>Client</label>
                                <ClientDropdown className={ formErros.selectedClient ? "errors" : ""} clients={allClients} setClients={handleClientSelect}/>
                                {formErros.selectedClient ? <p style={{ color: "red" }}>*{formErros.selectedClient.message}</p> : ""}
                              </Form.Group>
                            </Col>
                          </Row> : <div>
                          <h4>
                            Custom Client Details
                          </h4>
                          <Row>
                          <Col md="3">
                            <Form.Group>
                              <label>First Name</label>
                              <Form.Control
                                className={formErros.firstName ? "errors" : ""}
                                defaultValue={clientData.firstName}
                                placeholder="First Name"
                                type="text"
                                name="firstName"
                                onChange={(e) => changeClientData(e)}
                              ></Form.Control>
                              {formErros.firstName ? <p style={{ color: "red" }}>*{formErros.firstName.message}</p> : ""}

                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group>
                              <label>Last Name</label>
                              <Form.Control
                                className={formErros.lastName ? "errors" : ""}
                                defaultValue={clientData.lastName}
                                placeholder="Last Name"
                                type="text"
                                name="lastName"
                                onChange={(e) => changeClientData(e)}
                              ></Form.Control>
                              {formErros.lastName ? <p style={{ color: "red" }}>*{formErros.lastName.message}</p> : ""}

                            </Form.Group>
                          </Col>
                        

                          <Col md="3">
                            <Form.Group>
                              <label htmlFor="exampleInputEmail1">
                                Email
                              </label>
                              <Form.Control
                                className={formErros.email ? "errors" : ""}
                                defaultValue={clientData.email}
                                placeholder="example@mail.com"
                                type="email"
                                name="email"
                                onChange={(e) => changeClientData(e)}
                              ></Form.Control>
                              {formErros.email ? <p style={{ color: "red" }}>*{formErros.email.message}</p> : ""}

                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group>
                              <label>Mobile Number</label>
                              <Form.Control
                                className={formErros.mobileNumber ? "errors" : ""}

                                defaultValue={clientData.mobileNumber}
                                placeholder="Mobile Number"
                                type="text"
                                name="mobileNumber"
                                onChange={(e) => changeClientData(e)}
                              ></Form.Control>
                              {formErros.mobileNumber ? <p style={{ color: "red" }}>*{formErros.mobileNumber.message}</p> : ""}

                            </Form.Group>
                          </Col>
                          </Row>
                          <Row>
                          
                          <Col md="6">
                            <Form.Group>
                              <label>Client ID</label>
                              <Form.Control
                                className={formErros.id ? "errors" : ""}

                                defaultValue={clientData.id}
                                placeholder="Client ID"
                                type="text"
                                name="id"
                                onChange={(e) => changeClientData(e)}
                              ></Form.Control>
                              {formErros.id ? <p style={{ color: "red" }}>*{formErros.id.message}</p> : ""}

                            </Form.Group>
                          </Col>
                       
                          <Col md="6">
                            <Form.Group>
                              <label>Address</label>
                              <Form.Control
                                className={formErros.address ? "errors" : ""}

                                defaultValue={clientData.address}
                                placeholder="Address"
                                type="text"
                                name="address"
                                onChange={(e) => changeClientData(e)}
                              ></Form.Control>
                              {formErros.address ? <p style={{ color: "red" }}>*{formErros.address.message}</p> : ""}

                            </Form.Group>
                          </Col>
                        </Row>
                        
                        
                          </div>
                        }
                        {
                          clientType==='existing' ? <div>
                            <Row>
                              <Col md="12">
                                <Form.Group className="">
                                  <label>Project Type</label>
                                
                                  <div className="ml-3">
                                    <Form.Check
                                      inline
                                      label="Existing (From Project Management)"
                                      value='existing'
                                      name="projectType"
                                      type={'radio'}
                                      onClick={(e)=>{setProjectType(e.target.value)}}
                                      checked={projectType==='existing'}
                                    />
                                    <Form.Check
                                      inline
                                      label="Custom"
                                      value='custom'
                                      name="projectType"
                                      type={'radio'}
                                      onClick={(e)=>{setProjectType(e.target.value)}}
                                      checked={projectType==='custom'}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>
                            {
                              projectType==='existing' ? <Row>
                                <Col md='12'>
                                  <label>Select Project</label>
                                  <select 
                                  className={formErros.selectedExistingProject ? "errors form-control" : "form-control"}
                                  value={selectedExistingProject}
                                  onChange={(e)=>setSelectedExistingProject(e.target.value)}
                                  >
                                    <option value=''>Select Project</option>
                                  {
                                    selectedClient?.projects && selectedClient.projects.length > 0 ? selectedClient.projects.map(project=>{
                                      return <option value={project.id}>{project.title}</option>
                                    }) : <></>
                                  }
                                  </select>

                                  {formErros.selectedExistingProject ? <p style={{ color: "red" }}>*{formErros.selectedExistingProject.message}</p> : ""}

                                </Col>
                              </Row> : <Row>
                                <Col md='12'>
                                <label>Select Custom Project</label>
                                  <select 
                                  className={formErros.selectedCustomProject ? "errors form-control" : "form-control"}
                                  value={selectedCustomProject}
                                  onChange={(e)=> setSelectedCustomProject(e.target.value)}
                                  >
                                    <option value=''>Select Project</option>
                                  {
                                    selectedClient?.custom_projects && selectedClient.custom_projects.split(',').length > 0 ? selectedClient.custom_projects.split(',').map(project=>{
                                      return <option value={project}>{project}</option>
                                    }) : <></>
                                  }
                                  </select>

                                  {formErros.selectedCustomProject ? <p style={{ color: "red" }}>*{formErros.selectedCustomProject.message}</p> : ""}

                                </Col>
                              </Row>
                            }
                          </div> : <div>
                            <Row>
                            <Col md="12">
                                <Form.Group>
                                  <label>Project Name</label>
                                  <Form.Control
                                    className={formErros.customProject ? "errors" : ""}

                                    defaultValue={customProject}
                                    placeholder="Project Name"
                                    type="text"
                                    name="customProject"
                                    onChange={(e) => setCustomProject(e.target.value)}
                                  ></Form.Control>
                                  {formErros.customProject ? <p style={{ color: "red" }}>*{formErros.customProject.message}</p> : ""}

                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        }
                        <div>
                          <h4>Invoice Items</h4>
                          <Row>
                              <Col md="12">
                                  {
                                    invoiceItems.map((item, idx)=>{
                                      return <div key={idx}>
                                        <Row>
                                           <Col md='6'>
                                           <Form.Group>
                                              <label>Description</label>
                                              <Form.Control
                                                  defaultValue={item.description}
                                                  className={item.errors.description ? "errors" : ""}
                                                  placeholder={"Item description"}
                                                  type="text"
                                                  id={item.id}
                                                  name='description'
                                                  value={item.description}
                                                  onChange = {(e)=>handleInvoiceItemChange(e)}
                                              >
                                                </Form.Control>

                                                {item.errors.description ? <p style={{ color: "red" }}>*{item.errors.description}</p> : ""}

                                              </Form.Group>
                                           </Col>
                                           

                                        <Col md='3'>
                                           <Form.Group>
                                              <label>Quantity</label>
                                              <Form.Control
                                                  defaultValue={item.quantity}
                                                  className={item.errors.quantity ? "errors" : ""}
                                                  placeholder={"Item quantity"}
                                                  type="number"
                                                  min={0}
                                                  id={item.id}
                                                  name='quantity'
                                                  value={item.quantity}
                                                  onChange = {(e)=>handleInvoiceItemChange(e)}
                                              >
                                                </Form.Control>
                                                {item.errors.quantity ? <p style={{ color: "red" }}>*{item.errors.quantity}</p> : ""}
                                              </Form.Group>
                                           </Col>

                                           <Col md='3'>
                                           <Form.Group>
                                              <label>Price Per Unit</label>
                                              <Form.Control
                                                  defaultValue={item.pricePerUnit}
                                                  className={item.errors.pricePerUnit ? "errors" : ""}
                                                  placeholder={"Item price per unit"}
                                                  type="number"
                                                  id={item.id}
                                                  min={0}
                                                  name='pricePerUnit'
                                                  value={item.pricePerUnit}
                                                  onChange = {(e)=>handleInvoiceItemChange(e)}
                                              >
                                                </Form.Control>
                                                {item.errors.pricePerUnit ? <p style={{ color: "red" }}>*{item.errors.pricePerUnit}</p> : ""}
                                              </Form.Group>
                                           </Col>
                                        </Row>
                                      </div>
                                    })
                                  }
                              </Col>
                              <Col md="7">
                            <button onClick={()=>{
                              
                              setInvoiceItems([...invoiceItems, {id : itemCount+1,  description : '', quantity : 0, pricePerUnit : 0,  errors : {
                                'description' : null,
                                'quantity' : null,
                                'pricePerUnit' : null
                              }}])
                              let iCount = itemCount + 1
                              setItemCount(iCount)
                              
                              }} type="button" className="btn-fill pull-right btn btn-info mt-4"><i className="fas fa-plus"></i></button>
                          </Col>
                        </Row>
                        </div>
                        
                    
                        <Row>
                          <Col md="4">
                            <Form.Group>
                              <label>Sales Tax</label>

                              <Form.Control
                                className={formErros.salexTax ? "errors" : ""}
                                placeholder="Sales Tax"
                                type="number"
                                min={0}
                                value={salesTax}
                                name="salesTax"
                                onChange={(e) => setSalesTax(e.target.value)}
                              >

                              </Form.Control>
                              
                            </Form.Group>
                          </Col>
                          <Col md='4'>
                            <Form.Group>
                              <label>Transaction Method</label>
                              <select 
                                className="form-control" 
                                value={transactionMethod}
                                onChange={(e)=>setTransactionMethod(e.target.value)}
                                >
    
                                <option value='PayPal'>PayPal</option>
                                <option value='Payoneer'>Payoneer</option>
                              </select>
                            </Form.Group>
                          </Col>
                          <Col sm="4" >
                            <Form.Group>
                              <label>Due Date</label>
                              <Form.Control
                                defaultValue={dueDate}
                                className={formErros.dueDate ? "errors" : ""}
                                placeholder="Due Date"
                                type="date"
                                onChange={(e) => setDueDate(e.target.value)}
                                
                              ></Form.Control>
                              {formErros.dueDate ? <p style={{ color: "red" }}>*{formErros.dueDate.message}</p> : ""}

                            </Form.Group>
                          </Col>
                        </Row>
                      

                        <br />
                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                        >
                          Add Invoice
                        </Button>
                        <div className="clearfix"></div>

                      </Form>
                    </Card.Body>
                  </Card>
                </Col>


              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </div>


    </>
  );
}

export default AddInvoice;
