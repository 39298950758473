import React from "react";
import toast from "../utils/toast";
import { BASE_URL } from "apiUrl";
import { useEffect,useState } from "react";
import axios from "axios";
import ResetPassword from "views/ResetPassword";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  ProgressBar
} from "react-bootstrap";

const EmployeeProfile = ()=> {
 
  const [profileData,setProfileData]=useState({})
  const [updatedData,setUpdatedData]=useState({})
  const [changeImage,setChangeImage]=useState(null)
  const [updatedImage,setUpdatedImage]=useState(null)
  const [formErros,setFormErrors]=useState({})
  const [showLoading,setShowLoading] = useState(false)
  const [showPasswordReset,setShowPasswordReset] = useState(false)
  const [loaded,setLoaded] = useState(0)
  
  
  const changeProfileData= (e) =>{
      
    if (e.target.name ==="photo" ){
      setUpdatedData({
        ...updatedData,
        [e.target.name]:e.target.files[0]
      })  
      setProfileData({
          ...profileData,
          [e.target.name]:e.target.files[0]
        })
      }
      
      else{
        setUpdatedData({
          ...updatedData,
          [e.target.name]:e.target.value
        })

        setProfileData({
          ...profileData,
          [e.target.name]:e.target.value
        })
      }
      

    
  }


  const updateProfile =async (e)=>{
    e.preventDefault()
    
    
    if(updatedImage){
      setShowLoading(true)
      let form_data = new FormData();
      form_data.append('photo', updatedImage, updatedImage.name)
      const token = localStorage.getItem('token')
      const JWTToken = JSON.parse(token)
      const url =`${BASE_URL}/api/user/profile/`
      


      const config = {
        
        headers:{
            "Content-Type": "multipart/form-data",
            "Authorization" : "Bearer "+JWTToken
        },
        onUploadProgress: (p) => {
          // console.log(Math.ceil((p.loaded/p.total)*100))
          // setLoaded(Math.ceil((p.loaded/p.total)*100))
          setLoaded(p.loaded)
        }
    }
    try{

        const response = await axios.put(url,form_data,config)

        if(response.status===200){
        
          toast.success("Pofile picture updated successfully");
          setProfileData(response.data)
                    
            
                                            
        }
        else if (response.status===400){
      
          
          toast.error("Profile picture not updated successfully!")
                          
        }

        else if (response.status === 401){
              
        }
        setShowLoading(false)
        setLoaded(0)
    }
    catch(err){
        console.log(err)
        setShowLoading(false)
        setLoaded(0)
    }
    
    }else{
      toast.error("Please first add your updated picture.") 
    }

  }

  useEffect(()=>{
    

    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
   
    fetch(`${BASE_URL}/api/user/profile/`,{
      headers:{
          "Authorization" : "Bearer "+JWTToken
      }
      })
    .then((response) =>{
       if(response.status===200){
        response.json().then((resp)=>{
          setProfileData(resp)
          
        })
       }
       else if(response.status===401){
         alert('not authenticated')
       }
       })
  },[])


  const addImage = (e)=>{
    
    setUpdatedImage(e.target.files[0])
    setChangeImage(URL.createObjectURL(e.target.files[0]))
    
  }


  

  console.log(profileData)


  
  return (
    <>
      <Container fluid >
        <Row>
          <Col md="4" className="order-md-2">
            <Card className="card-user">
              <div className="card-image">
                <img
                  alt="..."
                  src={
                    require("assets/img/photo-1431578500526-4d9613015464.jpeg")
                      .default
                  }
                 
                ></img>
              </div>
              <Card.Body>
                <div className="author" >
                    <img
                      alt="..."
                      className="avatar border-gray"
                      src={changeImage?changeImage:BASE_URL+profileData.photo}

                      onError={({ currentTarget }) => {
                        console.log('on error')
                        currentTarget.onerror = null; 
                        currentTarget.src= require("assets/img/profile.png").default
                      }}
                    />
                    
                    
                  
                <Form.Group>
                       {
                        showPasswordReset?null:<>
                        <label htmlFor="file-upload" className="custom-file-upload">
                           <i style={{fontWeight:900,fontSize:"15px"}} className="fas fa-pencil-alt"></i> 
                       </label>
                       <input name="image" accept="image/*" id="file-upload" type="file" onChange={(e)=>{addImage(e)}}/>
                 
                        </>
                       }
                       
                </Form.Group>


                    <h5 style={{textTransform:"capitalize"}} className="title"><b>{profileData.first_name} {profileData.last_name}</b></h5>
                    {
                      showPasswordReset?null:<>
                      <p style={{fontSize:"12px"}}>*Change your profile image from here.</p>
                  
                  <Button
                    className="btn-fill "
                    type="submit"
                    variant="info"
                    onClick={(e)=>{window.scrollTo(0, 0);updateProfile(e);}}
                  >
                    Update Profile Picture
                  </Button>
                  
                      </>
                    }
                  </div>
                
                
              </Card.Body>

              
              
              
            </Card>

            
            

            
          </Col>
          
          {
            showPasswordReset?<ResetPassword setShowPasswordReset={setShowPasswordReset} userId={profileData.id}/>
            :
            <Col md="8" className="order-md-1">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Profile Details</Card.Title>
                <p style={{color: "#9A9A9A",fontWeight:"400",fontSize:"14px"}}>(Contact with your company for update the profile details.)</p>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col  md="6">
                      <Form.Group>
                        <label>Company</label>
                        <Form.Control
                          defaultValue={profileData.company_name}
                          disabled
                          placeholder="Company"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    
                    <Col  md="6">
                      <Form.Group>
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <Form.Control
                        className={formErros.email?"errors":""}
                          
                          defaultValue={profileData.email}
                          placeholder="example@mail.com"
                          type="email"
                          name="email"
                          disabled
                          onChange={(e)=>changeProfileData(e)}
                        ></Form.Control>
                        {formErros.email?<p style={{color:"red"}}>*{formErros.email.message}</p>:""}
                        
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col  md="6">
                      <Form.Group>
                        <label>First Name</label>
                        <Form.Control
                        className={formErros.first_name?"errors":""}
                          
                          defaultValue={profileData.first_name}
                          placeholder="Company"
                          type="text"
                          name="first_name"
                          disabled
                          onChange={(e)=>changeProfileData(e)}
                        ></Form.Control>
                        {formErros.first_name?<p style={{color:"red"}}>*{formErros.first_name.message}</p>:""}
                        
                      </Form.Group>
                    </Col>
                    <Col  md="6">
                      <Form.Group>
                        <label>Last Name</label>
                        <Form.Control
                        className={formErros.last_name?"errors":""}
                          
                          defaultValue={profileData.last_name}
                          placeholder="Last Name"
                          type="text"
                          name="last_name"
                          disabled
                          onChange={(e)=>changeProfileData(e)}
                        ></Form.Control>
                        {formErros.last_name?<p style={{color:"red"}}>*{formErros.last_name.message}</p>:""}
                        
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label>Mobile No</label>
                        <Form.Control
                        className={formErros.phone_no?"errors":""}
                          
                          defaultValue={profileData.phone_no}
                          placeholder="Mobile No"
                          type="text"
                          name="phone_no"
                          disabled
                          onChange={(e)=>changeProfileData(e)}
                        ></Form.Control>
                        {formErros.phone_no?<p style={{color:"red"}}>*{formErros.phone_no.message}</p>:""}
                        
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label>Address</label>
                        <Form.Control
                        className={formErros.address?"errors":""}
                          
                          defaultValue={profileData.address}
                          placeholder="Home Address"
                          type="text"
                          name="address"
                          disabled
                          onChange={(e)=>changeProfileData(e)}
                        ></Form.Control>
                        {formErros.address?<p style={{color:"red"}}>*{formErros.address.message}</p>:""}
                        
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col  md="6">
                      <Form.Group>
                        <label>City</label>
                        <Form.Control
                        className={formErros.city?"errors":""}
                          
                          defaultValue={profileData.city}
                          placeholder="City"
                          type="text"
                          name="city"
                          disabled
                          onChange={(e)=>changeProfileData(e)}
                        ></Form.Control>
                        {formErros.city?<p style={{color:"red"}}>*{formErros.city.message}</p>:""}
                        
                      </Form.Group>
                    </Col>
                    <Col  md="6">
                      <Form.Group>
                        <label>Country</label>
                        <Form.Control
                        className={formErros.country?"errors":""}
                          
                          defaultValue={profileData.country}
                          placeholder="Country"
                          type="text"
                          name="country"
                          disabled
                          onChange={(e)=>changeProfileData(e)}
                        ></Form.Control>
                        {formErros.country?<p style={{color:"red"}}>*{formErros.country.message}</p>:""}
                        
                      </Form.Group>
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label>Position</label>
                        <Form.Control
                        className={formErros.about?"errors":""}
                          
                          
                          defaultValue={profileData.about}
                          placeholder="Here can be your description"
                          name="about"
                          disabled
                          onChange={(e)=>changeProfileData(e)}
                        ></Form.Control>
                        {formErros.about?<p style={{color:"red"}}>*{formErros.about.message}</p>:""}
                        
                      </Form.Group>
                    </Col>


                     
                  </Row>
                  
                  <br></br>
                  {
                      showLoading?<><ProgressBar animated variant="success" now={loaded}  /><br></br></>:""
                    }
                  <div>
                    <a onClick={(e)=>{
                      e.preventDefault()
                      setShowPasswordReset(true)
                    }} style={{cursor:"pointer",textDecoration:"none"}} className="pull-right mt-2 mr-2">Reset Your Password?</a>
                    
                  </div>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          
          }
          
        </Row>
      </Container>
    </>
  );
}

export default EmployeeProfile
