import { useState,useEffect} from "react"
import "../assets/css/bank-account-dropdown.css"

const BankAccountDropdown = ({banks,setBank})=>{
    
    const [data,setData]=useState([]);
    const [allData,setAllData] = useState([])
    const [openDropdown,setOpenDropdown] = useState(false)
    const [displayText,setDisplayText] = useState("Select Bank Account")
    const [totalItems,setTotalItems] = useState(4)
    
    
    useEffect(()=>{
        if(banks.length>0){
          setData(banks.slice(0, totalItems))
        }
        setAllData(banks)
    },[banks])

    

    const bankAccountSelect = (e)=>{
        let account = JSON.parse(e.target.value)
        let dispTxt = account.bank_name+" ("+account.account_no+") "+ account.branch_name
        setDisplayText(dispTxt)
        setBank(account.id)
        setOpenDropdown(!openDropdown)
        console.log(account)
    }



      

      const searchBankAccount = (e)=>{
        console.log(e.target.value)
        let query = e.target.value.trim()
        if(query!==""){
          let matched = allData.filter((bank)=>bank.bank_name.toUpperCase()===query.toUpperCase()||bank.account_no===query)
          console.log(matched)
          if(matched.length>0){
            setData(matched)
          }else {
            setData(allData.slice(0, 4))
          }
        }else{

        }
      }

      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
    
      const onScroll = async (e) => {
        var maxScrollPosition = e.target.scrollHeight - e.target.clientHeight
        const currentScroll = e.target.scrollTop
        if(currentScroll===maxScrollPosition){
            let items =  totalItems+4
            await sleep(1 * 1000)
            setData(allData.slice(0, items))
            setTotalItems(items)
        }
        
      };

    return <>
    <div className="select_box_wrapper ">
  <div className="custom_select_box" onClick={()=>{setOpenDropdown(!openDropdown)}}>
    <div className="custom_select_box_selected_label">
      <label className="custom_select_box_selected_label_text" style={{cursor:"pointer"}}>{displayText}</label>
    </div>
    <div className="custom_select_box_dropdown_icon">
      <a className="custom_select_box_dropdown_icon_button"><i className="fas fa-angle-down"></i></a>
    </div>
  </div>
  {
    openDropdown?<div onScroll={onScroll} className="custom_select_dropdown" >
    <ul className="custom_select_dropdown_list_wrapper">
      <li className="custom_select_dropdown_list_search">
          <input  className="custom_select_dropdown_list_search_input" placeholder="Search Bank Account" 
          onChange={(e)=>{searchBankAccount(e)}}
          type="text" />
      </li>
      {data.map((item)=>{
                    
                    return <li key={item.id} className="custom_select_dropdown_list">
                      <input className="custom_select_dropdown_list_radio" type="radio" value={JSON.stringify(item)} onChange={(e)=>{bankAccountSelect(e)}} /><span className="custom_select_dropdown_list_label">{item.bank_name} ({item.account_no}) {item.branch_name}</span>
                    </li>}
      )} 
      
      </ul>
    </div>:null
  }
</div>

    </>
}
export default BankAccountDropdown