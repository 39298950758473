import { popup } from "utils/popup";
import axios from "axios";
import { useEffect, useState } from "react";
import toast from "../utils/toast";
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    NavDropdown,
    Modal,
    Form
  } from "react-bootstrap";
import { BASE_URL } from "apiUrl";


const EditBankBallanceModel = ({show,update,handleClose,setUpdate,selectedBank})=>{
   console.log(selectedBank)
   const [ballance,setBallance] = useState(0)
   const [bankId,setBankId] = useState(null)
    
    useEffect(()=>{
        if(selectedBank!==null){
            setBallance(selectedBank.total_ballance)
            setBankId(selectedBank.id)
        }
    },[selectedBank])


    function updateBallance(e)
      {
        e.preventDefault();
        
        if(true){
          const token = localStorage.getItem('token')
        const JWTToken = JSON.parse(token)
    
        const body =JSON.stringify({
            total_ballance : ballance
        })
        const url =  `${BASE_URL}/api/bank-management/bank/${bankId}/`
        fetch(url,{
            method: 'PUT',
            headers:{
            "Authorization" : "Bearer "+JWTToken,     
            'Content-Type':'application/json'
                                 
           },
          body:body

          }).then(response=>{
              if(response.status===200){
                  response.json().then(data=>{
                         
                  popup.success("Bank ballance updated successfully")
                  setUpdate(!update)
                  handleClose()
            })                                    
        }
        else if(response.status===400){
          popup.success("Bank ballance not updated successfully");
        }
      })
        }

       
      }

    return (
      <>
        <Modal   size="lg" animation={false}  show={show} onHide={handleClose} >
        <h4 className="narbar-brand text-center mt-5">Edit Total Ballance</h4>
          <Modal.Body style={{"height":"250px"}}>
                
                <div className="row justify-content-center">
                    
                <Col sm="7" >
                    
                      <Form.Group>
                        <label >TOTAL BALANCE :</label>
                        <Form.Control
                          value={ballance}
                          type="text"
                          onChange={(e)=>setBallance(e.target.value)}
                          required={true}
                          
                        ></Form.Control>
                        
                      </Form.Group>
                    </Col>

                    
                   
                </div>
                <div className="row justify-content-center mt-3">
                
                <Button
                    onClick = {updateBallance}
                    className="btn-fill "
                    type="button"
                    variant="info"
                    >
                    
                   Edit Total Ballance
                  </Button>
                  
                </div>
          </Modal.Body>
          <Modal.Footer>
         </Modal.Footer>
        </Modal>
      </>
    );
  }
  
export default EditBankBallanceModel