import { popup } from "utils/popup";
import axios from "axios";
import { useEffect, useState } from "react";
import toast from "../utils/toast";
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    NavDropdown,
    Modal,
    Form
  } from "react-bootstrap";
  
import currentDateTime from "utils/currentDateTime";
import { BASE_URL } from "apiUrl";

const GenerateSheetModel = ({show,handleClose,handleShow})=>{
   
    const [from,setFrom] = useState(currentDateTime)
    const [to,setTo] = useState(currentDateTime)
    
    useEffect(()=>{
        let d = new Date(currentDateTime);
        d.setMonth(d.getMonth() - 1)
        let dd = d.toISOString().slice(0,16)
        console.log(dd)
        setFrom(dd)
    },[show])


    const downloadSheet = ()=>{
        const token = localStorage.getItem('token')
        const JWTToken = JSON.parse(token)
        axios({
            url: `${BASE_URL}/api/salary-management/download-salary-sheet/${from}/${to}/`,
            method: 'POST',
            responseType: 'blob', 
            headers:{
                "Content-Type": "application/json",
                "Authorization" : "Bearer "+JWTToken
            },
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'salary_sheet.xlsx'); 
            document.body.appendChild(link);
            link.click();
            handleClose()
        }).catch(err=>{
            
            popup.error("Some error occur.")
        })
    }
    
    return (
      <>
       
  
        <Modal   size="lg" animation={false}  show={show} onHide={handleClose} >
        <h4 className="narbar-brand text-center mt-5">Generate Salary Sheet</h4>
          <Modal.Body style={{"height":"300px"}}>
                
                <div className="row justify-content-center">
                    
                <Col sm="7" >
                    
                      <Form.Group>
                        <label >From Date & Time :</label>
                        <Form.Control
                        defaultValue={from}
                          type="datetime-local"
                          onChange={(e)=>setFrom(e.target.value)}
                          required={true}
                        ></Form.Control>
                        
                      </Form.Group>
                    </Col>

                    <Col sm="7" >
                      <Form.Group>
                        <label>To Date & Time :</label>
                        <Form.Control
                        defaultValue={to}
                          type="datetime-local"
                          onChange={(e)=>setTo(e.target.value)}
                          required={true}
                        ></Form.Control>
                       
                      </Form.Group>
                    </Col>
                </div>
                <div className="row justify-content-center mt-3">
                
                <Button
                    onClick = {downloadSheet}
                    className="btn-fill "
                    type="button"
                    variant="info"
                    >
                    <i className="fas fa-download mr-2"></i>
                    Download Sheet
                  </Button>
                  
                </div>
          </Modal.Body>
          <Modal.Footer>
         </Modal.Footer>
        </Modal>
      </>
    );
  }
  
export default GenerateSheetModel