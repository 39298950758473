import React from "react";
import { popup } from "utils/popup";
import Sidebar from "components/Sidebar/Sidebar";
import sidebarImage from "assets/img/sidebar-3.jpg";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import { Link, useHistory } from "react-router-dom";
import routes from "routes.js";
import toast from "../utils/toast";
import { BASE_URL } from "apiUrl";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
import { MultiSelect } from "react-multi-select-component";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  ProgressBar
} from "react-bootstrap";
import CountryList from "./CountryList";
import MobileNumberField from "./MobileNumberField";


function editClient() {


  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const mainPanel = React.useRef(null);
  const { id } = useParams()
  console.log('id', id)

  const [clientData, setClientData] = useState({})
  const [updatedData, setUpdatedData] = useState({})
  const [changeImage, setChangeImage] = useState(null)
  const [updatedImage, setUpdatedImage] = useState(null)
  const [formErros, setFormErrors] = useState({})
  const [attachementUpdated, setAttachmentUpdated] = useState(null)
  const [showLoading, setShowLoading] = useState(false)
  const [loaded, setLoaded] = useState(0)
  const [projects, setProjects] = useState([])
  const [selectedProjects, setSelectedProjects] = useState([])
  const [projectsLoading, setProjectsLoading] = useState(true)
  const [selectProjectFrom, setSelectProjectFrom] = useState('existing');
  const [customProjects, setCustomProjects] = useState([{
    id : Math.random(1, 1000), 
    value : ''
  }, ])
  const history = useHistory()

  const [currentInput, setCurrentInput] = useState(1);
  const inputRefs = React.useRef([]);


  useEffect(() => {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/client-management/client/${id}/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((resp) => {
            setClientData(resp)
            if(resp.custom_projects){
              setSelectProjectFrom('custom')
              setCustomProjects(resp.custom_projects.split(',').map(p => {return {id : Math.random(1,10000), value : p}}))
            }
            console.log(resp)
          })
        }
        else if (response.status === 401) {
          alert('not authenticated')
        }
      })
  }, [])


  useEffect(()=>{
    if(clientData?.id){
      getAllProjects()
    }
   },[clientData])
 
 
   const getAllProjects = ()=>{
    setProjectsLoading(true)
     const token = localStorage.getItem('token')
     const JWTToken = JSON.parse(token)
    
     fetch(`${BASE_URL}/api/project-management/all-projects/`,{
       headers:{
           "Authorization" : "Bearer "+JWTToken
       }
       })
     .then((response) =>{
        if(response.status===200){
         response.json().then((data)=>{
           let clientProjects = clientData.projects
           let allProjects = data.data.map(project => {return {label : project.title, value : project.id}} )
           let selectedProjects = allProjects.filter(project=> clientProjects.includes(project.value))
           setProjects(allProjects)
           setSelectedProjects(selectedProjects)
           console.log(allProjects, clientData, clientProjects)
         })
        }
        else if(response.status===401){
         // alert('not authenticated')
        }
        })
        setProjectsLoading(false)
   }
  
  const changeProfileData = (e) => {

    if (e.target.name === "photo") {

      setUpdatedData({
        ...updatedData,
        [e.target.name]: e.target.files[0]
      })
      setClientData({
        ...clientData,
        [e.target.name]: e.target.files[0]
      })
    }

    else {
      setUpdatedData({
        ...updatedData,
        [e.target.name]: e.target.value
      })

      setClientData({
        ...clientData,
        [e.target.name]: e.target.value
      })
    }



  }




  const formValidation = () => {

    //validate first name


    if (!clientData.first_name) {
      setFormErrors({
        ...formErros,
        first_name: {
          error: true,
          message: "Please fill the first name"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }


    else if (clientData.first_name.trim().length < 3) {
      setFormErrors({
        ...formErros,
        first_name: {
          error: true,
          message: "First name must be contain upto 3 characters"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else if (!isNaN(clientData.first_name)) {
      setFormErrors({
        ...formErros,
        first_name: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else {

      delete formErros.first_name

    }
    console.log(formErros)

    //last name validation
    if (!clientData.last_name) {
      setFormErrors({
        ...formErros,
        last_name: {
          error: true,
          message: "Please fill the last name"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    } else if (clientData.last_name.trim().length < 3) {
      setFormErrors({
        ...formErros,
        last_name: {
          error: true,
          message: "Last name must be contain upto 3 characters"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else if (!isNaN(clientData.last_name)) {
      setFormErrors({
        ...formErros,
        last_name: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else {

      delete formErros.last_name

    }


    //validating for email


    if (!clientData.email) {
      setFormErrors({
        ...formErros,
        email: {
          error: true,
          message: "Please fill the email"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    } else if (!validateEmail(clientData.email)) {
      setFormErrors({
        ...formErros,
        email: {
          error: true,
          message: "Please enter a valid email"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    } else {

      delete formErros.email

    }


    //validation for cnic


    if (!clientData.cnic) {
      setFormErrors({
        ...formErros,
        cnic: {
          error: true,
          message: "Please fill the client ID"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    } else if (!/^\w+$/.test(clientData.cnic)) {
      setFormErrors({
        ...formErros,
        cnic: {
          error: true,
          message: "Client ID must be alphanumeric"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    } else {

      delete formErros.cnic

    }


    //validation for mobile number 

    if (!clientData.phone_no) {
      setFormErrors({
        ...formErros,
        phone_no: {
          error: true,
          message: "Please fill the mobile number"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(clientData.phone_no)) {
      setFormErrors({
        ...formErros,
        phone_no: {
          error: true,
          message: "Please enter a valid mobile number e.g. +923001234567"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    } else {

      delete formErros.phone_no

    }





    //validate address
    if ((!clientData.address) || clientData.address.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        address: {
          error: true,
          message: "Please fill address"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else if (!isNaN(clientData.address)) {
      setFormErrors({
        ...formErros,
        address: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }

    else {

      delete formErros.address

    }
    //validate city 
    if ((!clientData.city) || clientData.city.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        city: {
          error: true,
          message: "Please fill city"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }

    else if (!isNaN(clientData.city)) {
      setFormErrors({
        ...formErros,
        city: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }

    else {

      delete formErros.city

    }



    //validate the provience


    if ((!clientData.provience) || clientData.provience.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        provience: {
          error: true,
          message: "Please fill provience/state"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }

    else if (!isNaN(clientData.provience)) {
      setFormErrors({
        ...formErros,
        provience: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }

    else {

      delete formErros.provience

    }


    //validate the country 


    if ((!clientData.country) || clientData.country.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        country: {
          error: true,
          message: "Please fill country"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }

    else if (!isNaN(clientData.country)) {
      setFormErrors({
        ...formErros,
        country: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }

    else {

      delete formErros.country

    }


    //validate the domain of business

    if ((!clientData.domain) || clientData.domain.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        domain: {
          error: true,
          message: "Please fill domain of business"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else if (!isNaN(clientData.domain)) {
      setFormErrors({
        ...formErros,
        domain: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else {

      delete formErros.domain

    }


    // //validate the project name

    // if ((!clientData.project_name) || clientData.project_name.trim().length <= 0) {
    //   setFormErrors({
    //     ...formErros,
    //     project_name: {
    //       error: true,
    //       message: "Please fill project name"
    //     }
    //   })
    //   popup.error("Form not submited ! Please fill all required fields")
    //   return false
    // }
    // else if (!isNaN(clientData.project_name)) {
    //   setFormErrors({
    //     ...formErros,
    //     project_name: {
    //       error: true,
    //       message: "Only character allowed"
    //     }
    //   })
    //   popup.error("Form not submited ! Please fill all required fields")
    //   return false
    // }
    // else {

    //   delete formErros.project_name

    // }



    //validate description



    if ((!clientData.description) || clientData.description.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        description: {
          error: true,
          message: "Please fill description"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else if (!isNaN(clientData.description)) {
      setFormErrors({
        ...formErros,
        description: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else {

      delete formErros.description

    }

    return true
  }


  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }



  const updateToClient = async (e) => {
    e.preventDefault()

    if (formValidation()) {
      setShowLoading(true)
      let form_data = new FormData();
      form_data.append('first_name', clientData.first_name)
      form_data.append('last_name', clientData.last_name)
      form_data.append('address', clientData.address)
      form_data.append('city', clientData.city)
      form_data.append('provience', clientData.provience)
      form_data.append('country', clientData.country)
      form_data.append('email', clientData.email)
      form_data.append('phone_no', clientData.phone_no)
      form_data.append('cnic', clientData.cnic)
      form_data.append('description', clientData.description)
      form_data.append('domain', clientData.domain)
      form_data.append('selected_projects', JSON.stringify(selectedProjects.map(project=> project.value )))
      const filteredProjects = customProjects.filter(project => project.value.trim() !== '');
      form_data.append('custom_projects', JSON.stringify(filteredProjects.map(project => project.value)))
      form_data.append('select_project_from', selectProjectFrom)

      if (updatedImage) {
        form_data.append('photo', updatedImage, updatedImage.name)
      }
      if (attachementUpdated) {
        form_data.append('attachement', attachementUpdated, attachementUpdated.name)

      }



      const token = localStorage.getItem('token')
      const JWTToken = JSON.parse(token)
      const url = `${BASE_URL}/api/client-management/client/${id}/`



      const config = {

        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": "Bearer " + JWTToken
        },
        onUploadProgress: (p) => {
          // console.log(Math.ceil((p.loaded/p.total)*100))
          // setLoaded(Math.ceil((p.loaded/p.total)*100))
          setLoaded(p.loaded)
        }
      }
      try {

        const response = await axios.put(url, form_data, config)

        if (response.status === 200) {

          popup.success("Client data updated successfully");
          setClientData(response.data)


        }
        else if (response.status === 400) {


          popup.error("Client data not updated successfully")

        }

        else if (response.status === 401) {

        }
        setShowLoading(false)
        setLoaded(0)

      }
      catch (err) {
        console.log(err)
        setShowLoading(false)
        setLoaded(0)
      }

    }



  }





  const addImage = (e) => {

    setUpdatedImage(e.target.files[0])
    setChangeImage(URL.createObjectURL(e.target.files[0]))

  }

  const addAttachment = (e) => {
    console.log(e.target.name, 'attachement')
    setAttachmentUpdated(e.target.files[0])
  }


  const handleKeyDown = (event, index) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      let nextIndex = index + 1;
      if (nextIndex < inputRefs.current.length) {
        try{
          inputRefs.current[nextIndex].focus();
        }catch{
          nextIndex = nextIndex+1;
          inputRefs.current[nextIndex].focus();
        }
        setCurrentInput(nextIndex);
        return 
      }
      // here submit the form
      console.log('Submiting.....');
      updateToClient(event);
      return 
    }else if (event.key === 'Backspace') {
      const currentValue = event.target.value;
      if (currentValue === '' && index > 0) {
        let prevIndex = index - 1;
        try{
          inputRefs.current[prevIndex].focus();
        }catch{
          prevIndex = prevIndex -1;
          inputRefs.current[prevIndex].focus();
        }
        setCurrentInput(prevIndex);
      }
     return 
    }
  };

  return (
    <>

      <div className="wrapper">
        <Sidebar activeLink="/clients" color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">

            <Container fluid>
              <Row>
                <Col md="4" className="order-md-2">
                  <Card className="card-user">
                    <div className="card-image">
                      <img
                        alt="..."
                        src={
                          require("assets/img/photo-1431578500526-4d9613015464.jpeg")
                            .default
                        }
                      ></img>
                    </div>
                    <Card.Body>
                      <div className="author">
                        <img
                          alt="..."
                          className="avatar border-gray"
                          src={changeImage ? changeImage : BASE_URL + clientData.photo}
                          onError={({ currentTarget }) => {
                            console.log('on error')
                            currentTarget.onerror = null;
                            currentTarget.src = require("assets/img/profile.png").default
                          }}
                        />

                        <Form.Group>

                          <label htmlFor="file-upload" className="custom-file-upload">
                            <i style={{ fontWeight: 900, fontSize: "15px" }} className="fas fa-pencil-alt"></i>
                          </label>
                          <input name="image" accept="image/*" id="file-upload" type="file" onChange={(e) => { addImage(e) }} />
                        </Form.Group>


                        <h5 style={{ textTransform: "capitalize" }} className="title"><b>{clientData.first_name} {clientData.last_name}</b></h5>

                      </div>

                    </Card.Body>


                  </Card>
                </Col>


                <Col md="8" className="order-md-1">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">Edit Client</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form>

                        <Row>
                          <Col md="4">
                            <Form.Group>
                              <label>First Name</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[0] = el)}
                                className={formErros.first_name ? "errors" : ""}
                                defaultValue={clientData.first_name}
                                placeholder="First Name"
                                type="text"
                                name="first_name"
                                onChange={(e) => changeProfileData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 0)}
                              ></Form.Control>
                              {formErros.first_name ? <p style={{ color: "red" }}>*{formErros.first_name.message}</p> : ""}

                            </Form.Group>
                          </Col>
                          <Col md="4">
                            <Form.Group>
                              <label>Last Name</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[1] = el)}
                                className={formErros.last_name ? "errors" : ""}
                                defaultValue={clientData.last_name}
                                placeholder="Last Name"
                                type="text"
                                name="last_name"
                                onChange={(e) => changeProfileData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 1)}
                              ></Form.Control>
                              {formErros.last_name ? <p style={{ color: "red" }}>*{formErros.last_name.message}</p> : ""}

                            </Form.Group>
                          </Col>
                          <Col md="4">
                            <Form.Group>
                              <label htmlFor="exampleInputEmail1">
                                Email address
                              </label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[2] = el)}
                                className={formErros.email ? "errors" : ""}
                                defaultValue={clientData.email}
                                placeholder="example@mail.com"
                                type="email"
                                name="email"
                                onChange={(e) => changeProfileData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 2)}
                              ></Form.Control>
                              {formErros.email ? <p style={{ color: "red" }}>*{formErros.email.message}</p> : ""}

                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>

                          
                           <Col md="4">
                            <Form.Group>
                              <label>Client ID</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[3] = el)}
                                className={formErros.cnic ? "errors" : ""}
                                defaultValue={clientData.cnic}
                                placeholder="Client ID"
                                type="text"
                                name="cnic"
                                onChange={(e) => changeProfileData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 3)}
                              ></Form.Control>
                              {formErros.cnic ? <p style={{ color: "red" }}>*{formErros.cnic.message}</p> : ""}

                            </Form.Group>
                          </Col>
                           <Col md="4">
                            <Form.Group>
                              <label>Mobile No</label>
                              {/* <Form.Control
                                className={formErros.phone_no ? "errors" : ""}

                                defaultValue={clientData.phone_no}
                                placeholder="Mobile No"
                                type="text"
                                name="phone_no"
                                onChange={(e) => changeProfileData(e)}
                              ></Form.Control> */}
                              <MobileNumberField 
                                refr={(el) => (inputRefs.current[4] = el)}
                                mobileNumber={clientData.phone_no} 
                                name={'phone_no'} 
                                setMobileNumber={changeProfileData}
                                onkeydown={(event) => handleKeyDown(event, 4)}
                              />
                            
                              {formErros.phone_no ? <p style={{ color: "red" }}>*{formErros.phone_no.message}</p> : ""}

                            </Form.Group>
                          </Col>
                          <Col md="4">
                            <Form.Group>
                              <label>Address</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[5] = el)}
                                className={formErros.address ? "errors" : ""}
                                defaultValue={clientData.address}
                                placeholder="Home Address"
                                type="text"
                                name="address"
                                onChange={(e) => changeProfileData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 5)}
                              ></Form.Control>
                              {formErros.address ? <p style={{ color: "red" }}>*{formErros.address.message}</p> : ""}

                            </Form.Group>
                          </Col>
                        </Row>


                      
                        <Row>
                          <Col md="4">
                            <Form.Group>
                              <label>City</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[6] = el)}
                                className={formErros.city ? "errors" : ""}
                                defaultValue={clientData.city}
                                placeholder="City"
                                type="text"
                                name="city"
                                onChange={(e) => changeProfileData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 6)}
                              ></Form.Control>
                              {formErros.city ? <p style={{ color: "red" }}>*{formErros.city.message}</p> : ""}

                            </Form.Group>
                          </Col>
                          <Col md="4">
                            <Form.Group>
                              <label>Provience/State</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[7] = el)}
                                className={formErros.provience ? "errors" : ""}
                                defaultValue={clientData.provience}
                                placeholder="Provience/State"
                                type="text"
                                name="provience"
                                onChange={(e) => changeProfileData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 7)}
                              ></Form.Control>
                              {formErros.provience ? <p style={{ color: "red" }}>*{formErros.provience.message}</p> : ""}

                            </Form.Group>
                          </Col>
                           <Col md="4">
                            <Form.Group>
                              <label>Country</label>
                              <select 
                                ref={(el) => (inputRefs.current[8] = el)}
                                className={formErros.country ? "errors form-control" : "form-control"}
                                value={clientData.country}
                                name="country"
                                onChange={(e) => changeProfileData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 8)}
                              >
                                <CountryList/>
                              </select>
                              
                              {formErros.country ? <p style={{ color: "red" }}>*{formErros.country.message}</p> : ""}

                            </Form.Group>
                          </Col>


                        </Row>
                     
                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <label>Domain of Business</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[9] = el)}
                                className={formErros.domain ? "errors" : ""}
                                defaultValue={clientData.domain}
                                placeholder="Domain of Business"
                                type="text"
                                name="domain"
                                onChange={(e) => changeProfileData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 9)}
                              ></Form.Control>
                              {formErros.domain ? <p style={{ color: "red" }}>*{formErros.domain.message}</p> : ""}

                            </Form.Group>
                          </Col>
                          <Col md="6">
                              <Form.Group>
                                <label>Select Project From</label>
                                <select 
                                  ref={(el) => (inputRefs.current[10] = el)}
                                  value={selectProjectFrom} 
                                  className="form-control" 
                                  name="selectProject" 
                                  id="selectProject" 
                                  onChange={(e)=>{
                                    if(e.target.value==="new"){
                                      window.open("https://pm.officehassle.com/", "_blank")
                                      setSelectProjectFrom('new')
                                    }else if(e.target.value==="custom"){
                                      setSelectProjectFrom('custom')
                                    }else if(e.target.value==="existing"){
                                      setSelectProjectFrom('existing')
                                    }
                                }}
                                onKeyDown={(event) => handleKeyDown(event, 10)}
                                >
                                  <option value="existing">Existing Projects</option>
                                  <option value="custom">Custom Projects</option>
                                  <option value="new">Create New Project</option>
                                </select>
                              </Form.Group>
                            </Col>
                        </Row>
                        
                        {
                          selectProjectFrom === 'custom' ? <Row>
                              <Col md="12">
                                  {
                                    customProjects.map((project, idx)=>{
                                      return <Form.Group>
                                      <label>{ (idx+1)===1  ? "Custom Projects" : ""}</label>
                                      <Form.Control
                                          ref={(el) => (inputRefs.current[11] = el)}
                                          defaultValue={project.value}
                                          placeholder={"Project "+ (idx+1)}
                                          type="text"
                                          id={project.id}
                                          value={project.value}
                                          onChange={(e) => {setCustomProjects((cProjects)=>{
                                            return cProjects.map(p => p.id == e.target.id ? {...p, value : e.target.value} : {...p})
                                          })}}
                                          onKeyDown={(event) => handleKeyDown(event, 11)}
                                      >
                                        </Form.Control>
                                      </Form.Group>
                                    })
                                  }
                              </Col>
                              <Col md="7">
                            <button onClick={()=>{setCustomProjects([...customProjects, {id : Math.random(1, 1000),  value : ''}])}} type="button" className="btn-fill pull-right btn btn-info mt-4"><i className="fas fa-plus"></i></button>
                          </Col>
                          </Row> : <Row>
                          <Col md="12">
                            <Form.Group>
                              <label>Projects</label>
                              <MultiSelect
                               
                                options={projects}
                                value={selectedProjects}
                                onChange={setSelectedProjects}
                                labelledBy="Select Client Projects"
                                isLoading={projectsLoading}
                              />
                            </Form.Group>
                          </Col> 
                        </Row>

                        }
                        <Row>
                          <Col md="12">
                            <Form.Group controlId="formFile" className="">
                              <Form.Label>Change Attachment</Form.Label><br />
                              {clientData.attachement ? <Form.Label><a href={BASE_URL + clientData.attachement} target="_blank">{clientData.attachement}</a></Form.Label> : ""}

                              <Form.Control type="file" name="attachement" onChange={(e) => addAttachment(e)} />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <label>Description</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[12] = el)}
                                className={formErros.description ? "errors" : ""}
                                cols="80"
                                defaultValue={clientData.description == "undefined" ? "" : clientData.description}
                                placeholder="Description"
                                rows="2"
                                as="textarea"
                                name="description"
                                onChange={(e) => changeProfileData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 12)}
                              ></Form.Control>
                              {formErros.description ? <p style={{ color: "red" }}>*{formErros.description.message}</p> : ""}

                            </Form.Group>
                          </Col>



                        </Row>
                        <br></br>
                        {
                          showLoading ? <><ProgressBar animated variant="success" now={loaded} /><br></br></> : ""
                        }

                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                          onClick={(e) => { updateToClient(e) }}
                        >
                          Edit Client
                        </Button>
                        <div className="clearfix"></div>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>

              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </div>


    </>
  );
}

export default editClient;
