import { countries } from "countries";

const CountryList = ()=>{
    return <>
    <option value="">Select...</option>
    {
        countries.map(country=>{
            return <option value={country}>{country}</option>
        })
    }
    </>
}

export default CountryList;