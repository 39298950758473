import register from "views/register";
import AdminLayout from "layouts/Admin.js";
import EmployeeLayout from "layouts/Employee";
import login from "views/login";
import editEmployee from "views/editEmployee";
import addBank from "views/addBank"
import editbank from "views/editbank"
import addAmount from "views/addAmount"
import salary from "views/Salary"
import salaryAccount from "views/salaryAccount"
import salaryHistory from "views/salaryHistory"
import PaySalary from "./views/paySalary"
import addExpense from "views/addExpense";
import editExpense from "views/editExpense";
import SalaryInvoice from "./views/salaryInvoice"
import addEmployee from "views/addEmployee";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import paymentSuccess from "views/paymentSuccess";
import paymentFailed from "views/paymentFailed";
import addClient from "views/addClient";
import editClient from "views/editClient";
import recievedMoney from "views/recievedMoney";
import participants from "views/participants";
import scheduleMeeting from "views/scheduleMeeting";
import editMeeting from "views/editMeeting";
import editAmount from "views/editAmount";
import bankTransactionHistory from "views/bankTransactionsHistory";
import EditSalary from "views/editSalary";
import accountVarieficationSuccess from "views/accountVarieficationSuccess";
import accountVerieficationFailed from "views/accountVerieficationFailed";
import addPaidTax from "views/addPaidTax";
import editPaidTax from "views/editPaidTax";
import TwoFactorAuthentication from "views/TwoFactorAuthentication";
import empDashboardRoutes from "employeeRoutes";
import Projects from "views/Projects";
import AddProject from "views/addProject";
import EditProject from "views/editProject";
import AddMilestone from "views/addMilestone";
import EditMilestone from "views/editMilestone";
import AddTask from "views/addTask";
import EditTask from "views/editTask";
import PaySalaries from "views/paySalaries";
import AddInvoice from "views/addInvoice";
import EditInvoice from "views/editInvoice";

export const appRoles = {
    admin: "admin",
    employee: "employee"
  }

  
  const AuthConsumer = ({ children }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    return children({ user: user, isAuthenticated:  JSON.parse(localStorage.getItem('token')) });
}

const canAccessRoute = (user, role) => {
     
      if(user.role === role){
        return true
      }
    return false
}

  
const ProtctedRoutes = ({ children, role, ...rest }) => (
    <AuthConsumer>
      {({ user, isAuthenticated }) => {
        if (isAuthenticated) {   
          
          if (canAccessRoute(user, role)) {
            if(JSON.parse(localStorage.getItem('two_factor'))){
                return (<Redirect to="/twoFactorAuthentication"/>)
            }else{
                
                return  (<Route {...rest} >{children}</Route>) 
            }
            
          } 
          
          else {
              if(user.role === "admin"){
                if(JSON.parse(localStorage.getItem('two_factor'))){
                  return (<Redirect to="/twoFactorAuthentication"/>)}
                else if(window.location.pathname==="" || window.location.pathname==="/"){
                  return <Redirect to="/admin/dashboard"  noThrow/>
                }
                else return <Redirect to={window.location.pathname+window.location.search}  noThrow/>
              }
              
              else{ 
                if(JSON.parse(localStorage.getItem('two_factor'))){
                  return (<Redirect to="/twoFactorAuthentication"/>)}
                return <Redirect to={window.location.pathname}  noThrow/>}
          }
          
        } else {
          return (<Redirect from="" to="/login" />);
        }
      }}
    </AuthConsumer>
  )

  const LoginRoute = ({ children, ...rest }) => (
    <AuthConsumer>
      {({ user, isAuthenticated }) => {
        console.log(rest);
        if (isAuthenticated) {
          if(user.role === "admin")
            return <Redirect to="/admin/dashboard"  noThrow/>
          else return <Redirect to="/employee/profile"  noThrow/>
        } 
        else {
          return <Route {...rest} >{children}</Route>;
        }
      }}
    </AuthConsumer>
  );


const AppRouter = ()=>{
      
    return <>

        <LoginRoute component={login} path="/login" />

        <Route path="/verieficationSuccess" component={accountVarieficationSuccess} exact/>
        <Route path="/verieficationFailed" component={accountVerieficationFailed} exact/>  
        <Route path="/register" component={register}/>
        <Route path="/twoFactorAuthentication" component={TwoFactorAuthentication} exact/>

        <ProtctedRoutes path="/salaryAccount/:id" component={salaryAccount} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/addAmount/:id" component={addAmount}   role={appRoles.admin} exact/>
        <ProtctedRoutes path="/editAmount/:id" component={editAmount}   role={appRoles.admin} exact />
        <ProtctedRoutes path="/bankTransactionHistory/:id" component={bankTransactionHistory}   role={appRoles.admin} exact/>
        <ProtctedRoutes path="/addEmployee" component={addEmployee} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/addBank" component={addBank} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/salary" component={salary} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/editSalary/:id" component={EditSalary} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/salaryHistory" component={salaryHistory} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/editEmployee/:id" component={editEmployee} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/editbank/:id" component={editbank} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/paySalary/" component={PaySalary} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/paySalaries" component={PaySalaries} role={appRoles.admin} exact/>
        <ProtctedRoutes path = "/salaryInvoice/:id" component={SalaryInvoice} role={appRoles.admin} exact/>
        <ProtctedRoutes path = "/addPaidTax/" component={addPaidTax} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/editPaidTax/:id" component={editPaidTax} role={appRoles.admin} exact/>
        <ProtctedRoutes path = "/addExpense/" component={addExpense} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/editExpense/:id" component={editExpense} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/paymentSuccess" component={paymentSuccess} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/paymentFailed" component={paymentFailed} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/addClient/" component={addClient} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/editClient/:id" component={editClient} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/recievedMoney/:id" component={recievedMoney} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/scheduleMeeting/" component={scheduleMeeting} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/meetingParticipants/:id" component={participants} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/editMeeting/:id" component={editMeeting}  role={appRoles.admin} exact/>
        <ProtctedRoutes path = "/addProject/" component={AddProject} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/editProject/:id" component={EditProject}  role={appRoles.admin} exact/>
        <ProtctedRoutes path = "/addMilestone/" component={AddMilestone} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/editMilestone/:id" component={EditMilestone}  role={appRoles.admin} exact/>
        <ProtctedRoutes path = "/addTask/" component={AddTask} role={appRoles.admin} exact/>
        <ProtctedRoutes path="/editTask/:id" component={EditTask}  role={appRoles.admin} exact/>
        
        
        <ProtctedRoutes path="/admin/*" role={appRoles.admin}  render={(props) => <AdminLayout {...props} />} />
        
        <ProtctedRoutes path="/employee/*" role={appRoles.employee}  render={(props) => <EmployeeLayout {...props} />} />
        
        <ProtctedRoutes path = "/addInvoice/" component={AddInvoice} role={appRoles.admin} exact/>
        <ProtctedRoutes path = "/editInvoice/:id" component={EditInvoice} role={appRoles.admin} exact/>
    
    </>
}

export default AppRouter