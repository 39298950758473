import React, { useState } from "react";
import axios from "axios";
import { popup } from "utils/popup";

import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col
}
  from "react-bootstrap";
import Sidebar from "components/Sidebar/Sidebar";
import sidebarImage from "assets/img/sidebar-3.jpg";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import { Link } from "react-router-dom";
import toast from "../utils/toast";
import { useHistory } from "react-router-dom";
import routes from "routes.js";
import { BASE_URL } from "apiUrl";


function editMeeting() {

  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const mainPanel = React.useRef(null);


  const [selectEmployee, setSelectEmployee] = useState(false)
  const [selectClient, setSelectClient] = useState(false)
  const [employees, setEmployees] = useState([])
  const [clients, setClients] = useState([])
  const [selectedEmployees, setSelectedEmployees] = useState([])

  const [selectedClients, setSelectedClients] = useState([])
  const [selectAllEmployees, setSelectAllEmployees] = useState(false)
  const [selectAllClients, setSelectAllClients] = useState(false)
  const [description, setDescription] = useState("")
  const [formErros, setFormErrors] = useState({})
  const [title, setTitle] = useState("")
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [eventId, setEventId] = useState(false)
  const history = useHistory()


  var gapi = window.gapi
  var CLIENT_ID = "447650484903-mj1rmujsbf9non1it440pnnfejccg7vp.apps.googleusercontent.com"
  var API_KEY = "AIzaSyBqj6_jRXOp8u1absD6h-fT02K7wRNhm3M"
  var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
  var SCOPES = "https://www.googleapis.com/auth/calendar.events"



  const updateTheGoogleCallender = (emails) => {
    gapi.load('client:auth2', () => {
      console.log('loaded client')

      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })

      gapi.client.load('calendar', 'v3', () => console.log('bam!'))

      gapi.auth2.getAuthInstance().signIn()
        .then(() => {

          var event = {
            'summary': `${title}`,
            'location': '',
            'description': `${description}`,
            'start': {
              'dateTime': new Date(startTime),
              'timeZone': 'America/Los_Angeles'
            },
            'end': {
              'dateTime': new Date(endTime),
              'timeZone': 'America/Los_Angeles'
            },

            'attendees': emails.map(email => {
              return { 'email': email }
            }),
            'reminders': {
              'useDefault': false,
              'overrides': [
                { 'method': 'email', 'minutes': 24 * 60 },
                { 'method': 'popup', 'minutes': 10 }
              ]
            }
          }

          var request = gapi.client.calendar.events.insert({
            'calendarId': 'primary',
            'resource': event,
          })

          request.execute(event => {
            console.log(event)

            //history.push('/admin/meetings/')

            const token = localStorage.getItem('token')
            const JWTToken = JSON.parse(token)

            const body = JSON.stringify({

              title: title,
              employees: selectedEmployees,
              clients: selectedClients,
              start_time: startTime,
              end_time: endTime,
              description: description,
              id: event.id

            })


            const url = `${BASE_URL}/api/meeting-management/create-meeting-event/`

            fetch(url, {
              method: 'POST',
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + JWTToken
              },
              body: body
            }).then(response => {

              if (response.status === 201) {
                popup.success("Meeting event created successfully");
                history.push('/admin/meetings')

              }
              else if (response.status === 400) {
                popup.success("Meeting event not successfully");
              }
            })





          })



          // // get events
          // gapi.client.calendar.events.list({
          //   'calendarId': 'primary',
          //   'timeMin': (new Date()).toISOString(),
          //   'showDeleted': false,
          //   'singleEvents': true,
          //   'maxResults': 10,
          //   'orderBy': 'startTime'
          // }).then(response => {
          //   const events = response.result.items
          //   console.log('EVENTS: ', events)
          // })



        })
    })
  }



  React.useEffect(() => {

    getAllClients()
    getAllEmployees()

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);


  const formValidation = () => {


    return true

  }

  const getAllClients = () => {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/client-management/clients/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setClients(data)
          })
        }
        else if (response.status === 401) {
          // alert('not authenticated')
          localStorage.clear()
          window.location.href = '/'
        }
      })


  }
  const getAllEmployees = () => {

    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/employee-management/employees/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setEmployees(data)

          })
        }
        else if (response.status === 401) {
          //alert('not authenticated')
          localStorage.clear()
          window.location.href = '/'
        }
      })

  }



  const createMeetingEvent = (e) => {
    e.preventDefault()
    if (formValidation()) {

      var emails = []
      clients.forEach(client => {
        selectedClients.forEach(selectedClient => {
          if (selectedClient == client.id) {
            emails.push(client.email)
          }
        })
      })

      employees.forEach(emp => {
        selectedEmployees.forEach(selectedEmp => {
          if (selectedEmp == emp.id) {
            emails.push(emp.email)
          }
        })
      })
      console.log(emails)
      updateTheGoogleCallender(emails)




    }
  }


  const clickOnSelectEmployee = (e) => {
    console.log(e.target.value)
    setSelectedEmployees([])
    setSelectAllEmployees(false)
    setSelectEmployee(!selectEmployee)
  }

  const clickOnSelectClient = (e) => {
    console.log(e.target.value)
    setSelectedClients([])
    setSelectAllClients(false)
    setSelectClient(!selectClient)
  }

  const setTheSelectedEmployees = (e) => {

    if (e.target.id === 'all_employee' && selectAllEmployees == false) {
      setSelectedEmployees([])
      const selectEmp = []
      employees.forEach(emp => selectEmp.push(emp.id.toString()))
      setSelectedEmployees(selectEmp)
      setSelectAllEmployees(!selectAllEmployees)
    }
    else if (e.target.id === 'all_employee' && selectAllEmployees) {
      setSelectedEmployees([])
      setSelectAllEmployees(!selectAllEmployees)
    }
    else {
      const selectEmp = selectedEmployees
      selectEmp.indexOf(e.target.id) < 0 ? selectEmp.push(e.target.id) : selectEmp.splice(selectEmp.indexOf(e.target.id), 1)
      setSelectedEmployees(selectEmp)
    }
    console.log(selectedEmployees)

  }

  const setTheSelectedClients = (e) => {
    if (e.target.id === 'all_clients' && selectAllClients == false) {
      setSelectedClients([])
      const selectCli = []
      clients.forEach(cli => selectCli.push(cli.id.toString()))
      setSelectedClients(selectCli)
      setSelectAllClients(!selectAllClients)
    }
    else if (e.target.id === 'all_clients' && selectAllClients) {
      setSelectedClients([])
      setSelectAllClients(!selectAllClients)
    }
    else {
      const selectCli = selectedClients
      selectCli.indexOf(e.target.id) < 0 ? selectCli.push(e.target.id) : selectCli.splice(selectCli.indexOf(e.target.id), 1)
      setSelectedClients(selectCli)
    }
    console.log(selectedClients)

  }

  return (
    <>
      <div className="wrapper">
        <Sidebar color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Container fluid>
              <Row>





                <Col md="8" className="order-md-1">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">Schedule Meeting Event</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={(e) => createMeetingEvent(e)}>

                        <Row>
                          <Col sm="12" xs="12" md="12">
                            <Form.Group>
                              <label>Meeting Title</label>
                              <Form.Control
                                className={formErros.title ? "errors" : ""}
                                defaultValue={title}
                                placeholder="Meeting Title"
                                type="text"
                                onChange={(e) => setTitle(e.target.value)}
                              ></Form.Control>
                              {formErros.title ? <p style={{ color: "red" }}>*{formErros.title.message}</p> : ""}

                            </Form.Group>
                          </Col>
                        </Row>



                        <Row>
                          <Col md="6" >
                            <Form.Group className="">
                              <label>Select Participant</label><br />
                              <input type="checkbox" className="form-check-input ml-3" checked={selectEmployee} onChange={(e) => { clickOnSelectEmployee(e) }} />
                              <label className="form-check-label ml-5 " htmlFor="exampleCheck1">Employees</label>
                            </Form.Group>
                            {
                              selectEmployee ? <Col style={{ overflowY: 'auto', height: '200px' }}>
                                <Form.Group >



                                </Form.Group>

                                <Form.Group className="" >

                                  <input type="checkbox" className="form-check-input ml-3" id="all_employee" onChange={(e) => setTheSelectedEmployees(e)} />
                                  <label className="form-check-label ml-5 " htmlFor="exampleCheck1">Select All</label>
                                </Form.Group>


                                {!selectAllEmployees ? employees.map((emp) => {
                                  return (
                                    <Form.Group className="">

                                      <input type="checkbox" className="form-check-input ml-3" onChange={(e) => setTheSelectedEmployees(e)} id={emp.id} />
                                      <label className="form-check-label ml-5 " htmlFor="exampleCheck1">{emp.first_name} {emp.last_name}</label>
                                    </Form.Group>
                                  )
                                }) : ""}
                              </Col> : ""
                            }
                          </Col>
                          <Col md="6">


                            <Form.Group className="">
                              <br />
                              <input type="checkbox" className="form-check-input ml-3" onChange={(e) => clickOnSelectClient(e)} checked={selectClient} />
                              <label className="form-check-label ml-5 " htmlFor="exampleCheck1">Clients</label>
                            </Form.Group>

                            {
                              selectClient ? <Col style={{ overflowY: 'auto', height: '200px' }}>
                                <Form.Group>



                                </Form.Group>

                                <Form.Group className="">

                                  <input type="checkbox" className="form-check-input ml-3" id="all_clients" onChange={(e) => setTheSelectedClients(e)} />
                                  <label className="form-check-label ml-5 " htmlFor="exampleCheck1">Select All</label>
                                </Form.Group>
                                {!selectAllClients ? clients.map((cl) => {
                                  return (
                                    <Form.Group className="">

                                      <input type="checkbox" className="form-check-input ml-3" onChange={(e) => setTheSelectedClients(e)} id={cl.id} />
                                      <label className="form-check-label ml-5 " htmlFor="exampleCheck1">{cl.first_name} {cl.last_name}</label>
                                    </Form.Group>
                                  )
                                })
                                  : ""}
                              </Col> : ""
                            }



                          </Col>





                        </Row>




                        <Row>
                          <Col sm="12" xs="12" md="12">
                            <Form.Group>
                              <label>Start Time</label>
                              <Form.Control
                                className={formErros.startTime ? "errors" : ""}
                                placeholder="Start Time"
                                type="datetime-local"
                                onChange={(e) => setStartTime(e.target.value)}
                              ></Form.Control>
                              {formErros.endTime ? <p style={{ color: "red" }}>*{formErros.endTime.message}</p> : ""}

                            </Form.Group>
                          </Col>
                        </Row>


                        <Row>
                          <Col sm="12" xs="12" md="12">
                            <Form.Group>
                              <label>End Time</label>
                              <Form.Control
                                className={formErros.endTime ? "errors" : ""}
                                placeholder="Start Time"
                                type="datetime-local"
                                onChange={(e) => setEndTime(e.target.value)}
                              ></Form.Control>
                              {formErros.endTime ? <p style={{ color: "red" }}>*{formErros.endTime.message}</p> : ""}

                            </Form.Group>
                          </Col>
                        </Row>




                        <Col md="12">
                          <Form.Group>
                            <label>Description</label>
                            <Form.Control
                              cols="80"

                              placeholder="Here can be your description"
                              rows="4"
                              as="textarea"
                              name="description"
                              onChange={(e) => setDescription(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Row>


                        </Row>

                        <br />
                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                        >
                          Add Meeting Event
                        </Button>
                        <div className="clearfix"></div>

                      </Form>
                    </Card.Body>
                  </Card>
                </Col>


              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </div>


    </>
  );
}

export default editMeeting;
