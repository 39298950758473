import React, { useState } from "react";
import axios from "axios";
import "../assets/css/error.css"
import { popup } from "utils/popup";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  ProgressBar
} from "react-bootstrap";
import Sidebar from "components/Sidebar/Sidebar";
import sidebarImage from "assets/img/sidebar-3.jpg";
import toast from "../utils/toast";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "../assets/css/file-button.css"
import img from "../assets/img/profile.png"
import routes from "routes.js";
import { BASE_URL } from "apiUrl";
import CountryList from "./CountryList";
import MobileNumberField from "./MobileNumberField";

function User() {

  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const mainPanel = React.useRef(null);
  const history = useHistory()
  const [data, setData] = useState({})
  const [empImage, setEmpImage] = useState(null)
  const [submited, setSubmited] = useState(false)
  const [formErros, setFormErrors] = useState({})
  const [showLoading, setShowLoading] = useState(false)
  const [loaded, setLoaded] = useState(0)

  const [currentInput, setCurrentInput] = useState(1);
  const inputRefs = React.useRef([]);

  const addData = (e) => {


    setData({
      ...data,
      [e.target.name]: e.target.value
    })

  }




  const addImage = (e) => {
    setData({
      ...data,
      "image": e.target.files[0]
    })

    setEmpImage(URL.createObjectURL(e.target.files[0]))

  }

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location, submited]);


  const formValidation = () => {

    if (!data.first_name) {
      setFormErrors({
        ...formErros,
        first_name: {
          error: true,
          message: "Please fill the first name"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }


    else if (data.first_name.trim().length < 3) {
      setFormErrors({
        ...formErros,
        first_name: {
          error: true,
          message: "First name must be contain upto 3 characters"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else if (!isNaN(data.first_name)) {
      setFormErrors({
        ...formErros,
        first_name: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else {

      delete formErros.first_name

    }
    console.log(formErros)

    //last name validation
    if (!data.last_name) {
      setFormErrors({
        ...formErros,
        last_name: {
          error: true,
          message: "Please fill the last name"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    } else if (data.last_name.trim().length < 3) {
      setFormErrors({
        ...formErros,
        last_name: {
          error: true,
          message: "Last name must be contain upto 3 characters"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else if (!isNaN(data.last_name)) {
      setFormErrors({
        ...formErros,
        last_name: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else {

      delete formErros.last_name

    }

    //validate the position 

    if ((!data.position) || data.position.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        position: {
          error: true,
          message: "Please fill position"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else if (!isNaN(data.position)) {
      setFormErrors({
        ...formErros,
        position: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else {

      delete formErros.position

    }
    //validation for mobile number 

    if (!data.mobile_number) {
      setFormErrors({
        ...formErros,
        mobile_number: {
          error: true,
          message: "Please fill the mobile number"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(data.mobile_number)) {
      setFormErrors({
        ...formErros,
        mobile_number: {
          error: true,
          message: "Please enter a valid mobile number e.g. +923001234567"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    } else {

      delete formErros.mobile_number

    }

    //validating for email


    if (!data.email) {
      setFormErrors({
        ...formErros,
        email: {
          error: true,
          message: "Please fill the email"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    } else if (!validateEmail(data.email)) {
      setFormErrors({
        ...formErros,
        email: {
          error: true,
          message: "Please enter a valid email"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    } else {

      delete formErros.email

    }

    
    //bank name validation 
    if (!data.bank_name) {
      setFormErrors({
        ...formErros,
        bank_name: {
          error: true,
          message: "Please fill the bank name"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    } else if (data.bank_name.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        bank_name: {
          error: true,
          message: "Please fill the bank name"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else if (!isNaN(data.bank_name)) {
      setFormErrors({
        ...formErros,
        bank_name: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else {

      delete formErros.bank_name

    }

    //validate account no 

    if (!data.account_no) {
      setFormErrors({
        ...formErros,
        account_no: {
          error: true,
          message: "Please fill the account no "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else if (data.account_no.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        account_no: {
          error: true,
          message: "Please fill the account no "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else if (isNaN(data.account_no)) {
      setFormErrors({
        ...formErros,
        account_no: {
          error: true,
          message: "Only digits allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else {

      delete formErros.account_no

    }
    //branch name
    if ((!data.branch_name) || data.branch_name.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        branch_name: {
          error: true,
          message: "Please fill branch name"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else if (!isNaN(data.branch_name)) {
      setFormErrors({
        ...formErros,
        branch_name: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else {

      delete formErros.branch_name

    }
    //validate address
    if ((!data.address) || data.address.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        address: {
          error: true,
          message: "Please fill address"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else if (!isNaN(data.address)) {
      setFormErrors({
        ...formErros,
        address: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else {

      delete formErros.address

    }
    //validate city 
    if ((!data.city) || data.city.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        city: {
          error: true,
          message: "Please fill city"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else if (!isNaN(data.city)) {
      setFormErrors({
        ...formErros,
        city: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else {

      delete formErros.city

    }


    //validate provience
    if ((!data.provience) || data.provience.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        provience: {
          error: true,
          message: "Please fill provience"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else if (!isNaN(data.provience)) {
      setFormErrors({
        ...formErros,
        provience: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else {

      delete formErros.provience

    }


    //validate country
    if ((!data.country) || data.country.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        country: {
          error: true,
          message: "Please fill country"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else if (!isNaN(data.country)) {
      setFormErrors({
        ...formErros,
        country: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else {

      delete formErros.country

    }


    return true
  }


  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  async function setEmployee(e) {

    e.preventDefault();

    if (formValidation()) {
      setShowLoading(true)
      let form_data = new FormData();
      form_data.append('first_name', data.first_name)
      form_data.append('last_name', data.last_name)
      form_data.append('address', data.address)
      form_data.append('cnic', data.cnic)
      form_data.append('city', data.city)
      form_data.append('branch_name', data.branch_name)
      form_data.append('email', data.email)
      form_data.append('mobile_number', data.mobile_number)
      form_data.append('bank_name', data.bank_name)
      form_data.append('account_no', data.account_no)
      form_data.append('position', data.position)
      form_data.append('provience', data.provience)
      form_data.append('country', data.country)
      if(data.joining_date){
        form_data.append('joining_date', data.joining_date)
      }
      if(data.resigning_date){
        form_data.append('resigning_date', data.resigning_date)
      }
      if (data.image) {
        form_data.append('image', data.image, data.image.name)
      }



      const token = localStorage.getItem('token')
      const JWTToken = JSON.parse(token)


      const url = `${BASE_URL}/api/employee-management/add-employee/`


      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": "Bearer " + JWTToken
        },
        onUploadProgress: (p) => {
          // console.log(Math.ceil((p.loaded/p.total)*100))
          // setLoaded(Math.ceil((p.loaded/p.total)*100))
          setLoaded(p.loaded)
        }
      }
      try {

        const res = await axios.post(url, form_data, config)

        if (res.status === 201) {
          popup.success("Employee added successfully")
          history.push('/admin/table/')
          setSubmited(!submited)
          setData({})

        }
        else if (res.status === 400) {
          popup.error("Employee not added successfully")
        }
        else if(res.status === 401){
          localStorage.clear()
          window.location.href = '/'
        }
        setShowLoading(false)
        setLoaded(0)
      }
      catch (err) {
        console.log(err)
        popup.error(err)
        setShowLoading(false)
        setLoaded(0)
      }
    }
  }

  const handleKeyDown = (event, index) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.current.length) {
        inputRefs.current[nextIndex].focus();
        setCurrentInput(nextIndex);
        return 
      }
      // here submit the form
      console.log('Submiting.....');
      setEmployee(event);
      return 
    }else if (event.key === 'Backspace') {
      const currentValue = event.target.value;
      if (currentValue === '' && index > 0) {
        const prevIndex = index - 1;
        inputRefs.current[prevIndex].focus();
        setCurrentInput(prevIndex);
      }
     return 
    }
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar activeLink="/table" color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Container fluid>
              <Row>

                <Col md="4" className="order-md-2">
                  <Card className="card-user">
                    <div className="card-image">
                      <img
                        alt="..."
                        src={
                          require("assets/img/photo-1431578500526-4d9613015464.jpeg")
                            .default
                        }
                      ></img>
                    </div>
                    <Card.Body>
                      <div className="author">
                        <img
                          alt="..."
                          className="avatar border-gray"
                          src={empImage ? empImage : img}
                        ></img>


                        <Form.Group>

                          <label htmlFor="file-upload" className="custom-file-upload">
                            <i style={{ fontWeight: 900, fontSize: "15px" }} className="fas fa-pencil-alt"></i>
                          </label>
                          <input name="image" accept="image/*" id="file-upload" type="file" onChange={(e) => { addImage(e) }} />

                        </Form.Group>




                      </div>

                    </Card.Body>

                  </Card>
                </Col>


                <Col md="8" className="order-md-1">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">Add Employee</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={(e) => setEmployee(e)}>
                        <Row>
                        </Row>
                        <Row>
                          <Col className="pr-1" md="4">
                            <Form.Group>
                              <label>First Name</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[0] = el)}
                                className={formErros.first_name ? formErros.first_name.error ? "errors" : "" : ""}
                                defaultValue={data.first_name}
                                placeholder="First Name"
                                type="text"
                                name="first_name"
                                onChange={(e) => addData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 0)}
                              ></Form.Control>
                              {formErros.first_name ? formErros.first_name.error ? <p style={{ color: "red" }}>*{formErros.first_name.message}</p> : "" : ""}

                            </Form.Group>
                          </Col>
                          <Col md="4">
                            <Form.Group>
                              <label>Last Name</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[1] = el)}
                                className={formErros.last_name ? formErros.last_name.error ? "errors" : "" : ""}
                                defaultValue={data.last_name}
                                placeholder="Last Name"
                                type="text"
                                name="last_name"
                                onChange={(e) => addData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 1)}
                              ></Form.Control>
                              {formErros.last_name ? <p style={{ color: "red" }}>*{formErros.last_name.message}</p> : ""}

                            </Form.Group>
                          </Col>
                          <Col md="4">
                            <Form.Group>
                              <label>Position</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[2] = el)}
                                className={formErros.position ? "errors" : ""}
                                defaultValue={data.position}
                                placeholder="Position"
                                type="text"
                                name="position"
                                onChange={(e) => addData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 2)}
                              ></Form.Control>
                              {formErros.position ? <p style={{ color: "red" }}>*{formErros.position.message}</p> : ""}

                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          
                          <Col md="4">
                            <Form.Group>
                              <label>Mobile Number</label>
                              {/* <Form.Control
                                className={formErros.mobile_number ? "errors" : ""}
                                defaultValue={data.mobile_number}
                                placeholder="Mobile Number"
                                type="text"
                                name="mobile_number"
                                onChange={(e) => addData(e)}
                              ></Form.Control> */}
                              <MobileNumberField 
                                refr={(el) => (inputRefs.current[3] = el)}
                                mobileNumber={data.mobile_number} 
                                name={'mobile_number'} 
                                setMobileNumber={addData}
                                onkeydown={(event) => handleKeyDown(event, 3)}
                              />
                            
                              {formErros.mobile_number ? <p style={{ color: "red" }}>*{formErros.mobile_number.message}</p> : ""}

                            </Form.Group>
                          </Col>

                          <Col md="4">
                            <Form.Group>
                              <label>Email</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[4] = el)}
                                className={formErros.email ? "errors" : ""}
                                defaultValue={data.email}
                                placeholder="Email"
                                type="email"
                                name="email"
                                onChange={(e) => addData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 4)}
                              ></Form.Control>
                              {formErros.email ? <p style={{ color: "red" }}>*{formErros.email.message}</p> : ""}

                            </Form.Group>
                          </Col>

                          <Col md="4">
                            <Form.Group>
                              <label>Country ID</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[5] = el)}
                                className={formErros.cnic ? "errors" : ""}
                                defaultValue={data.cnic}
                                placeholder="Country ID"
                                type="text"
                                name="cnic"
                                onChange={(e) => addData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 5)}
                              ></Form.Control>
                              {formErros.cnic ? <p style={{ color: "red" }}>*{formErros.cnic.message}</p> : ""}

                            </Form.Group>


                          </Col>

                        </Row>

                       
                        <Row>
                          <Col className="pr-1" md="4">
                            <Form.Group>
                              <label>Bank Name</label>

                              <Form.Control
                               ref={(el) => (inputRefs.current[6] = el)}
                                className={formErros.bank_name ? "errors" : ""}
                                placeholder="Bank Name"
                                defaultValue={data.bank_name}
                                type="text"
                                name="bank_name"
                                onChange={(e) => addData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 6)}
                              >

                              </Form.Control>
                              {formErros.bank_name ? <p style={{ color: "red" }}>*{formErros.bank_name.message}</p> : ""}

                            </Form.Group>
                          </Col>
                          <Col md="4">
                            <Form.Group>
                              <label>Account No</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[7] = el)}
                                className={formErros.account_no ? "errors" : ""}
                                placeholder="Account No"
                                defaultValue={data.account_no}
                                type="text"
                                name="account_no"
                                onChange={(e) => addData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 7)}
                              ></Form.Control>
                              {formErros.account_no ? <p style={{ color: "red" }}>*{formErros.account_no.message}</p> : ""}

                            </Form.Group>
                          </Col>

                          <Col md="4">
                            <Form.Group>
                              <label>Branch Name</label>
                              <Form.Control
                               ref={(el) => (inputRefs.current[8] = el)}
                                className={formErros.branch_name ? "errors" : ""}
                                placeholder="Branch Name"
                                defaultValue={data.branch_name}
                                type="text"
                                name="branch_name"
                                onChange={(e) => addData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 8)}
                              ></Form.Control>
                              {formErros.branch_name ? <p style={{ color: "red" }}>*{formErros.branch_name.message}</p> : ""}

                            </Form.Group>

                          </Col>

                        </Row>


                       

                        <Row>
                          <Col md="3">
                            <Form.Group>
                              <label>Street Address</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[9] = el)}
                                className={formErros.address ? "errors" : ""}
                                defaultValue={data.address}
                                placeholder="Street Address"
                                type="text"
                                name="address"
                                onChange={(e) => addData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 9)}
                              ></Form.Control>
                              {formErros.address ? <p style={{ color: "red" }}>*{formErros.address.message}</p> : ""}

                            </Form.Group>
                          </Col>
                          <Col className="pr-1" md="3">
                            <Form.Group>
                              <label>City</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[10] = el)}
                                className={formErros.city ? "errors" : ""}
                                defaultValue={data.city}
                                placeholder="City"
                                name="city"
                                type="text"
                                onChange={(e) => addData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 10)}
                              ></Form.Control>
                              {formErros.city ? <p style={{ color: "red" }}>*{formErros.city.message}</p> : ""}

                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group>
                              <label>Provience/State</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[11] = el)}
                                className={formErros.provience ? "errors" : ""}
                                defaultValue={data.provience}
                                placeholder="Provience/State"
                                type="text"
                                name="provience"
                                onChange={(e) => addData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 11)}
                              ></Form.Control>
                              {formErros.provience ? <p style={{ color: "red" }}>*{formErros.provience.message}</p> : ""}

                            </Form.Group>
                          </Col>

                           <Col className="pr-1" md="3">
                            <Form.Group>
                              <label>Country</label>
                              <select 
                                ref={(el) => (inputRefs.current[12] = el)}
                                className={formErros.country ? "errors form-control" : "form-control"}
                                defaultValue={data.country}
                                name="country"
                                onChange={(e) => addData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 12)}
                              >
                                <CountryList/>
                              </select>
                              
                              {formErros.country ? <p style={{ color: "red" }}>*{formErros.country.message}</p> : ""}

                            </Form.Group>
                          </Col>

                        </Row>
                     
                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <label>Joining Date</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[13] = el)}
                                className=""
                                placeholder="Joining Date"
                                type="date"
                                name="joining_date"
                                onChange={(e) => addData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 13)}
                              ></Form.Control>
                            </Form.Group>
                          </Col>

                          <Col className="pr-1" md="6">
                            <Form.Group>
                              <label>Resigning Date</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[14] = el)}
                                className=""
                                placeholder="Resigning Date"
                                name="resigning_date"
                                type="date"
                                onChange={(e) => addData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 14)}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>




                        <br></br>
                        {
                          showLoading ? <><ProgressBar animated variant="success" now={loaded} /><br></br></> : ""
                        }
                        <br></br>
                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                          onClick={setEmployee}
                        >
                          Add Employee
                        </Button>
                        <div className="clearfix"></div>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>



              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </div>


    </>
  );
}

export default User;
