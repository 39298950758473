import "./app.css"
import React, { useEffect } from "react";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import firebase from './firebase'
import AppRouter from "appRouter";
const App = () => {

    const notificationAlertRef = React.useRef(null);



    useEffect(() => {

        const msg = firebase.messaging();
        msg.requestPermission().then(() => {

            return msg.getToken();

        }).then((data) => {

        }).catch(err => {

            console.log(err)
        })


    }, [])



    // onMessageListener()
    //   .then((payload) => {
    //    console.log('i am')
    //     toast.success(payload.notification.title);
    //     console.log(payload);
    //   })
    //   .catch((err) => console.log("failed: ", err));


    return (
        <>
            <BrowserRouter>
                <NotificationContainer />
                <Switch>
                    <AppRouter />
                </Switch>
            </BrowserRouter>
        </>
    )
}

export default App