import React, { useState } from "react";
import { popup } from "utils/popup";
import axios from "axios";
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  ProgressBar
}
  from "react-bootstrap";
import Sidebar from "components/Sidebar/Sidebar";
import sidebarImage from "assets/img/sidebar-3.jpg";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import { Link, useParams } from "react-router-dom";
import toast from "../utils/toast";
import { useHistory } from "react-router-dom";
import routes from "routes.js";
import { BASE_URL } from "apiUrl";
import ProjectDropdown from "./ProjectDropdown";
import EditProjectDropdown from "./EditProjectDropdown";
import EditEmployeeDropdown from "./EditEmployeeDropdown";
import EditMilestoneDropdown from "./EditMilestoneDropdown";

function EditTask() {

  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const mainPanel = React.useRef(null);

  const [loaded, setLoaded] = useState(0)
  const [showLoading, setShowLoading] = useState(false)
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [milestone, setMilestone] = useState(null)
  const [milestones, setMilestones] = useState([])
  const [employee, setEmployee] = useState(null)
  const [employees, setEmployees] = useState([])
  const [taskStatus, setTaskStatus] = useState(null)
  const [formErros, setFormErrors] = useState({})
  const history = useHistory()
  const { id } = useParams()


  React.useEffect(() => {
    getTaskData()
    getAllMilestones()
    getAllEmployee()
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);



  const getAllMilestones = () => {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/project-management/milestones/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setMilestones(data)
          })
        }
        else if (response.status === 401) {
          setMilestones([])
          localStorage.clear()
          window.location.href = '/'
        }
      })
  }


  const getAllEmployee = () => {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/employee-management/employees/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setEmployees(data)
          })
        }
        else if (response.status === 401) {
          setEmployees([])
          localStorage.clear()
          window.location.href = '/'
        }
      })
  }

  async function getTaskData() {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
    let item = id
    let url = `${BASE_URL}/api/project-management/task/${item}/`
    let result = await fetch(url, {
      method: "GET",
      headers: {

        "Authorization": "Bearer " + JWTToken
      }
    })

    result = await result.json();
    console.log(result)
    setDates(result.start_date, result.end_date)
    setTitle(result.title)
    setMilestone(result.milestone.id)
    setEmployee(result.assign_to.id)
    setDescription(result.description)
    setTaskStatus(result.status)


  }

  const setDates = (start, end) => {
    let start_date = new Date(start)
    let date = start_date.setDate(start_date.getDate())
    setStartDate(new Date(date).toISOString().split('T')[0])
    let end_date = new Date(end)
    const edate = end_date.setDate(end_date.getDate())
    setEndDate(new Date(edate).toISOString().split('T')[0])

  }

  const formValidation = () => {

    //validate title 
    if (title.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        title: {
          error: true,
          message: "Please enter the task title "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else if (title.trim().length < 10) {
      setFormErrors({
        ...formErros,
        title: {
          error: true,
          message: "Milestone title must be greater than 10 characters"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else {

      delete formErros.title

    }

    if (milestone === null) {
      setFormErrors({
        ...formErros,
        milestone: {
          error: true,
          message: "Please select milestone"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else {

      delete formErros.milestone

    }

    if (employee === null) {
      setFormErrors({
        ...formErros,
        employee: {
          error: true,
          message: "Please select employee"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else {

      delete formErros.employee

    }


    return true

  }






  const updateTask = async (e) => {
    e.preventDefault()
    if (formValidation()) {
      setShowLoading(true)
      const token = localStorage.getItem('token')
      const JWTToken = JSON.parse(token)
      const start_date = new Date(startDate + 'Z')
      const end_date = new Date(endDate + 'Z')
      const body = JSON.stringify({

        title: title,
        status: taskStatus,
        milestone: milestone,
        assign_to: employee,
        description: description,
        start_date: start_date.toISOString(),
        end_date: end_date.toISOString(),


      })

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + JWTToken
        },
        onUploadProgress: (p) => {
          // console.log(Math.ceil((p.loaded/p.total)*100))
          // setLoaded(Math.ceil((p.loaded/p.total)*100))
          setLoaded(p.loaded)
        }
      }

      const url = `${BASE_URL}/api/project-management/update-task/${id}/`


      try {
        const resp = await axios.put(url, body, config)
        console.log(resp)
        if (resp.status === 201) {

          popup.success("Task updated successfully");


        }
        else if (resp.status === 400) {
          popup.error("Task not updated successfully");
        }

      }
      catch (err) {
        console.log(err)
        popup.error("Task not updated successfully");
      }
      finally {
        setShowLoading(false)
      }

    }
  }




  return (
    <>
      <div className="wrapper">
        <Sidebar activeLink="/projectManagement" color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Container fluid>
              <Row>

                <Col md="8" className="order-md-1">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">Edit Task</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={(e) => updateTask(e)}>
                        <Row>
                          <Col sm="12" >
                            <Form.Group>
                              <label>Title</label>
                              <Form.Control
                                className={formErros.title ? formErros.title.error ? "errors" : "" : ""}
                                defaultValue={title}
                                placeholder="Title"
                                type="text"
                                name="title"
                                onChange={(e) => { setTitle(e.target.value) }}

                              ></Form.Control>
                              {formErros.title ? formErros.title.error ? <p style={{ color: "red" }}>*{formErros.title.message}</p> : "" : ""}

                            </Form.Group>
                          </Col>

                        </Row>

                        <Row>
                          <Col md="12">

                            <div className="form-group">
                              <label>Task Milestone </label>
                              <EditMilestoneDropdown milestones={milestones} setMilestone={setMilestone} selectedMilestone={milestone} />
                              {formErros.milestone ? <p style={{ color: "red" }}>*{formErros.milestone.message}</p> : ""}
                            </div>


                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">

                            <div className="form-group">
                              <label>Task Assign To </label>
                              <EditEmployeeDropdown employees={employees} setEmployee={setEmployee} selectedEmployee={employee} />
                              {formErros.employee ? <p style={{ color: "red" }}>*{formErros.employee.message}</p> : ""}
                            </div>


                          </Col>
                        </Row>


                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <label>Description</label>
                              <Form.Control
                                value={description}
                                cols="80"
                                placeholder="Task description here"
                                rows="4"
                                as="textarea"
                                name="description"
                                onChange={(e) => setDescription(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          </Col>

                        </Row>

                        <Row>
                          <Col sm="12" >
                            <Form.Group>
                              <label>Task Start Date </label>
                              <Form.Control
                                defaultValue={startDate}
                                className={formErros.startDate ? "errors" : ""}
                                placeholder="Task Start Date"
                                type="date"
                                onChange={(e) => setStartDate(e.target.value)}
                                required={true}
                              ></Form.Control>
                              {formErros.startDate ? <p style={{ color: "red" }}>*{formErros.startDate.message}</p> : ""}

                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="12" >
                            <Form.Group>
                              <label>Task Expected End Date </label>
                              <Form.Control
                                defaultValue={endDate}
                                className={formErros.endDate ? "errors" : ""}
                                placeholder="Task Expected End Date"
                                type="date"
                                onChange={(e) => setEndDate(e.target.value)}
                                required={true}
                              ></Form.Control>
                              {formErros.endDate ? <p style={{ color: "red" }}>*{formErros.endDate.message}</p> : ""}

                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>

                        </Row>

                        <Row>
                          <Col>
                            <Form.Group className="">
                              <label>Task Status</label>
                              <Form.Control
                                required
                                name="select"
                                as="select"
                                onChange={(e) => { setTaskStatus(e.target.value) }}
                              >

                                <option value="Created And Assigned" selected={taskStatus == "Created And Assigned" ? true : false}>Created And Assigned</option>
                                <option value="Pending" selected={taskStatus == "Pending" ? true : false}>Pending</option>
                                <option value="Completed" selected={taskStatus == "Completed" ? true : false}>Completed</option>

                              </Form.Control>
                            </Form.Group>
                          </Col>





                        </Row>

                        <br></br>
                        {
                          showLoading ? <><ProgressBar animated variant="success" now={loaded} /><br></br></> : ""
                        }

                        <div className="text-right">
                          <Button
                            className="btn-fill pull-right"
                            type="submit"
                            variant="info"
                          >
                            Edit Task
                          </Button>


                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>


              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </div>


    </>
  );
}

export default EditTask;
