import React, { useState, useEffect } from "react";
import { popup } from "utils/popup";
import { BASE_URL } from "apiUrl";
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import moment from "moment";
const MyPaginate = styled(ReactPaginate).attrs({

})`
  margin-bottom: 2rem;
  margin-top:30px;
  display:flex;
  justify-content: center;
  list-style-type: none;
  padding: 0 5rem;
  li a {
    margin: 4px;
    float:left;
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.selected a {
    background-color: #1DC7EA;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table,
  NavDropdown
} from "react-bootstrap";
import Sidebar from "components/Sidebar/Sidebar";
import toast from "../utils/toast";
import sidebarImage from "assets/img/sidebar-3.jpg";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import { Link } from "react-router-dom";
import routes from "routes.js";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

function addAmount() {

  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const [data, setdata] = useState([]);
  const [formErros, setFormErrors] = useState({})
  const mainPanel = React.useRef(null);
  const [submited, setSubmited] = useState(false)
  let history = useHistory();
  let { id } = useParams();
  console.log(id)

  const [price, setPrice] = useState();
  const [depositDetails, setDepositDetails] = useState("");
  const [added, setAdded] = useState(false)

  React.useEffect(() => { }, [submited])
  React.useEffect(() => {
    TransactionHistory();
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);

  function setbalance(e) {

    e.preventDefault();
    if (formValidation()) {

      const token = localStorage.getItem('token')
      const JWTToken = JSON.parse(token)
      const item = {
        "amount": price,
        "deposit_details": depositDetails === "" ? "None" : depositDetails
      }
      const body = JSON.stringify(item)
      console.log(body)
      const url = `${BASE_URL}/api/bank-management/bank/${id}/deposit-amount/`
      fetch(url, {
        method: 'POST',
        headers: {
          "Authorization": "Bearer " + JWTToken,
          'Content-Type': 'application/json',
        },
        body: body
      }).then(response => {

        if (response.status === 200) {
          popup.success("Amount added successfully");
          TransactionHistory();
          setPrice('')
          setDepositDetails('')
          setAdded(!added)


        }
        else if (response.status === 400) {
          popup.success("Amount not added successfully");
        }
        else if (response.status === 401){
          localStorage.clear()
          window.location.href = '/'
        }
      })


    }

  }

  const formValidation = () => {


    if (!price) {
      setFormErrors({
        ...formErros,
        price: {
          error: true,
          message: "Please fill the account no "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else if (price.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        price: {
          error: true,
          message: "Please enter the amount "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else if (isNaN(price)) {
      setFormErrors({
        ...formErros,
        price: {
          error: true,
          message: "Only digits allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else {

      delete formErros.price

    }
    return true

  }

  async function TransactionHistory() {
    let item = { id };
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
    const url = `${BASE_URL}/api/bank-management/bank/${id}/deposit-history/`

    let result = await fetch(url, {
      method: "GET",

      headers: {
        "Authorization": "Bearer " + JWTToken,
        "Content-Type": 'application/json',
        "Accept": 'application/json'
      }
    })
    result = await result.json().then((resp) => {
      console.log(resp)
      setdata(resp)
      setSubmited(!submited)
    });
    console.log(result);

  }


  function editDepositAmount(id) {
    console.log(id)
    history.push(`/editAmount/${id}`);
  }

  function deleteDepositAmount(id) {
    console.log(id)
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
    const url = `${BASE_URL}/api/bank-management/bank/delete-deposit-amount/${id}/`
    fetch(url, {
      method: 'DELETE',
      headers: {
        "Authorization": "Bearer " + JWTToken,
        'Content-Type': 'application/json',
      },
    }).then(response => {

      if (response.status === 200) {
        popup.success("Deposit details deleted successfully");
        TransactionHistory();
        setAdded(!added)
      }
      else if (response.status === 400) {
        popup.success("Deposit detains not deleted successfully");
      }
    })
  }

  const DepositAmount = ({ currentItems }) => {
    const navDropdownTitle = (<i className="fas fa-ellipsis-v"></i>);

    return <>
      {currentItems.map((item) =>
        <tr>
          <td>{item.bank.bank_name}</td>
          <td>{item.bank.account_no}</td>
          <td>{item.bank.branch_name}</td>
          <td>{item.amount}</td>
          <td>{item.deposit_details}</td>
          <td>{item?.deposited_at ? moment(item.deposited_at).format('MMMM DD, YYYY h:mm:ss A') : ''}</td>
          <td>
            <NavDropdown title={navDropdownTitle} id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => { editDepositAmount(item.id) }}>Edit Deposit</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => { deleteDepositAmount(item.id) }}>Delete Deposit</NavDropdown.Item>
            </NavDropdown>
          </td>
        </tr>
      )}
    </>
  }
  const PaginateDepositAmount = ({ itemsPerPage }) => {
    const [currentItems, setCurrentItems] = useState(data);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(data.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % data.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <div class="tableWrap">
          <Table className="table-hover table-striped">
            <thead>
              <tr>
                <th className="border-0">Bank Name</th>
                <th className="border-0">Account no </th>
                <th className="border-0">Bank Branch</th>
                <th className="border-0">Amount</th>
                <th className="border-0">Description</th>
                <th className="border-0">Date/Time</th>
                <th className="border-0">Actions</th>
              </tr>
            </thead>
            <tbody>

              <DepositAmount currentItems={currentItems} />

            </tbody>
          </Table>
        </div>

        <MyPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />

      </>
    );
  }



  return (
    <>
      <div className="wrapper">
        <Sidebar activeLink="/bank" color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Container fluid>
              <Row>
                <Col md="8">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">Deposit Amount</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={(e) => setPrice(e)}>

                        <Row>
                          <Col className="pr-1" md="10">
                            <Form.Group>
                              <label>Deposit Amount</label>
                              <Form.Control
                                className={formErros.price ? "errors" : ""}
                                placeholder="Deposit Amount"
                                type="text"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                              ></Form.Control>
                              <br />
                              {formErros.price ? <p style={{ color: "red" }}>*{formErros.price.message}</p> : ""}
                            </Form.Group>

                            <Form.Group>
                              <label>Description</label>
                              <Form.Control
                                cols="80"

                                placeholder="Here can be your deposit description"
                                rows="4"
                                as="textarea"
                                value={depositDetails}
                                onChange={(e) => setDepositDetails(e.target.value)}
                              ></Form.Control>

                            </Form.Group>
                          </Col>
                        </Row>
                        <br />
                        <Button


                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                          onClick={setbalance}
                        >
                          Add Amount
                        </Button>
                        <div className="clearfix"></div>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>

              </Row>
            </Container>
            <Container fluid>
              <Row>
                <Col md="12">
                  <Card className="strpied-tabled-with-hover">
                    <Card.Header>
                      <Card.Title as="h4">Deposit History</Card.Title>
                    </Card.Header>
                    <Card.Body className="table-full-width table-responsive px-0">
                      <PaginateDepositAmount itemsPerPage={5} />
                    </Card.Body>
                  </Card>
                </Col>

              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </div>


    </>
  );
}

export default addAmount;
