import React from "react";
import sidebarImage from "assets/img/sidebar-3.jpg";
import { Link ,useHistory} from "react-router-dom";
import toast from "../utils/toast";
import { BASE_URL } from "apiUrl";
import { useEffect,useState } from "react";
import axios from "axios";
import { popup } from "utils/popup";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  ProgressBar
} from "react-bootstrap";

function ResetPassword({setShowPasswordReset,userId}) {

  console.log(userId)
    
  const [oldPassword,setOldPassword] = useState("")
  const [password1,setPassword1]=useState("")
  const [password2,setPassword2]=useState("")
  const [formErros,setFormErrors]=useState({})
  const [showLoading,setShowLoading] = useState(false)
  const [loaded,setLoaded] = useState(0)
  const history = useHistory()

  const [currentInput, setCurrentInput] = useState(1);
  const inputRefs = React.useRef([]);
  
   useEffect(()=>{
        window.scrollTo(0, 0)
   },[])

   

   const formValidation = ()=>{
    
    if(oldPassword.length<=0){
        setFormErrors({
          ...formErros,
          oldPassword:{
            error:true,
            message:"Please fill old password"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
    }
    else{
        delete formErros.oldPassword
      }
    
    if(password1.length<=0){
        setFormErrors({
          ...formErros,
          password1:{
            error:true,
            message:"Please fill password"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
    }else if(!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(password1)){
      setFormErrors({
        ...formErros,
        password1:{
          error:true,
          message:" Password must contains minimum eight characters, at least one letter , one number and one special character"
        }
      })
      return false
    }else{
        delete formErros.password1
      }
    

    if(password2.length<=0){
        setFormErrors({
          ...formErros,
          password2:{
            error:true,
            message:"Please fill password"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
    }else if(!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(password2)){
      setFormErrors({
        ...formErros,
        password2:{
          error:true,
          message:" Password must contains minimum eight characters, at least one letter , one number and one special character"
        }
      })
      return false
    }else{
        delete formErros.password2
      }
    

      if(password1!==password2){
        setFormErrors({
          ...formErros,
          password2:{
            error:true,
            message:"Passwords not matched"
          }
        })
        popup.error("Passwords not matched")
        return false
    }else{
        delete formErros.password2
      }
    
    
    return true
    }




  const resetPassword =async (e)=>{
    console.log('hello ')
   
    if(formValidation()){
    
    let form_data = new FormData();
    form_data.append('old_password',oldPassword)
    form_data.append('password',password1)
    form_data.append('password2',password2)
    
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
    const url =`${BASE_URL}/api/user/change-password/${userId}/`
    
    const config = {
       
      headers:{
          "Content-Type": "multipart/form-data",
          "Authorization" : "Bearer "+JWTToken
      },
      
  }
  try{

      const response = await axios.put(url,form_data,config)
      console.log(response.status)
      if(response.status===200){
        popup.success("Password reset successfully"); 
        setShowPasswordReset(false)                                 
      }
      
      else if (response.status === 401){
        //popup.error("Password not reset successfully")
          localStorage.clear()
          window.location.href = '/'
      }

  }
  catch(err){
    console.log(err)
    console.log(err.response.data)
    if ('old_password' in err.response.data){
        popup.error("Old password is not correct")
    }
    else if('password' in err.response.data ){
        popup.error("Password fields didn't match")
    }
    else {
        popup.error(JSON.stringify(err.response.data))
    } 
  }
    }
  }

    

     const handleKeyDown = (event, index) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      let nextIndex = index + 1;
      if (nextIndex < inputRefs.current.length) {
        try{
          inputRefs.current[nextIndex].focus();
        }catch{
          nextIndex = nextIndex+1;
          inputRefs.current[nextIndex].focus();
        }
        setCurrentInput(nextIndex);
        return 
      }
      // here submit the form
      console.log('Submiting.....');
      resetPassword(event);
      return 
    }else if (event.key === 'Backspace') {
      const currentValue = event.target.value;
      if (currentValue === '' && index > 0) {
        let prevIndex = index - 1;
        try{
          inputRefs.current[prevIndex].focus();
        }catch{
          prevIndex = prevIndex -1;
          inputRefs.current[prevIndex].focus();
        }
        setCurrentInput(prevIndex);
      }
     return 
    }
  };

  
  return (
    <>


          <Col md="8" className="order-md-1">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Resset Your Password</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>


                <Row>
                  <Col  md="12">
                      <Form.Group>
                        <label htmlFor="exampleInputEmail1">
                          Enter Old Password
                        </label>
                        <Form.Control
                          ref={(el) => (inputRefs.current[0] = el)}
                          className={formErros.oldPassword?"errors":""}
                          defaultValue={oldPassword}
                          placeholder ="********"
                          type="password"
                          name="oldPassword"
                          onChange={(e)=>setOldPassword(e.target.value)}
                          onKeyDown={(event) => handleKeyDown(event, 0)}
                        ></Form.Control>
                        {formErros.oldPassword?<p style={{color:"red"}}>*{formErros.oldPassword.message}</p>:""}
                        
                      </Form.Group>
                    </Col>
                  </Row>

                  
                <Row>
                  <Col  md="12">
                      <Form.Group>
                        <label htmlFor="exampleInputEmail1">
                          Enter New Password
                        </label>
                        <Form.Control
                          ref={(el) => (inputRefs.current[1] = el)}
                          className={formErros.password1?"errors":""}
                          defaultValue={password1}
                          placeholder ="********"
                          type="password"
                          name="password1"
                          onChange={(e)=>setPassword1(e.target.value)}
                          onKeyDown={(event) => handleKeyDown(event, 1)}
                        ></Form.Control>
                        {formErros.password1?<p style={{color:"red"}}>*{formErros.password1.message}</p>:""}
                        
                      </Form.Group>
                    </Col>
                  </Row>


                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label>Re-enter Your Password</label>
                        <Form.Control
                          ref={(el) => (inputRefs.current[2] = el)}
                          className={formErros.password2?"errors":""}
                          defaultValue={password2}
                          placeholder ="********"
                          type="password"
                          name="password2"
                          onChange={(e)=>setPassword2(e.target.value)}
                          onKeyDown={(event) => handleKeyDown(event, 2)}
                        ></Form.Control>
                        {formErros.password2?<p style={{color:"red"}}>*{formErros.password2.message}</p>:""}
                        
                      </Form.Group>
                    </Col>
                  </Row>


                  <br></br>
                  {
                      showLoading?<><ProgressBar animated variant="success" now={loaded}  /><br></br></>:""
                    }
                  <Button
                    className="btn-fill pull-right"
                    type="button"
                    variant="info"
                    onClick={()=>{resetPassword()}}
                  >
                    Reset Password 
                  </Button>
                  <a onClick={(e)=>{
                    e.preventDefault()
                    setShowPasswordReset(false)
                  }} style={{cursor:"pointer",textDecoration:"none"}} className="pull-right mt-2 mr-2">Go Back</a>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          
    </>
  );
}

export default ResetPassword;
