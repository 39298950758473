import React, { useState } from "react";
import { BASE_URL } from "apiUrl";
import { popup } from "utils/popup";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table,
  NavDropdown
} from "react-bootstrap";
import Sidebar from "components/Sidebar/Sidebar";
import toast from "../utils/toast";
import sidebarImage from "assets/img/sidebar-3.jpg";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import { Link } from "react-router-dom";
import routes from "routes.js";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

function editAmount() {

  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const [data, setdata] = useState([]);
  const [formErros, setFormErrors] = useState({})
  const mainPanel = React.useRef(null);
  const [submited, setSubmited] = useState(false)
  let history = useHistory();
  let { id } = useParams();
  console.log(id)

  const [price, setPrice] = useState();
  const [depositDetails, setDepositDetails] = useState("");
  const [added, setAdded] = useState(false)

  React.useEffect(() => { }, [submited])
  React.useEffect(() => {
    getDepositAmountData()
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);



  function submitEditDepositDetails(e) {

    e.preventDefault();
    if (formValidation()) {

      const token = localStorage.getItem('token')
      const JWTToken = JSON.parse(token)
      const item = {
        "amount": price,
        "deposit_details": depositDetails
      }
      const body = JSON.stringify(item)
      console.log(body)
      const url = `${BASE_URL}/api/bank-management/bank/update-deposit-amount/${id}/`
      fetch(url, {
        method: 'PUT',
        headers: {
          "Authorization": "Bearer " + JWTToken,
          'Content-Type': 'application/json',
        },
        body: body
      }).then(response => {

        if (response.status === 200) {
          response.json().then(data => {
            console.log(data)
            popup.success("Deposit details changed successfully");
            history.push(`/addAmount/${data.bank.id}/`)
            setAdded(!added)
          })
        }
        else if (response.status === 400) {
          popup.success("Deposit details not changed successfully");
        }
      })
    }
  }


  function getDepositAmountData() {

    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
    const url = `${BASE_URL}/api/bank-management/bank/get-deposit-amount/${id}/`
    fetch(url, {
      method: 'GET',
      headers: {
        "Authorization": "Bearer " + JWTToken,
        'Content-Type': 'application/json',
      },

    }).then(response => {

      if (response.status === 200) {
        response.json().then(data => {
          console.log(data)
          setPrice(data.amount)
          setDepositDetails(data.deposit_details)
        })
      }
    }).catch(err => { console.log(err) })
  }



  const formValidation = () => {


    if (!price) {
      setFormErrors({
        ...formErros,
        price: {
          error: true,
          message: "Please fill the account no "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }


    else if (isNaN(price)) {
      setFormErrors({
        ...formErros,
        price: {
          error: true,
          message: "Only digits allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else {

      delete formErros.price

    }
    return true

  }




  return (
    <>
      <div className="wrapper">
        <Sidebar color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Container fluid>
              <Row>
                <Col md="8">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">Edit Deposit Amount</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form >

                        <Row>
                          <Col className="pr-1" md="10">
                            <Form.Group>
                              <label>Deposit Amount</label>
                              <Form.Control
                                className={formErros.price ? "errors" : ""}
                                placeholder="Deposit Amount"
                                type="text"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                              ></Form.Control>
                              <br />
                              {formErros.price ? <p style={{ color: "red" }}>*{formErros.price.message}</p> : ""}
                            </Form.Group>

                            <Form.Group>
                              <label>Description</label>
                              <Form.Control
                                cols="80"

                                placeholder="Here can be your deposit description"
                                rows="4"
                                as="textarea"
                                value={depositDetails}
                                onChange={(e) => setDepositDetails(e.target.value)}
                              ></Form.Control>

                            </Form.Group>
                          </Col>
                        </Row>
                        <br />
                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                          onClick={(e) => { submitEditDepositDetails(e) }}
                        >
                          Edit Amount
                        </Button>
                        <div className="clearfix"></div>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>

              </Row>
            </Container>

          </div>
          <Footer />
        </div>
      </div>


    </>
  );
}

export default editAmount;
