import React, { useEffect, useState } from "react";
import { popup } from "utils/popup";
import axios from "axios";
import { BASE_URL } from "apiUrl";
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import moment from "moment";
const MyPaginate = styled(ReactPaginate).attrs({

})`
  margin-bottom: 2rem;
  margin-top:30px;
  display:flex;
  justify-content: center;
  list-style-type: none;
  padding: 0 5rem;
  li a {
    margin: 4px;
    float:left;
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.selected a {
    background-color: #1DC7EA;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table
}
  from "react-bootstrap";
import Sidebar from "components/Sidebar/Sidebar";
import sidebarImage from "assets/img/sidebar-3.jpg";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import { Link } from "react-router-dom";
import toast from "../utils/toast";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import routes from "routes.js";



function recievedMoney() {

  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const mainPanel = React.useRef(null);


  const [banks, setBanks] = useState([])
  const [bank, setBank] = useState(null)
  const [amount, setAmount] = useState(0)
  const [description, setDescription] = useState("")
  const [formErros, setFormErrors] = useState({})
  const [selected, setSelected] = useState("cash")
  const [added, setAdded] = useState(false)
  const [date, setDate] = useState(null)
  const history = useHistory()
  const [recivedMoneyHistory, setRecievedMoneyHistory] = useState([])
  const [currency, setCurrency] = useState("")


  const { id } = useParams()
  const clientId = id

  const [clientData, setClientData] = useState({})



  useEffect(() => {

    getAllBanks()
    getClient()
    getRecivedMoneyHistory()
    getCurrency()



  }, []);

  const getCurrency = () => {

    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/user/profile/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((resp) => {

            setCurrency(resp.currency)

          })
        }
        else if (response.status === 401) {
          localStorage.clear()
          window.location.href = '/'
        }
      })
  }



  const getRecivedMoneyHistory = () => {


    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/client-management/recieved-money/${clientId}/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((resp) => {

            setRecievedMoneyHistory(resp)

          })
        }
        else if (response.status === 400) {
          //alert('not authenticated')
        }
        else if (response.status === 401) {
          localStorage.clear()
          window.location.href = '/'
        }
      })


  }
  const getClient = () => {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/client-management/client/${clientId}/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((resp) => {

            setClientData(resp)

          })
        }
        else if (response.status === 401) {
          //alert('not authenticated')
          localStorage.clear()
          window.location.href = '/'
        }
      })
  }

  const formValidation = () => {
    if (!date) {
      setFormErrors({
        ...formErros,
        date: {
          error: true,
          message: "Please select the date "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }



    else {

      delete formErros.date

    }

    if (!amount) {
      setFormErrors({
        ...formErros,
        amount: {
          error: true,
          message: "Please fill the amount "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else if (amount.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        amount: {
          error: true,
          message: "Please fill the amount "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else if (isNaN(amount)) {
      setFormErrors({
        ...formErros,
        amount: {
          error: true,
          message: "Only digits allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else {

      delete formErros.amount

    }

    return true

  }



  const getAllBanks = () => {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/bank-management/banks/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setBanks(data)

          })
        }
        else if (response.status === 401) {
          //alert('not authenticated')
          localStorage.clear()
          window.location.href = '/'
        }
      })
  }


  const selectedOption = (e) => {
    setSelected(e.target.value)

  }

  const resetStates = () => {
    setAdded(!added)
  }

  const addToRecievedMoney = async (e) => {
    e.preventDefault()
    if (formValidation()) {


      const token = localStorage.getItem('token')
      const JWTToken = JSON.parse(token)

      const body = JSON.stringify({
        bank: bank,
        amount: amount,
        description: description,
        type: selected,
        recieve_from: clientId,
        date: date,

      })
      console.log(body)

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + JWTToken
        }
      }

      const url = `${BASE_URL}/api/client-management/add-recieved-money/`


      try {
        const resp = await axios.post(url, body, config)

        if (resp.status === 201) {

          popup.success("Recieved money added successfully");

          getRecivedMoneyHistory()
          resetStates()
          history.push('/admin/clients')


        }
        else if (resp.status === 400) {
          popup.success("Recieved money not added successfully");
        }

      }
      catch (err) {
        console.log(err)
      }





    }
  }

  const bankSelected = (e) => {
    setBank(e.target.value)

  }

  console.log(clientData)

  const RecievedMoney = ({ currentItems }) => {
    return <>
      {currentItems.map((item) =>
        <tr>
          <td style={{ textTransform: "capitalize" }}>{item.recieve_from.first_name} {item.recieve_from.last_name}</td>
          <td style={{ textTransform: "capitalize" }}>{currency} {item.amount}</td>
          <td style={{ textTransform: "capitalize" }}>{item.type == "bank" ? "Bank (" + item.bank.bank_name + ")" : item.type}</td>
          <td style={{ textTransform: "capitalize" }}>{item.description}</td>
          <td style={{ textTransform: "capitalize" }}>{item?.date ? moment(item.date).format('MMMM DD, YYYY') : ''}</td>
        </tr>
      )}
    </>
  }
  const PaginateRecievedMoney = ({ itemsPerPage }) => {
    const [currentItems, setCurrentItems] = useState(recivedMoneyHistory);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(recivedMoneyHistory.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(recivedMoneyHistory.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % recivedMoneyHistory.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <div class="tableWrap">
          <Table className="table-hover table-striped">
            <thead>
              <tr>
                <th className="border-0">Client Name</th>
                <th className="border-0">Recieved Amount </th>
                <th className="border-0">Cash/Bank</th>
                <th className="border-0">Description</th>
                <th className="border-0">Date/Time</th>
              </tr>
            </thead>
            <tbody>

              <RecievedMoney currentItems={currentItems} />

            </tbody>
          </Table>
        </div>
        <MyPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />

      </>
    );
  }



  return (
    <>
      <div className="wrapper">
        <Sidebar color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Container fluid>
              <Row>

                <Col md="4" className="order-md-2">
                  <Card className="card-user">
                    <div className="card-image">
                      <img
                        alt="..."
                        src={
                          require("assets/img/photo-1431578500526-4d9613015464.jpeg")
                            .default
                        }
                      ></img>
                    </div>
                    <Card.Body>
                      <div className="author">
                        <img
                          alt="..."
                          className="avatar border-gray"
                          src={clientData ? BASE_URL + clientData.photo : ""}
                        ></img>




                        <p style={{ textTransform: "capitalize" }} className="title">Name : {clientData.first_name} {clientData.last_name}</p>

                        <p className="title">Email : {clientData.email} </p>

                        <p className="title">Client ID : {clientData.cnic} </p>

                        <p style={{ textTransform: "capitalize" }} className="title">Domain of Business : {clientData.domain} </p>

                      </div>

                    </Card.Body>


                  </Card>
                </Col>





                <Col md="8" className="order-md-1">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">Recieved Money</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={(e) => addToRecievedMoney(e)}>

                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <label>Date</label>

                              <Form.Control

                                className={formErros.date ? "errors" : ""}
                                placeholder="Date"
                                type="date"
                                name="date"
                                onChange={(e) => setDate(e.target.value)}
                              >

                              </Form.Control>
                              {formErros.date ? <p style={{ color: "red" }}>*{formErros.date.message}</p> : ""}

                            </Form.Group>
                          </Col>
                        </Row>



                        <Row>
                          <Col md="12">
                            <Form.Group className="">
                              <label>Select Option</label>
                              <Form.Control

                                required
                                name="select"
                                as="select"
                                onChange={(e) => { selectedOption(e) }}
                              >
                                <option value="bank" >Bank</option>
                                <option value="cash" selected>Cash</option>

                              </Form.Control>
                            </Form.Group>
                          </Col>





                        </Row>
                        <Row>


                          {
                            selected == "bank" ? <Col md="12">
                              <Form.Group className="">
                                <label>Bank Account</label>
                                <Form.Control
                                  required
                                  name="bank"
                                  as="select"
                                  onChange={(e) => { bankSelected(e) }}

                                >
                                  <option value="" selected>Select Bank</option>

                                  {

                                    banks.map(bank => {
                                      return <option value={bank.id} key={bank.id}>{bank.bank_name} {bank.branch_name} Acc No ({bank.account_no})</option>
                                    })
                                  }

                                </Form.Control>
                              </Form.Group>
                            </Col> : ""
                          }


                        </Row>
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <label>Amount</label>

                              <Form.Control
                                className={formErros.amount ? "errors" : ""}

                                placeholder="Amount"
                                type="text"
                                name="amount"
                                onChange={(e) => setAmount(e.target.value)}
                              >

                              </Form.Control>
                              {formErros.amount ? <p style={{ color: "red" }}>*{formErros.amount.message}</p> : ""}

                            </Form.Group>
                          </Col>
                        </Row>







                        <Col md="12">
                          <Form.Group>
                            <label>Description</label>
                            <Form.Control
                              cols="80"

                              placeholder="Here can be your description"
                              rows="4"
                              as="textarea"
                              name="description"
                              onChange={(e) => setDescription(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Row>


                        </Row>

                        <br />
                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                        >
                          Recieved Money
                        </Button>
                        <div className="clearfix"></div>

                      </Form>
                    </Card.Body>
                  </Card>
                </Col>


              </Row>
            </Container>
            <Container fluid>
              <Row>
                <Col md="12">
                  <Card className="strpied-tabled-with-hover">
                    <Card.Header>
                      <Card.Title as="h4">Recieved Money History</Card.Title>
                    </Card.Header>
                    <Card.Body className="table-full-width table-responsive px-0">
                      <PaginateRecievedMoney itemsPerPage={5} />
                    </Card.Body>
                  </Card>
                </Col>

              </Row>
            </Container>

          </div>
          <Footer />
        </div>
      </div>


    </>
  );
}

export default recievedMoney;
