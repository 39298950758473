import React, { useEffect, useState } from "react";
import { popup } from "utils/popup";
import toast from "../utils/toast";
import { BASE_URL } from "apiUrl";
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import SweetAlert from 'react-bootstrap-sweetalert';
import ProjectManagementNavbar from "./projectManagementNavbar";
const MyPaginate = styled(ReactPaginate).attrs({
  
})`
  margin-bottom: 2rem;
  margin-top:30px;
  display:flex;
  justify-content: center;
  list-style-type: none;
  padding: 0 5rem;
  li a {
    margin: 4px;
    float:left;
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.selected a {
    background-color: #1DC7EA;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  NavDropdown,
  Form,
} from "react-bootstrap";
import { useHistory,Link } from "react-router-dom";
import EditBankBallanceModel from "./EditBankBallanceModel";

function Tasks() {

  const [data,setData]=useState([])
  const [allData,setAllData]=useState([])
  const [deleted,setDeleted]=useState(false)
  const [currency,setCurrency]=useState("")
  const [pressDelete,setPressDelete]=useState(false)
  const [id,setId] = useState(null)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [editBallanceItem,setEditBallanceItem] = useState(null)
  const [update,setUpdate] = useState(false)
  const history = useHistory()
  

  

  
  useEffect(()=>{
   
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
   
    fetch(`${BASE_URL}/api/project-management/tasks/`,{
      headers:{
          "Authorization" : "Bearer "+JWTToken
      }
      })
    .then((response) =>{
       if(response.status===200){
        response.json().then((data)=>{
          setData(data)
          setAllData(data)
          console.log(data)
        })
       }
       else if(response.status===401){
         //alert('not authenticated')
         localStorage.clear()
          window.location.href = '/'
       }
       })

      
  },[deleted,update])



  function editTask(id)
  {
    console.log(id);
    history.push(`/editTask/${id}`);
  }
 

  function deleteTask(id)
  {
        const token = localStorage.getItem('token')
        const JWTToken = JSON.parse(token)
        let item=id
        let url = `${BASE_URL}/api/project-management/task/${item}/`
        fetch(url,{
            method:"DELETE",
            headers:{
               
                "Authorization" : "Bearer "+JWTToken
            }
        }).then(response=>{
          if(response.status===200){
            
            popup.success("Task deleted successfully");
            setDeleted(!deleted)
          }
          else if(response.status===400){
            popup.success("Task not deleted successfully");
          }
          else if(response.status===401){
            localStorage.clear()
            window.location.href = '/'
          }
        })
        
  }

  console.log(data);
  
  const Project = ({currentItems})=>{
    const navDropdownTitle = (<i className="fas fa-ellipsis-v"></i>);
   
    return <>
      {currentItems.map((item)=>
                <tr>
                  
                  <td>{item.title}</td>
                  <td>{item.assign_to.first_name} {item.assign_to.last_name}</td>
                  <td>{item.status}</td>
                  <td>{item.start_date}</td>
                  <td>{item.end_date}</td>
                  
                  <NavDropdown title={navDropdownTitle} id="basic-nav-dropdown">
                  
                    <NavDropdown.Item onClick={()=>editTask(item.id)}>Edit</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={()=>{setPressDelete(true);setId(item.id)}}>Delete</NavDropdown.Item>
                    <NavDropdown.Divider/>
                    
                    </NavDropdown>
                  </tr>
                  )}
    </>
  }
  const PaginateTasks = ({ itemsPerPage })=> {
    const [currentItems, setCurrentItems] = useState(data);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
  
    useEffect(() => {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(data.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);
  
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % data.length;
      setItemOffset(newOffset);
    };
  
    return (
      <>
        <div class="tableWrap">
        <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      
                      <th className="border-0">Title</th>
                      <th className="border-0">Assign To</th>
                      <th className="border-0">Status</th>
                      <th className="border-0">Start Date</th>
                      <th className="border-0">Expected End Date</th>
                      <th className="border-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                  
                    <Project currentItems={currentItems}/>
    
                  </tbody>
                </Table>
                </div>
        <MyPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      
      </>
    );
  }

  const searchTask = (e)=>{
    console.log(e.target.value)
    let query = e.target.value.trim()
    if(query!==""){
      let matchedTask = data.filter((task)=>task.title.toUpperCase().includes(query.toUpperCase()) )
      console.log(matchedTask)
      if(matchedTask.length>0){
        setData(matchedTask)
      }else {
        setData(allData)
      }
    }else{setData(allData)}
  }
  return (
    <>

{
      pressDelete?
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={()=>{deleteTask(id);setPressDelete(false);setId(null)}}
        onCancel={()=>{setPressDelete(false);setId(null)}}
        focusCancelBtn
      >
        You will not be able to recover task data!
      </SweetAlert>:""
}
      <Container fluid>
        <ProjectManagementNavbar/>
        <Row>
        <EditBankBallanceModel show={show} update={update} handleClose={handleClose} selectedBank={editBallanceItem} setUpdate={setUpdate}/>
                  
          <Col md="12 mt-3">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">List of Tasks</Card.Title>
                <div className="text-right">
                        
                  <Link to="/addTask">
                    <Button className="btn btn-primary">Add Task</Button>
                  </Link>
                 
                </div>
                <br/>
                <div>
                <Form.Control
                          defaultValue=""
                          placeholder="Search Task By Title...🔍"
                          type="text"
                          onChange={(e)=>{searchTask(e)}}
                        >
                        
                </Form.Control>

                </div>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
               <PaginateTasks itemsPerPage={15} />
              </Card.Body>
            </Card>
          </Col>
    
        </Row>
      </Container>
   
    </>
  );
}

export default Tasks;
