
import EmployeeProfile from "empViews/EmployeeProfile";

const empDashboardRoutes = [
  
  
  {
    path: "/profile",
    name: "User Profile",
    icon: "nc-icon nc-circle-09",
    component: EmployeeProfile,
    layout: "/employee",
  },
    
];

export default empDashboardRoutes;
