import React, { useEffect, useState } from "react";
import SweetAlert from 'react-bootstrap-sweetalert';
import toast from "../utils/toast";
import moment from "moment";
import { popup } from "utils/popup";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  NavDropdown,
  Modal
} from "react-bootstrap";
import { useHistory,Link } from "react-router-dom";
import { BASE_URL } from "apiUrl";
import axios from "axios";
function Meetings() {

  const [data,setData]=useState([]);
  const [deleted,setDeleted]=useState(false)
  const [currency,setCurrency]=useState("")
  const [credentialsExist,setCredentialsExist] = useState(false)
  const [aclientID,setAClientID] = useState(null)
  const [aapiKey,setAApiKey] = useState(null)
  const [pressDelete,setPressDelete]=useState(false)
  const [id,setId] = useState(null)
  const history = useHistory();
  
  var gapi = window.gapi
  var CLIENT_ID = aclientID
  var API_KEY = aapiKey
  var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
  var SCOPES = "https://www.googleapis.com/auth/calendar.events"
  
  

  const getGoogleCallenderAPICredentials = ()=>{

    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
   
    fetch(`${BASE_URL}/api/meeting-management/get-google-callender-api-credentials/`,{
      headers:{
          "Authorization" : "Bearer "+JWTToken
      }
      })
    .then((response) =>{
       if(response.status===200){
        response.json().then((resp)=>{
           console.log(resp)
           setCredentialsExist(resp.credentials_exists)
           setAApiKey(resp.data.api_key)
           setAClientID(resp.data.client_id)
           if(resp.credentials_exists){
            localStorage.setItem('apiKey',resp.data.api_key)
            localStorage.setItem('clientID',resp.data.client_id)
           }
        })
       }
       else if(response.status===400){
         console.log(400)
       }
       })
}


  useEffect(()=>{
    getGoogleCallenderAPICredentials()
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
   
    fetch(`${BASE_URL}/api/meeting-management/meeting-events/`,{
      headers:{
          "Authorization" : "Bearer "+JWTToken
      }
      })
    .then((response) =>{
       if(response.status===200){
        response.json().then((data)=>{
          setData(data)
         
        })
       }
       else if(response.status===401){
         //alert('not authenticated')
         localStorage.clear()
          window.location.href = '/'
       }
       })

      
  },[deleted])



  

  function deleteMeetingEvent(id)
  {


    gapi.load('client:auth2', () => {
      console.log('loaded client')

      gapi.client.init({
        apiKey: aapiKey,
        clientId: aclientID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })

      gapi.client.load('calendar', 'v3', () => console.log('bam!'))

      gapi.auth2.getAuthInstance().signIn()
      .then(() => {
        
        
        var request = gapi.client.calendar.events.delete({
          'calendarId': 'primary',
          'eventId': id
        })
        request.execute(event => {
          console.log("Delete request ",event)


          const token = localStorage.getItem('token')
        const JWTToken = JSON.parse(token)
        let item=id
        let url = `${BASE_URL}/api/meeting-management/meeting-event/${item}/`
        fetch(url,{
            method:"DELETE",
            headers:{
               
                "Authorization" : "Bearer "+JWTToken
            }
        }).then(response=>{
          if(response.status===200){
            
            popup.success("Meeting deleted successfully");
            setDeleted(!deleted)
          }
          else if(response.status===400){
            popup.success("Meeting not deleted successfully");
          }
        })
        
        
        });

       
        

      })
    })
    


        
  }

  console.log(data);
  const navDropdownTitle = (<i className="fas fa-ellipsis-v"></i>);

  


  const InstructModel = ({deleted,setDeleted})=>{
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [agree,setAgree] = useState(false)
    const [clientID,setClientID] = useState(null)
    const [apiKey,setApiKey] = useState(null)
    const [formErros,setFormErrors]=useState({})
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const formValidation = ()=>{

        
      //apiKey
      if((!apiKey)||apiKey.trim().length<=0){
        setFormErrors({
          ...formErros,
          apiKey:{
            error:true,
            message:"Please fill api Key"
          }
        })
        popup.error("Form not submited! Please fill all required fields")
        return false
      }
      else if(!isNaN(apiKey)){
        setFormErrors({
          ...formErros,
          apiKey:{
            error:true,
            message:"Please paste valid api Key"
          }
        })
        popup.error("Form not submited! Please fill all required fields")
        return false
      }
  
      else{
              
        delete formErros.apiKey
        
      }
      
  
      //clientID
      if((!clientID)||clientID.trim().length<=0){
        setFormErrors({
          ...formErros,
          clientID:{
            error:true,
            message:"Please fill client ID"
          }
        })
        popup.error("Form not submited! Please fill all required fields")
        return false
      }
      else if(!isNaN(clientID)){
        setFormErrors({
          ...formErros,
          clientID:{
            error:true,
            message:"Please paste valid client ID"
          }
        })
        popup.error("Form not submited! Please fill all required fields")
        return false
      }
  
      else{
              
        delete formErros.clientID
        
      }
      return true
  
  
    }

    const addGoogleCalenderCredentials = async ()=>{
         if(formValidation()&&agree){
            const token = localStorage.getItem('token')
            const JWTToken = JSON.parse(token)
            
            const body = JSON.stringify({
                api_key : apiKey.trim(),
                client_id : clientID.trim(),
            })

            const config = {
                headers:{
                    "Content-Type": "application/json",
                    "Authorization" : "Bearer "+JWTToken
                }
            }

            const url = `${BASE_URL}/api/meeting-management/add-google-callender-api-credentials/`


            try{
                const resp = await axios.post(url,body,config)
                console.log(resp)
                if(resp.status===201){
                    
                    popup.success("Credentials created successfully");
                    window.location.reload();
                    
                   }
                   else if (resp.status===400){
                    popup.success("Credentials not created successfully");
                   }
            }
            catch(err){
                console.log(err)
            }
    }
  }

    return (
      <>
        <Button variant="primary" onClick={handleShow}>
          Schedule Meeting
        </Button>
  
        <Modal  size="lg" animation={false}  show={show} onHide={handleClose} >
          
          <Modal.Body style={{"height":"400px",overflowY:"scroll"}}>

          <h3>You must first have a <a href="https://console.developers.google.com/" target="_blank"> Google Calendar API</a> Credentials:</h3>
          <ol>
            <li>
            Go to the Google Developer Console and create a new project (it might take a second).

            </li>
            <li>
            Once its done, go to API Library on the sidebar.

            </li>
            <li>
            Find “Google Calendar API” click on it ane ENABLE it.

            </li>
            <li>
            On the sidebar,  &gt; Credentials.

            </li>
            <li>
            Create Credentials &gt; Create API Key.

            </li>
            <li>
            Note down you API Key.

            </li>
            <li>
            On the sidebar,  &gt; OAuth consent screen.

            </li>
            <li>
            User Type &gt; External.

            </li>
            <li>
            Click on Create.

            </li>
            <li>
             App Name &gt; "office-management-system" .

            </li>
            <li>
             Next select your email as support email.

            </li>
            <li>
              In app logo upload your logo.
            </li>
            <li>
              In application home page field put "https://officehassle.com/" .
            </li>
            <li>In application privacy policy link put "https://officehassle.com/".</li>
            <li>In authorized domain put "officehassle.com".</li>
            <li>In developer contact information put your email.</li>
            <li>Save and continue.</li>
            <li>In Scope screen &gt; Save and continue.</li>
            <li>In Test users click on +ADD USER.</li>
            <li>Put your own email here.</li>
            <li>Save and continue.</li>
            <li>In Summary screen click on Back To dashboard.</li>
            <li>On the sidebar,  &gt; Credentials.</li>
            <li>
            Create Credentials &gt; OAuth client ID.

            </li>
            <li>
              Application type select "Web Application" .
            </li>
            <li>
              Name "Office-management-system" .
            </li>
            <li>In Authorized javascript origin add "https://officehassle.com" .</li>
            <li>
              In Authorized redirect URIs add "https://officehassle.com/scheduleMeeting/" .
            </li>
            <li>
              Click on Create.
            </li>
            <li>Note down your client ID.</li>
            <li>Paste client ID and API Key bellow.</li>
            <strong>Note:</strong>When you click on Add Meeting Event in Dashboard while creating meeting event a screen will appear  please chosse the same gmail account here for creating the meeting.
            </ol>
          <input type="text" onChange={(e)=>{setApiKey(e.target.value)}} className="form-control md-12" placeholder="Paste your API Key here." required/>
          {formErros.apiKey?<p style={{color:"red"}}>*{formErros.apiKey.message}</p>:""}
                        
          <br></br>
          <input type="text" onChange={(e)=>{setClientID(e.target.value)}} className="form-control md-12" placeholder="Paste your client ID here." required/>
          {formErros.clientID?<p style={{color:"red"}}>*{formErros.clientID.message}</p>:""}
          
          </Modal.Body>
          <Modal.Footer>
            <label>
              <input className="ml-3" type="checkbox" onClick={(e)=>{
                if(e.target.checked){
                  setAgree(true)
                  console.log(true)
                }
                else{
                  setAgree(false)
                  console.log(false)
                }
              }}/>
              <span className="ml-1">I follow all the instruction carefully and past the accurate credentials.</span>
            </label>
            <Button variant="primary" onClick={()=>addGoogleCalenderCredentials()} >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  
  


  return (
    <>

  {
      pressDelete?
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={()=>{deleteMeetingEvent(id);setPressDelete(false);setId(null)}}
        onCancel={()=>{setPressDelete(false);setId(null)}}
        focusCancelBtn
      >
        You will not be able to recover meeting data!
      </SweetAlert>:""
  }
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">List of Meetings</Card.Title>
                <div className="text-right">
                  {
                    credentialsExist && aapiKey  && aclientID ? 
                    
                    <Link to="/scheduleMeeting/"><Button className="btn btn-primary">Schedule Meeting</Button></Link>
                    :

                    < InstructModel/>
                  }
                  
                </div>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
              <div class="tableWrap">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">Title</th>
                      <th className="border-0">Description</th>
                     
                      <th className="border-0">Start Time</th>
                      <th className="border-0">End Time</th>
                      <th className="border-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                  {data.map((item)=>
                  <tr>
                  <td>{item.title}</td>
                  <td>{item.description}</td>
                 
                  <td>{item?.start_time ? moment(item.start_time.split('Z')[0]).format('MMMM DD, YYYY h:mm:ss A') : ''}</td>
                  <td>{item?.end_time ? moment(item.end_time.split('Z')[0]).format('MMMM DD, YYYY h:mm:ss A') : ''}</td>
                  <NavDropdown title={navDropdownTitle}  id="basic-nav-dropdown">
                  
                    {/* <NavDropdown.Item onClick={()=>editGCMeeting(item.id)}>Edit</NavDropdown.Item>
                    <NavDropdown.Divider /> */}
                    <NavDropdown.Item onClick={()=>{setPressDelete(true);setId(item.id)}}>Delete</NavDropdown.Item>
                    {/* <NavDropdown.Divider/> */}
                    {/* <NavDropdown.Item onClick={()=>meetingsParticipants(item.id)}>Participants</NavDropdown.Item> */}
                  </NavDropdown>
                  </tr>
                  )}
                
    
                  </tbody>
                </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
    
        </Row>
      </Container>
   
    </>
  );
}

export default Meetings;
