import { useState,useEffect } from "react"
import "../assets/css/bank-account-dropdown.css"


const EditEmployeeAccountDropdown = ({employees,setEmployee,selectedEmp})=>{
    console.log('edit employee account')
    const [data,setData]=useState([]);
    const [allData,setAllData] = useState([])
    const [openDropdown,setOpenDropdown] = useState(false)
    const [displayText,setDisplayText] = useState("Select Employee")
    const [totalItems,setTotalItems] = useState(4)

    useEffect(()=>{
      if(employees.length>0){
        setData(employees.slice(0, totalItems))
      }
        setAllData(employees)
        setSelectEmployee()
    },[employees,selectedEmp])

    const setSelectEmployee = ()=>{
        let emp = employees.find(em=>em.id==selectedEmp)
        if(emp){
            let dispTxt = emp.first_name+" "+emp.last_name+" ("+emp.account_no+") "+ emp.branch_name
            setDisplayText(dispTxt)
        }
    }

    const empAccountSelect = (e)=>{
        let emp = JSON.parse(e.target.value)
        let dispTxt = emp.first_name+" "+emp.last_name+" ("+emp.account_no+") "+ emp.branch_name
        setDisplayText(dispTxt)
        setEmployee(emp.id)
        setOpenDropdown(!openDropdown)
      
    }

    
    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  
    const onScroll = async (e) => {
      var maxScrollPosition = e.target.scrollHeight - e.target.clientHeight
      const currentScroll = e.target.scrollTop
      if(currentScroll===maxScrollPosition){
          let items = totalItems+4
          await sleep(1 * 1000)
          setData(allData.slice(0, items))
          setTotalItems(items)
      }
      
    };

      


      const searchEmpBankAccount = (e)=>{
        console.log(e.target.value)
        let query = e.target.value.trim()
        if(query!==""){
          let matched = allData.filter((emp)=>emp.first_name.toUpperCase()+" "+emp.last_name.toUpperCase()===query.toUpperCase()||emp.account_no===query||emp.first_name.toUpperCase()===query.toUpperCase()||emp.last_name.toUpperCase()===query.toUpperCase())
          console.log(matched)
          if(matched.length>0){
            setData(matched)
          }else {
            setData(allData.slice(0, 4))
          }
        }else{

        }
      }
    
    return <>
    <div className="select_box_wrapper ">
  <div className="custom_select_box" onClick={()=>{setOpenDropdown(!openDropdown)}}>
    <div className="custom_select_box_selected_label">
      <label className="custom_select_box_selected_label_text" style={{cursor:"pointer"}}>{displayText}</label>
    </div>
    <div className="custom_select_box_dropdown_icon">
      <a className="custom_select_box_dropdown_icon_button"><i className="fas fa-angle-down"></i></a>
    </div>
  </div>
  {
    openDropdown?<div onScroll={onScroll} className="custom_select_dropdown">
    <ul className="custom_select_dropdown_list_wrapper">
      <li className="custom_select_dropdown_list_search">
          <input  className="custom_select_dropdown_list_search_input" placeholder="Search Employee" 
          onChange={(e)=>{searchEmpBankAccount(e)}}
          type="text" />
      </li>
  
      {data.map((item)=>{
                    
                    return <li key={item.id} className="custom_select_dropdown_list">
                      <input className="custom_select_dropdown_list_radio" type="radio" value={JSON.stringify(item)} onChange={(e)=>{empAccountSelect(e)}} /><span style={{textTransform:"capitalize!important"}} className="custom_select_dropdown_list_label">{item.first_name} {item.last_name} {item.bank_name} ({item.account_no}) {item.branch_name}</span>
                    </li>}
      )}   
      </ul>
    </div>:null
  }
</div>

    </>
}
export default EditEmployeeAccountDropdown