import React, { useEffect, useState } from "react";
import axios from "axios";
import { popup } from "utils/popup";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table,
}
  from "react-bootstrap";
import Sidebar from "components/Sidebar/Sidebar";
import sidebarImage from "assets/img/sidebar-3.jpg";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import { Link } from "react-router-dom";
import toast from "../utils/toast";
import routes from "routes.js";
import { BASE_URL } from "apiUrl";
import { useHistory } from "react-router-dom";

function PaySalaries() {

  const history = useHistory()
  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const mainPanel = React.useRef(null);

  const search = window.location.search ? window.location.search : "?month=None&year=None"
  const [monthValue, yearValue] = search.slice(1).split("&");
  const month = monthValue.split("=")[1];
  const year = yearValue.split("=")[1];

  const [previousPaidSalaries, setPreviousPaidSalaries] = useState([])
  const [focusedInput, setFocusedInput] = useState(null)
  const [showLoading, setShowLoading] = useState(false)

  console.log(search)

  React.useEffect(() => {
    console.log('hello')
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, []);

  useEffect(() => {
    getLastPaidSalaries()
  }, [])






  const getLastPaidSalaries = () => {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/salary-management/pay-salaries`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            let salaries = data.previous_paid_salaries
            salaries = salaries.map(salary => { return { ...salary, selected: true, send_slip: false } })
            setPreviousPaidSalaries(salaries)
            console.log(salaries)

          })
        }
        else if (response.status === 401) {
          //alert('not authenticated')
          localStorage.clear()
          window.location.href = '/'
        }
      })
  }



  const paySelectedSalaries = async () => {
    let selectedSalaries = previousPaidSalaries.filter(salary => salary.selected === true)
    console.log(selectedSalaries)
    if (selectedSalaries.length > 0) {

      const token = localStorage.getItem('token')
      const JWTToken = JSON.parse(token)
      const body = JSON.stringify({
        salaries: selectedSalaries
      })

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + JWTToken
        },
      }

      const url = `${BASE_URL}/api/salary-management/pay-salaries`


      try {
        setShowLoading(true)
        const resp = await axios.post(url, body, config)
        console.log(resp)
        if (resp.status === 200) {
          console.log(resp.data.employees_salaries_paid_succcess)
          popup.success(`${resp.data.employees_salaries_paid_succcess.join(',')} Salaries paid successfully`);
          const path = `/admin/salary`
          history.push(path)
        }
        else if (resp.status === 400) {
          popup.success("Salary not successfully");
        }
        else if(resp.status === 401){
          localStorage.clear()
          window.location.href = '/'
        }
      }
      catch (err) {
        console.log(err)
      }
      finally {
        setShowLoading(false)
      }
    }
  }



  return (
    <>
      <div className="wrapper">
        <Sidebar activeLink="/salary" color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Container fluid>
              <Row>
                <Form>
                  <Col md="12">
                    <Card className="strpied-tabled-with-hover">
                      <Card.Header>
                        <Card.Title as="h4">Salaries Payments {month !== "None" && year !== "None" ? `For The Month ${month} ${year}` : ""} </Card.Title>
                        <div className="text-center" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "100%" }}>

                        </div>
                        <div>
                          <br />


                        </div>

                      </Card.Header>
                      <Card.Body className="table-full-width table-responsive px-0">

                        <Table className="table-hover table-striped" style={{ "width": "940px", "marginBottom": "1rem", "color": "#212529", "overflowX": "scroll", "display": "block", "whiteSpace": "nowrap" }}>
                          <thead>
                            <tr>

                              <th className="border-0">Select</th>
                              <th className="border-0">Employee</th>
                              <th className="border-0">Employee Account</th>
                              <th className="border-0">Bank Account</th>
                              <th className="border-0">Salary Amount</th>
                              <th className="border-0">Bonus Amount</th>
                              <th className="border-0">Allowance</th>
                              <th className="border-0">Tax Deduction</th>
                              <th className="border-0">Transaction Charges</th>
                              <th className="border-0">Slip Send</th>

                            </tr>
                          </thead>
                          <tbody>


                            {
                              previousPaidSalaries?.map(salary => {
                                return <tr key={Math.random() * 1000}>

                                  <td>
                                    <Form.Group>

                                      <input className="mt-2 ml-3"
                                        type="checkbox"
                                        checked={salary.selected}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setFocusedInput(null)
                                            setPreviousPaidSalaries(previousPaidSalaries.map((sal) => salary.id === sal.id ? { ...sal, selected: true } : { ...sal }))
                                          } else {
                                            setFocusedInput(null)
                                            setPreviousPaidSalaries(previousPaidSalaries.map((sal) => salary.id === sal.id ? { ...sal, selected: false } : { ...sal }))
                                          }
                                        }}

                                      />

                                    </Form.Group>
                                  </td>
                                  <td>{salary.paid_to__first_name} {salary.paid_to__last_name}</td>
                                  <td>{salary.bank_name} ({salary.account_no}) </td>
                                  <td>{salary.sender_bank_details__bank_name} ({salary.sender_bank_details__account_no}) </td>
                                  <td>
                                    <Form.Group>


                                      <Form.Control

                                        name={"salary_amount_" + salary.id}
                                        autoFocus={focusedInput === "salary_amount_" + salary.id}
                                        defaultValue={salary.salary_amount}
                                        type="text"
                                        onChange={(e) => {
                                          setFocusedInput(e.target.name)
                                          setPreviousPaidSalaries(previousPaidSalaries.map((sal) => salary.id === sal.id ? { ...sal, salary_amount: e.target.value } : { ...sal }))
                                        }}
                                      >

                                      </Form.Control>

                                    </Form.Group>
                                  </td>
                                  <td>
                                    <Form.Group>

                                      <Form.Control
                                        name={"bonus_" + salary.id}
                                        autoFocus={focusedInput === "bonus_" + salary.id}
                                        defaultValue={salary.bonus}
                                        type="text"
                                        onChange={(e) => {
                                          setFocusedInput(e.target.name)
                                          setPreviousPaidSalaries(previousPaidSalaries.map((sal) => salary.id === sal.id ? { ...sal, bonus: e.target.value } : { ...sal }))
                                        }}
                                      >

                                      </Form.Control>

                                    </Form.Group>
                                  </td>
                                  <td>
                                    <Form.Group>

                                      <Form.Control
                                        name={"allowance_" + salary.id}
                                        autoFocus={focusedInput === "allowance_" + salary.id}
                                        defaultValue={salary.allowance}
                                        type="text"
                                        onChange={(e) => {
                                          setFocusedInput(e.target.name)
                                          setPreviousPaidSalaries(previousPaidSalaries.map((sal) => salary.id === sal.id ? { ...sal, allowance: e.target.value } : { ...sal }))
                                        }}

                                      >

                                      </Form.Control>

                                    </Form.Group>
                                  </td>
                                  <td>
                                    <Form.Group>

                                      <Form.Control
                                        name={"tax_deduction_" + salary.id}
                                        autoFocus={focusedInput === "tax_deduction_" + salary.id}
                                        defaultValue={salary.tax_deduction}
                                        type="text"
                                        onChange={(e) => {
                                          setFocusedInput(e.target.name)
                                          setPreviousPaidSalaries(previousPaidSalaries.map((sal) => salary.id === sal.id ? { ...sal, tax_deduction: e.target.value } : { ...sal }))
                                        }}
                                      >

                                      </Form.Control>

                                    </Form.Group>
                                  </td>
                                  <td>
                                    <Form.Group>

                                      <Form.Control
                                        name={"transaction_charges_" + salary.id}
                                        autoFocus={focusedInput === "transaction_charges_" + salary.id}
                                        defaultValue={salary.transaction_charges}
                                        type="text"
                                        onChange={(e) => {
                                          setFocusedInput(e.target.name)
                                          setPreviousPaidSalaries(previousPaidSalaries.map((sal) => salary.id === sal.id ? { ...sal, transaction_charges: e.target.value } : { ...sal }))
                                        }}
                                      >

                                      </Form.Control>

                                    </Form.Group>
                                  </td>

                                  <td>
                                    <Form.Group>

                                      <input className="mt-2 ml-3"
                                        type="checkbox"
                                        name="send_salary_slip"
                                        checked={salary.send_slip}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setFocusedInput(null)
                                            setPreviousPaidSalaries(previousPaidSalaries.map((sal) => salary.id === sal.id ? { ...sal, send_slip: true } : { ...sal }))
                                          } else {
                                            setFocusedInput(null)
                                            setPreviousPaidSalaries(previousPaidSalaries.map((sal) => salary.id === sal.id ? { ...sal, send_slip: false } : { ...sal }))
                                          }
                                        }}
                                      />

                                    </Form.Group>

                                  </td>

                                </tr>

                              })
                            }
                          </tbody>

                        </Table>

                        <div className="text-center" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "100%" }}>
                          {
                            showLoading ? <div style={{ marginRight: "25px" }} className="spinner-border text-success" role="status">
                              <span className="sr-only">Loading...</span>
                            </div> : <Link to="#" onClick={(e) => {
                              e.preventDefault()
                              paySelectedSalaries()
                            }} className="mr-3"><Button style={{ width: "100%" }} className="btn btn-success  ">Pay Selected Salaries</Button>
                            </Link>

                          }


                        </div>

                      </Card.Body>
                    </Card>

                  </Col>
                </Form>
              </Row>


            </Container>
          </div>
          <Footer />
        </div>

      </div>


    </>
  );
}

export default PaySalaries
