import Swal  from 'sweetalert2'

const successPopup = (message)=>{
    return Swal.fire({
        position: 'center',
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 1500
    })
}

const errorPopup = (message)=>{
    return Swal.fire({
        position: 'center',
        icon: 'error',
        title: message,
        showConfirmButton: false,
        timer: 1500
    })
}

export const popup = {
    "success" : successPopup,
    "error" : errorPopup

}