import { popup } from "utils/popup";
import axios from "axios";
import { useEffect, useState } from "react";
import toast from "../utils/toast";
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    NavDropdown,
    Modal,
    Form,
    Spinner 
  } from "react-bootstrap";
  
import currentDateTime from "utils/currentDateTime";
import { BASE_URL } from "apiUrl";

const SendInvoiceModal = ({show,handleClose,handleShow, invoiceId})=>{
   
    const [customEmail, setCustomEmail] = useState('')
    const [emailType, setEmailType] = useState('client')
    const [formErros, setFormErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(()=>{
      console.log('invoice id==================', invoiceId)
    },[show, invoiceId])


    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }


    const formValidation = () => {

        if(emailType==="client"){
            return true
        }

        if (!customEmail) {
          setFormErrors({
            ...formErros,
            customEmail: {
              error: true,
              message: "Please fill the email"
            }
          })
          popup.error("Form not submited ! Please fill all required fields")
          return false
        } else if (!validateEmail(customEmail)) {
          setFormErrors({
            ...formErros,
            customEmail: {
              error: true,
              message: "Please enter a valid email"
            }
          })
          popup.error("Form not submited ! Please fill all required fields")
          return false
        } else {
    
          delete formErros.customEmail
    
        }
    
        return true
    
    }
    const sendInvoiceSlip = async (e) => {
        e.preventDefault()
        if(formValidation()){
            if (formValidation()) {
                setIsLoading(true)
                const token = localStorage.getItem('token')
                const JWTToken = JSON.parse(token)
                const body = JSON.stringify({
                  invoice_id : invoiceId,
                  custom_email : customEmail,
                })
                console.log(body)
          
                const config = {
                  headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + JWTToken
                  }
                }
          
                const url = `${BASE_URL}/api/invoice-management/send-email-invoice-slip/`
          
                try {
                  const resp = await axios.post(url, body, config)
                  console.log(resp)
                  if (resp.status === 200) {
                    popup.success("Invoice slip sends successfully");
                    handleClose();
                  }
                  else if (resp.status === 400) {
                    popup.error("Invoice slip not sends successfully");
                  }
                  else if(resp.status === 401){
                    localStorage.clear()
                    window.location.href = '/'
                  }
          
                }
                catch (err) {
                  console.log(err);
                  if (err && err.message) {
                    popup.error(err.message); 
                  } else {
                    popup.error("An error occurred."); 
                  }
                }
                setIsLoading(false)
              }
        }
      }

      
    return (
      <>
       
  
        <Modal   size="lg" animation={false}  show={show} onHide={handleClose} >
        <h4 className="narbar-brand text-center mt-5">Send Invoice Slip</h4>
          <Modal.Body style={{"height":"300px"}}>
                
                <div className="row justify-content-center">
                    
                        <Col md="7">
                            <Form.Group className="">
                              <label>Send Slip To </label>
                             
                              <div className="ml-3">
                                <Form.Check
                                  inline
                                  label="Client Email"
                                  value='client'
                                  name="emailType"
                                  type={'radio'}
                                  onClick={(e)=>{setEmailType(e.target.value); setCustomEmail('');}}
                                  checked={emailType==='client'}
                                />
                                <Form.Check
                                  inline
                                  label="Custom Email"
                                  value='custom'
                                  name="emailType"
                                  type={'radio'}
                                  onClick={(e)=>{setEmailType(e.target.value)}}
                                  checked={emailType==='custom'}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                        
                    {
                        emailType==='custom' ? <Col sm="7" >
                        <Form.Group>
                          <label>Custom Email </label>
                          <Form.Control
                          defaultValue={customEmail}
                          className={formErros.customEmail ? "errors" : ""}
                            type="email"
                            placeholder="Type custom email here..."
                            onChange={(e)=>setCustomEmail(e.target.value)}
                            required={true}
                          ></Form.Control>
                         {formErros.customEmail ? <p style={{ color: "red" }}>*{formErros.customEmail.message}</p> : ""}
                        </Form.Group>
                      </Col> : <></>
                    }
                    
                </div>
                <div className="row justify-content-center mt-3">
                {
                  isLoading ? <Spinner animation="border" role="status">
                  <span className="visually-hidden"></span>
                </Spinner> : <Button
                  onClick = {(e)=> sendInvoiceSlip(e)}
                  className="btn-fill "
                  type="button"
                  variant="info"
                  >
                  <i className="fas fa-paper-plane mr-2"></i>
                  Send Invoice Slip
                </Button>
                }
                
                  
                </div>
          </Modal.Body>
          <Modal.Footer>
         </Modal.Footer>
        </Modal>
      </>
    );
  }
  
export default SendInvoiceModal