import React from "react";
import toast from "../utils/toast";
import { popup } from "utils/popup";
import { BASE_URL } from "apiUrl";
import { useEffect, useState } from "react";
import axios from "axios";
import ResetPassword from "./ResetPassword";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  ProgressBar
} from "react-bootstrap";
import CountryList from "./CountryList";
import MobileNumberField from "./MobileNumberField";

function User() {
  const [profileData, setProfileData] = useState({})
  const [updatedData, setUpdatedData] = useState({})
  const [changeImage, setChangeImage] = useState(null)
  const [updatedImage, setUpdatedImage] = useState(null)
  const [formErros, setFormErrors] = useState({})
  const [showLoading, setShowLoading] = useState(false)
  const [showPasswordReset, setShowPasswordReset] = useState(false)
  const [loaded, setLoaded] = useState(0)

  const [mobileNumber1, setMobileNumber1] = useState('')
  const [mobileNumber2, setMobileNumber2] = useState('')
  const [showOtherNumber, setShowOtherNumber] = useState(false)

  const [site1, setSite1] = useState('')
  const [site2, setSite2] = useState('')
  const [showOtherSiet, setShowOtherSite] = useState(false)


  const [email1, setEmail1] = useState('')
  const [email2, setEmail2] = useState('')
  const [showOtherEmail, setShowOtherEmail] = useState(false)
  
  const [companyAddress, setCompanyAddress] = useState('')

  const [currentInput, setCurrentInput] = useState(1);
  const inputRefs = React.useRef([]);

  const changeProfileData = (e) => {

    if (e.target.name === "photo") {
      setUpdatedData({
        ...updatedData,
        [e.target.name]: e.target.files[0]
      })
      setProfileData({
        ...profileData,
        [e.target.name]: e.target.files[0]
      })
    }

    else {
      setUpdatedData({
        ...updatedData,
        [e.target.name]: e.target.value
      })

      setProfileData({
        ...profileData,
        [e.target.name]: e.target.value
      })
    }



  }




  const formValidation = () => {

    //validate username


    if (!profileData.username) {
      setFormErrors({
        ...formErros,
        username: {
          error: true,
          message: "Please fill the username"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }



    else if (!isNaN(profileData.username)) {
      setFormErrors({
        ...formErros,
        username: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else {

      delete formErros.username

    }




    //validating for email


    if (!profileData.email) {
      setFormErrors({
        ...formErros,
        email: {
          error: true,
          message: "Please fill the email"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    } else if (!validateEmail(profileData.email)) {
      setFormErrors({
        ...formErros,
        email: {
          error: true,
          message: "Please enter a valid email"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    } else {

      delete formErros.email

    }

    //validate first name


    if (!profileData.first_name) {
      setFormErrors({
        ...formErros,
        first_name: {
          error: true,
          message: "Please fill the first name"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }


    else if (profileData.first_name.trim().length < 3) {
      setFormErrors({
        ...formErros,
        first_name: {
          error: true,
          message: "First name must be contain upto 3 characters"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else if (!isNaN(profileData.first_name)) {
      setFormErrors({
        ...formErros,
        first_name: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else {

      delete formErros.first_name

    }
    console.log(formErros)

    //last name validation
    if (!profileData.last_name) {
      setFormErrors({
        ...formErros,
        last_name: {
          error: true,
          message: "Please fill the last name"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    } else if (profileData.last_name.trim().length < 3) {
      setFormErrors({
        ...formErros,
        last_name: {
          error: true,
          message: "Last name must be contain upto 3 characters"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else if (!isNaN(profileData.last_name)) {
      setFormErrors({
        ...formErros,
        last_name: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else {

      delete formErros.last_name

    }

    //validation for mobile number 

    if (!profileData.phone_no) {
      setFormErrors({
        ...formErros,
        phone_no: {
          error: true,
          message: "Please fill the mobile number"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(profileData.phone_no)) {
      setFormErrors({
        ...formErros,
        phone_no: {
          error: true,
          message: "Please enter a valid mobile number e.g. +923001234567"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    } else {

      delete formErros.phone_no

    }





    //validate address
    if ((!profileData.address) || profileData.address.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        address: {
          error: true,
          message: "Please fill address"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else if (!isNaN(profileData.address)) {
      setFormErrors({
        ...formErros,
        address: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }

    else {

      delete formErros.address

    }
    //validate city 
    if ((!profileData.city) || profileData.city.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        city: {
          error: true,
          message: "Please fill city"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }

    else if (!isNaN(profileData.city)) {
      setFormErrors({
        ...formErros,
        city: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }

    else {

      delete formErros.city

    }
    //validate the country 


    if ((!profileData.country) || profileData.country.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        country: {
          error: true,
          message: "Please fill country"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }

    else if (!isNaN(profileData.country)) {
      setFormErrors({
        ...formErros,
        country: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }

    else {

      delete formErros.country

    }

    //validate postal code 

    if (!profileData.postal_code) {
      setFormErrors({
        ...formErros,
        postal_code: {
          error: true,
          message: "Please fill the postal code "
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else if (profileData.postal_code.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        postal_code: {
          error: true,
          message: "Please fill the postal code "
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }

    else if (isNaN(profileData.postal_code)) {
      setFormErrors({
        ...formErros,
        postal_code: {
          error: true,
          message: "Only digits allowed"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else {

      delete formErros.postal_code

    }

    //validate the about 

    if ((!profileData.about) || profileData.about.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        about: {
          error: true,
          message: "Please fill about"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else if (!isNaN(profileData.about)) {
      setFormErrors({
        ...formErros,
        about: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else {

      delete formErros.about

    }


    //validation for company mobile number 1 

    if (!mobileNumber1) {
      setFormErrors({
        ...formErros,
        mobileNumber1: {
          error: true,
          message: "Please fill the mobile number"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(mobileNumber1)) {
      setFormErrors({
        ...formErros,
        mobileNumber1: {
          error: true,
          message: "Please enter a valid mobile number e.g. +923001234567"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    } else {

      delete formErros.mobileNumber1

    }



    //validation for company mobile number 2 optional

    if (mobileNumber2) {
      if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(mobileNumber2)) {
        setFormErrors({
          ...formErros,
          mobileNumber2: {
            error: true,
            message: "Please enter a valid mobile number e.g. +923001234567"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      } else {
  
        delete formErros.mobileNumber2
  
      }
    }else if(formErros?.mobileNumber2){
      delete formErros.mobileNumber2
    }


     //validation for company email 1 
     const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

     if (!email1) {
       setFormErrors({
         ...formErros,
         email1: {
           error: true,
           message: "Please fill the email"
         }
       })
       popup.error("Form not submited ! Please fill all required fields")
       return false
     } else if (!emailRegex.test(email1)) {
       setFormErrors({
         ...formErros,
         email1: {
           error: true,
           message: "Please enter a valid email"
         }
       })
       popup.error("Form not submited ! Please fill all required fields")
       return false
     } else {
 
       delete formErros.email1
 
     }
 
 
 
     //validation for company email 2 optional
 
     if (email2) {
       if (!emailRegex.test(email2)) {
         setFormErrors({
           ...formErros,
           email2: {
             error: true,
             message: "Please enter a valid email"
           }
         })
         popup.error("Form not submited ! Please fill all required fields")
         return false
       } else {
   
         delete formErros.email2
   
       }
     }else if(formErros?.email2){
       delete formErros.email2
     }
     
    

    //validation for company site 1 
    const siteRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(\/[^\s]*)?$/;

    if (!site1) {
      setFormErrors({
        ...formErros,
        site1: {
          error: true,
          message: "Please fill the site"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    } else if (!siteRegex.test(site1)) {
      setFormErrors({
        ...formErros,
        site1: {
          error: true,
          message: "Please enter a valid site url"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    } else {

      delete formErros.site1

    }



    //validation for company site 2 optional

    if (site2) {
      if (!siteRegex.test(site2)) {
        setFormErrors({
          ...formErros,
          site2: {
            error: true,
            message: "Please enter a valid site url"
          }
        })
        popup.error("Form not submited ! Please fill all required fields")
        return false
      } else {
  
        delete formErros.site2
  
      }
    }else if(formErros?.site2){
      delete formErros.site2
    }
    
     //validate company address

     if ((!companyAddress) || companyAddress.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        companyAddress: {
          error: true,
          message: "Please fill company address"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else if (!isNaN(companyAddress)) {
      setFormErrors({
        ...formErros,
        companyAddress: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }

    else {

      delete formErros.companyAddress

    }

    return true
  }


  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }



  const updateProfile = async (e) => {
    e.preventDefault()


    if (formValidation()) {
      setShowLoading(true)
      let form_data = new FormData();
      form_data.append('first_name', profileData.first_name)
      form_data.append('last_name', profileData.last_name)
      form_data.append('address', profileData.address)
      form_data.append('username', profileData.username)
      form_data.append('city', profileData.city)
      form_data.append('country', profileData.country)
      form_data.append('email', profileData.email)
      form_data.append('phone_no', profileData.phone_no)
      form_data.append('postal_code', profileData.postal_code)
      form_data.append('about', profileData.about)
      form_data.append('mobile_numbers', JSON.stringify([mobileNumber1.trim(), mobileNumber2.trim()]))
      form_data.append('sites', JSON.stringify([site1.trim(), site2.trim()]))
      form_data.append('emails', JSON.stringify([email1.trim(), email2.trim()]))
      form_data.append('company_address', companyAddress)

      if (updatedImage) {
        form_data.append('photo', updatedImage, updatedImage.name)
      }



      console.log(updatedData)


      const token = localStorage.getItem('token')
      const JWTToken = JSON.parse(token)
      const url = `${BASE_URL}/api/user/profile/`



      const config = {

        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": "Bearer " + JWTToken
        },
        onUploadProgress: (p) => {
          // console.log(Math.ceil((p.loaded/p.total)*100))
          // setLoaded(Math.ceil((p.loaded/p.total)*100))
          setLoaded(p.loaded)
        }
      }
      try {

        const response = await axios.put(url, form_data, config)
        

        if (response.status === 200) {

          popup.success("Profile updated successfully");
          setProfileData(response.data)



        }
        else if (response.status === 400) {


          popup.error("Ensure CNIC field has no more than 13 characters")

        }

        else if (response.status === 401) {
          localStorage.clear()
          window.location.href = '/'
        }
        setShowLoading(false)
        setLoaded(0)
      }
      catch (err) {
        Object.keys(err.response.data).forEach((key) => {
          popup.error(`${err.response.data[key]}`);
        });
        setShowLoading(false)
        setLoaded(0)
      }

    }



  }

  useEffect(() => {


    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/user/profile/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((resp) => {
            setProfileData(resp)
            setMobileNumber1(resp.company_contacts.mobile_numbers.split(',')[0])
            setMobileNumber2(resp.company_contacts.mobile_numbers.split(',')[1])
            if(resp.company_contacts.mobile_numbers.split(',')[1]){
              setShowOtherNumber(true)
            }
            setEmail1(resp.company_contacts.emails.split(',')[0])
            setEmail2(resp.company_contacts.emails.split(',')[1])
            if(resp.company_contacts.emails.split(',')[1]){
              setShowOtherEmail(true)
            }
            setSite1(resp.company_contacts.sites.split(',')[0])
            setSite2(resp.company_contacts.sites.split(',')[1])
            if(resp.company_contacts.sites.split(',')[1]){
              setShowOtherSite(true)
            }
            setCompanyAddress(resp.company_contacts.address)
          })
        }
        else if (response.status === 401) {
          //alert('not authenticated')
          localStorage.clear()
          window.location.href = '/'
        }
      })
  }, [])


  const addImage = (e) => {

    setUpdatedImage(e.target.files[0])
    setChangeImage(URL.createObjectURL(e.target.files[0]))

  }




  console.log(profileData)


   const handleKeyDown = (event, index) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      let nextIndex = index + 1;
      if (nextIndex < inputRefs.current.length) {
        try{
          inputRefs.current[nextIndex].focus();
        }catch{
          nextIndex = nextIndex+1;
          inputRefs.current[nextIndex].focus();
        }
        setCurrentInput(nextIndex);
        return 
      }
      // here submit the form
      console.log('Submiting.....');
      updateProfile(event);
      return 
    }else if (event.key === 'Backspace') {
      const currentValue = event.target.value;
      if (currentValue === '' && index > 0) {
        let prevIndex = index - 1;
        try{
          inputRefs.current[prevIndex].focus();
        }catch{
          prevIndex = prevIndex -1;
          inputRefs.current[prevIndex].focus();
        }
        setCurrentInput(prevIndex);
      }
     return 
    }
  };

  return (
    <>
      <Container fluid >
        <Row>
          <Col md="4" className="order-md-2">
            <Card className="card-user">
              <div className="card-image">
                <img
                  alt="..."
                  src={
                    require("assets/img/photo-1431578500526-4d9613015464.jpeg")
                      .default
                  }

                ></img>
              </div>
              <Card.Body>
                <div className="author" >
                  <img
                    alt="..."
                    className="avatar border-gray"
                    src={changeImage ? changeImage : BASE_URL + profileData.photo}

                    onError={({ currentTarget }) => {
                      console.log('on error')
                      currentTarget.onerror = null;
                      currentTarget.src = require("assets/img/profile.png").default
                    }}
                  />



                  <Form.Group>
                    {
                      showPasswordReset ? null : <>
                        <label htmlFor="file-upload" className="custom-file-upload">
                          <i style={{ fontWeight: 900, fontSize: "15px" }} className="fas fa-pencil-alt"></i>
                        </label>
                        <input name="image" accept="image/*" id="file-upload" type="file" onChange={(e) => { addImage(e) }} />

                      </>
                    }
                  </Form.Group>


                  <h5 style={{ textTransform: "capitalize" }} className="title"><b>{profileData.first_name} {profileData.last_name}</b></h5>
                  {
                    showPasswordReset ? null : <>
                      <p style={{ fontSize: "12px" }}>*Change your profile image from here.</p>

                    </>
                  }
                </div>


              </Card.Body>




            </Card>





          </Col>

          {
            showPasswordReset ? <ResetPassword setShowPasswordReset={setShowPasswordReset} userId={profileData.id} />
              :
              <Col md="8" className="order-md-1">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Edit Profile</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Form>
                      <Row>
                        <Col md="4">
                          <Form.Group>
                            <label>Company (disabled)</label>
                            <Form.Control
                              defaultValue={profileData.company_name}
                              disabled
                              placeholder="Company"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group>
                            <label>Username</label>
                            <Form.Control
                              ref={(el) => (inputRefs.current[0] = el)}
                              className={formErros.username ? "errors" : ""}
                              defaultValue={profileData.username}
                              placeholder="Username"
                              type="text"
                              name="username"
                              onChange={(e) => changeProfileData(e)}
                              onKeyDown={(event) => handleKeyDown(event, 0)}
                            ></Form.Control>
                            {formErros.username ? <p style={{ color: "red" }}>*{formErros.username.message}</p> : ""}

                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group>
                            <label htmlFor="exampleInputEmail1">
                              Email address
                            </label>
                            <Form.Control
                              ref={(el) => (inputRefs.current[1] = el)}
                              className={formErros.email ? "errors" : ""}
                              defaultValue={profileData.email}
                              placeholder="example@mail.com"
                              type="email"
                              name="email"
                              onChange={(e) => changeProfileData(e)}
                              onKeyDown={(event) => handleKeyDown(event, 1)}
                            ></Form.Control>
                            {formErros.email ? <p style={{ color: "red" }}>*{formErros.email.message}</p> : ""}

                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <Form.Group>
                            <label>First Name</label>
                            <Form.Control
                              ref={(el) => (inputRefs.current[2] = el)}
                              className={formErros.first_name ? "errors" : ""}
                              defaultValue={profileData.first_name}
                              placeholder="Company"
                              type="text"
                              name="first_name"
                              onChange={(e) => changeProfileData(e)}
                              onKeyDown={(event) => handleKeyDown(event, 2)}
                            ></Form.Control>
                            {formErros.first_name ? <p style={{ color: "red" }}>*{formErros.first_name.message}</p> : ""}

                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group>
                            <label>Last Name</label>
                            <Form.Control
                              ref={(el) => (inputRefs.current[3] = el)}
                              className={formErros.last_name ? "errors" : ""}
                              defaultValue={profileData.last_name}
                              placeholder="Last Name"
                              type="text"
                              name="last_name"
                              onChange={(e) => changeProfileData(e)}
                              onKeyDown={(event) => handleKeyDown(event, 3)}
                            ></Form.Control>
                            {formErros.last_name ? <p style={{ color: "red" }}>*{formErros.last_name.message}</p> : ""}

                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group>
                            <label>Mobile No</label>
                            <MobileNumberField 
                              refr={(el) => (inputRefs.current[4] = el)}
                              mobileNumber={profileData.phone_no} 
                              name={'phone_no'} 
                              setMobileNumber={changeProfileData}
                              onkeydown={(event) => handleKeyDown(event, 4)}
                            />
                            {formErros.phone_no ? <p style={{ color: "red" }}>*{formErros.phone_no.message}</p> : ""}

                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="3">
                          <Form.Group>
                            <label>Address</label>
                            <Form.Control
                              ref={(el) => (inputRefs.current[5] = el)}
                              className={formErros.address ? "errors" : ""}
                              defaultValue={profileData.address}
                              placeholder="Home Address"
                              type="text"
                              name="address"
                              onChange={(e) => changeProfileData(e)}
                              onKeyDown={(event) => handleKeyDown(event, 5)}
                            ></Form.Control>
                            {formErros.address ? <p style={{ color: "red" }}>*{formErros.address.message}</p> : ""}

                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <Form.Group>
                            <label>City</label>
                            <Form.Control
                              ref={(el) => (inputRefs.current[6] = el)}
                              className={formErros.city ? "errors" : ""}
                              defaultValue={profileData.city}
                              placeholder="City"
                              type="text"
                              name="city"
                              onChange={(e) => changeProfileData(e)}
                              onKeyDown={(event) => handleKeyDown(event, 6)}
                            ></Form.Control>
                            {formErros.city ? <p style={{ color: "red" }}>*{formErros.city.message}</p> : ""}

                          </Form.Group>
                        </Col>
                     
                      <Col md="3">
                          <Form.Group>
                            <label>Country</label>
                            <select 
                                ref={(el) => (inputRefs.current[7] = el)}
                                className={formErros.country ? "errors form-control" : "form-control"}
                                value={profileData.country}
                                name="country"
                                onChange={(e) => changeProfileData(e)}
                                onKeyDown={(event) => handleKeyDown(event, 7)}
                              >
                                <CountryList/>
                              </select>
                            
                            {formErros.country ? <p style={{ color: "red" }}>*{formErros.country.message}</p> : ""}

                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <Form.Group>
                            <label>Postal Code</label>
                            <Form.Control
                              ref={(el) => (inputRefs.current[8] = el)}
                              className={formErros.postal_code ? "errors" : ""}
                              defaultValue={profileData.postal_code}
                              placeholder="ZIP Code"
                              type="number"
                              name="postal_code"
                              onChange={(e) => changeProfileData(e)}
                              onKeyDown={(event) => handleKeyDown(event, 8)}
                            ></Form.Control>
                            {formErros.postal_code ? <p style={{ color: "red" }}>*{formErros.postal_code.message}</p> : ""}

                          </Form.Group>
                        </Col>
                     
                        
                      </Row>
                      <Row>
                        <Col md="12">
                          <Form.Group>
                            <label>About Me</label>
                            <Form.Control
                              ref={(el) => (inputRefs.current[9] = el)}
                              className={formErros.about ? "errors" : ""}
                              cols="80"
                              defaultValue={profileData.about}
                              placeholder="Description"
                              rows="2"
                              as="textarea"
                              name="about"
                              onChange={(e) => changeProfileData(e)}
                              onKeyDown={(event) => handleKeyDown(event, 9)}
                            ></Form.Control>
                            {formErros.about ? <p style={{ color: "red" }}>*{formErros.about.message}</p> : ""}

                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Card.Header>
                          <Card.Title as="h4">Company Contacts</Card.Title>
                        </Card.Header>
                      </Row>
                      <Row>
                        <Col md="3">
                          <Form.Group>
                              <label>Mobile Numbers</label>
                              <Form.Control
                                  ref={(el) => (inputRefs.current[10] = el)}
                                  className={formErros.mobileNumber1 ? "errors" : ""}
                                  defaultValue={mobileNumber1}
                                  placeholder={"Mobile Number"}
                                  type="text"
                                  value={mobileNumber1}
                                  name="mobileNumber1"
                                  onChange={(e) => {setMobileNumber1(e.target.value);}}
                                  onKeyDown={(event) => handleKeyDown(event, 10)}
                              >
                              </Form.Control>
                              {formErros.mobileNumber1 ? <p style={{ color: "red" }}>*{formErros.mobileNumber1.message}</p> : ""}

                          </Form.Group>
                        </Col>
                        {
                          showOtherNumber ? <Col md="3">
                          <Form.Group>
                              <label>&nbsp;</label>
                              <Form.Control
                                  className={formErros.mobileNumber2 ? "errors" : ""}
                                  defaultValue={mobileNumber2}
                                  placeholder={"Other Mobile Number"}
                                  type="text"
                                  value={mobileNumber2}
                                  name="mobileNumber2"
                                  onChange={(e) => {setMobileNumber2(e.target.value);}}
                              >
                              </Form.Control>
                              {formErros.mobileNumber2 ? <p style={{ color: "red" }}>*{formErros.mobileNumber2.message}</p> : ""}

                              </Form.Group>
                          </Col> : <Col md="3">
                            <button onClick={()=>{setShowOtherNumber(true)}} type="button" className="btn-fill pull-left btn btn-info mt-4"><i className="fas fa-plus"></i></button>
                          </Col>
                        }


                        <Col md="3">
                          <Form.Group>
                              <label>Emails</label>
                              <Form.Control
                                  ref={(el) => (inputRefs.current[11] = el)}
                                  className={formErros.email1 ? "errors" : ""}
                                  defaultValue={email1}
                                  placeholder={"Email"}
                                  type="text"
                                  value={email1}
                                  name="email1"
                                  onChange={(e) => {setEmail1(e.target.value);}}
                                  onKeyDown={(event) => handleKeyDown(event, 11)}
                              >
                              </Form.Control>
                              {formErros.email1 ? <p style={{ color: "red" }}>*{formErros.email1.message}</p> : ""}

                          </Form.Group>
                        </Col>
                        {
                          showOtherEmail ? <Col md="3">
                          <Form.Group>
                              <label>&nbsp;</label>
                              <Form.Control
                                  className={formErros.email2 ? "errors" : ""}
                                  defaultValue={email2}
                                  placeholder={"Other Email"}
                                  type="text"
                                  value={email2}
                                  name="email2"
                                  onChange={(e) => {setEmail2(e.target.value);}}
                              >
                              </Form.Control>
                              {formErros.email2 ? <p style={{ color: "red" }}>*{formErros.email2.message}</p> : ""}

                              </Form.Group>
                          </Col> : <Col md="3">
                            <button onClick={()=>{setShowOtherEmail(true)}} type="button" className="btn-fill pull-left btn btn-info mt-4"><i className="fas fa-plus"></i></button>
                          </Col>
                        }

                    </Row>
                      
                    <Row>
                        <Col md="3">
                          <Form.Group>
                              <label>Sites</label>
                              <Form.Control
                                  ref={(el) => (inputRefs.current[12] = el)}
                                  className={formErros.site1 ? "errors" : ""}
                                  defaultValue={site1}
                                  placeholder={"Site"}
                                  type="text"
                                  value={site1}
                                  name="site1"
                                  onChange={(e) => {setSite1(e.target.value);}}
                                  onKeyDown={(event) => handleKeyDown(event, 12)}
                              >
                              </Form.Control>
                              {formErros.site1 ? <p style={{ color: "red" }}>*{formErros.site1.message}</p> : ""}

                          </Form.Group>
                        </Col>
                        {
                          showOtherSiet ? <Col md="3">
                          <Form.Group>
                              <label>&nbsp;</label>
                              <Form.Control
                                  className={formErros.site2 ? "errors" : ""}
                                  defaultValue={site2}
                                  placeholder={"Other Site"}
                                  type="text"
                                  value={site2}
                                  name="site2"
                                  onChange={(e) => {setSite2(e.target.value);}}
                              >
                              </Form.Control>
                              {formErros.site2 ? <p style={{ color: "red" }}>*{formErros.site2.message}</p> : ""}

                              </Form.Group>
                          </Col> : <Col md="3">
                            <button onClick={()=>{setShowOtherSite(true)}} type="button" className="btn-fill pull-left btn btn-info mt-4"><i className="fas fa-plus"></i></button>
                          </Col>
                        }
                    </Row>


                    
                      <Row>
                        <Col md="12">
                          <Form.Group>
                            <label>Address</label>
                            <Form.Control
                              ref={(el) => (inputRefs.current[13] = el)}
                              className={formErros.companyAddress ? "errors" : ""}
                              defaultValue={companyAddress}
                              value={companyAddress}
                              cols="80"
                              placeholder="Company Address"
                              rows="2"
                              as="textarea"
                              name="companyAddress"
                              onChange={(e) => {setCompanyAddress(e.target.value)}}
                              onKeyDown={(event) => handleKeyDown(event, 13)}
                            >
                            </Form.Control>
                            {formErros.companyAddress ? <p style={{ color: "red" }}>*{formErros.companyAddress.message}</p> : ""}

                          </Form.Group>
                        </Col>

                      </Row>

                      <br></br>
                      {
                        showLoading ? <><ProgressBar animated variant="success" now={loaded} /><br></br></> : ""
                      }

                      <Button
                        className="btn-fill pull-right"
                        type="submit"
                        variant="info"
                        onClick={(e) => { window.scrollTo(0, 0); updateProfile(e); }}
                      >
                        Update Profile
                      </Button>
                      <a onClick={(e) => {
                        e.preventDefault()
                        setShowPasswordReset(true)
                      }} style={{ cursor: "pointer", textDecoration: "none" }} className="pull-right mt-2 mr-2">Reset Your Password?</a>
                      <div className="clearfix"></div>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>

          }

        </Row>
      </Container>
    </>
  );
}

export default User;
