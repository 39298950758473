import React, { useEffect, useState } from "react";
import { popup } from "utils/popup";
import toast from "../utils/toast";
import { BASE_URL } from "apiUrl";
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import SweetAlert from 'react-bootstrap-sweetalert';
const MyPaginate = styled(ReactPaginate).attrs({
  
})`
  margin-bottom: 2rem;
  margin-top:30px;
  display:flex;
  justify-content: center;
  list-style-type: none;
  padding: 0 5rem;
  li a {
    margin: 4px;
    float:left;
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.selected a {
    background-color: #1DC7EA;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  NavDropdown,
  Form,
} from "react-bootstrap";
import { useHistory,Link } from "react-router-dom";
import EditBankBallanceModel from "./EditBankBallanceModel";

function Account() {

  const [data,setData]=useState([])
  const [allData,setAllData]=useState([])
  const [deleted,setDeleted]=useState(false)
  const [currency,setCurrency]=useState("")
  const [pressDelete,setPressDelete]=useState(false)
  const [id,setId] = useState(null)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [editBallanceItem,setEditBallanceItem] = useState(null)
  const [update,setUpdate] = useState(false)
  const history = useHistory()
  

  

  const getCurrency = ()=>{

    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
   
    fetch(`${BASE_URL}/api/user/profile/`,{
      headers:{
          "Authorization" : "Bearer "+JWTToken
      }
      })
    .then((response) =>{
       if(response.status===200){
        response.json().then((resp)=>{
            
            setCurrency(resp.currency)
          
        })
       }
       else if(response.status===401){
        localStorage.clear()
        window.location.href = '/'
       }
       })
}


  useEffect(()=>{
   
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
   
    fetch(`${BASE_URL}/api/bank-management/banks/`,{
      headers:{
          "Authorization" : "Bearer "+JWTToken
      }
      })
    .then((response) =>{
       if(response.status===200){
        response.json().then((data)=>{
          setData(data)
          setAllData(data)
          console.log(setData)
        })
       }
       else if(response.status===401){
         localStorage.clear()
        window.location.href = '/'
       }
       })

       getCurrency()
  },[deleted,update])



  function selectUser(id)
  {
    console.log(id);
    history.push(`/editbank/${id}`);
  }
  function addamount(id)
  {
    console.log(id);
    history.push(`/addAmount/${id}`);
  }
  function gotoTransactionHistory(id){
    console.log(id);
    history.push(`/bankTransactionHistory/${id}`);
  }

  function deletebank(id)
  {
        const token = localStorage.getItem('token')
        const JWTToken = JSON.parse(token)
        let item=id
        let url = `${BASE_URL}/api/bank-management/bank/${item}/`
        fetch(url,{
            method:"DELETE",
            headers:{
               
                "Authorization" : "Bearer "+JWTToken
            }
        }).then(response=>{
          if(response.status===200){
            
            popup.success("Bank deleted successfully");
            setDeleted(!deleted)
          }
          else if(response.status===400){
            popup.success("Bank not deleted successfully");
          }
        })
        
  }

  console.log(data);
  
  const Bank = ({currentItems})=>{
    const navDropdownTitle = (<i className="fas fa-ellipsis-v"></i>);
   
    return <>
      {currentItems.map((item)=>
                  <tr>
                  <td>{item.bank_name}</td>
                  <td>{item.account_no}</td>
                  <td>{item.branch_name}</td>
                  <td>{currency} {item.total_ballance}
                    
                  </td>
                  <td>
                  <i onClick={()=>{
                    setEditBallanceItem(item)
                    handleShow()
                    }} style={{color:'#0069D9',marginLeft:"-75px",cursor:"pointer"}} className="fas fa-edit"></i>
                  </td>
                  <NavDropdown title={navDropdownTitle} id="basic-nav-dropdown">
                  
                    <NavDropdown.Item onClick={()=>selectUser(item.id)}>Edit</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={()=>{setPressDelete(true);setId(item.id)}}>Delete</NavDropdown.Item>
                    <NavDropdown.Divider/>
                    <NavDropdown.Item onClick={()=>addamount(item.id)}>Deposit</NavDropdown.Item>
                    <NavDropdown.Item onClick={()=>{gotoTransactionHistory(item.id)}}>Transaction History</NavDropdown.Item>
                  </NavDropdown>
                  </tr>
                  )}
    </>
  }
  const PaginateBanks = ({ itemsPerPage })=> {
    const [currentItems, setCurrentItems] = useState(data);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
  
    useEffect(() => {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(data.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);
  
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % data.length;
      setItemOffset(newOffset);
    };
  
    return (
      <>
        <div class="tableWrap">
        <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">Bank Name</th>
                      <th className="border-0">Account no </th>
                      <th className="border-0">Bank Branch</th>
                      <th className="border-0">Total Balance</th>
                      <th className="border-0"></th>
                      <th className="border-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                  
                    <Bank currentItems={currentItems}/>
    
                  </tbody>
                </Table>
                </div>
        <MyPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      
      </>
    );
  }

  const searchBank = (e)=>{
    console.log(e.target.value)
    let query = e.target.value.trim()
    if(query!==""){
      let matchedBanks = data.filter((bank)=>bank.bank_name.toUpperCase()===query.toUpperCase()||bank.account_no===query)
      console.log(matchedBanks)
      if(matchedBanks.length>0){
        setData(matchedBanks)
      }else {
        setData(allData)
      }
    }
  }
  return (
    <>

{
      pressDelete?
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={()=>{deletebank(id);setPressDelete(false);setId(null)}}
        onCancel={()=>{setPressDelete(false);setId(null)}}
        focusCancelBtn
      >
        You will not be able to recover bank data!
      </SweetAlert>:""
}
      <Container fluid>
        <Row>
        <EditBankBallanceModel show={show} update={update} handleClose={handleClose} selectedBank={editBallanceItem} setUpdate={setUpdate}/>
                  
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">List of Banks</Card.Title>
                <div className="text-right">
                        
                  <Link to="/addBank">
                    <Button className="btn btn-primary">Add Bank</Button>
                  </Link>
                 
                </div>
                <br/>
                <div>
                <Form.Control
                          defaultValue=""
                          placeholder="Search Bank By Name Or Account No...🔍"
                          type="text"
                          onChange={(e)=>{searchBank(e)}}
                        >
                        
                </Form.Control>

                </div>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
               <PaginateBanks itemsPerPage={15} />
              </Card.Body>
            </Card>
          </Col>
    
        </Row>
      </Container>
   
    </>
  );
}

export default Account;
