import React, { useEffect, useState } from "react";
import { loadStripe } from '@stripe/stripe-js';
import toast from "../utils/toast";
import "assets/css/card.css"
// react-bootstrap components
import {
  Alert,
  Badge,
  Button,
  Card,
  Modal,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { BASE_URL } from "apiUrl";

function subscription(props) {
  const colors = {
    "azure": "#1DC7EA",
    "green": "#87CB16",
    "orange": "#FFA534",
    "red": "#FB404B",
    "purple": "#9368E9"
  }
  const [stripe, setStripe] = useState(null)
  const [subscriptionDetails, setSubscriptionDetails] = useState({})
  const [subscriptionPackages, setSubscriptionPackages] = useState([])

  useEffect(() => {
    getStripePublicKey()
    getSubscriptionDetails()
    getSubscriptionPackages()
  }, [])
  const getSubscriptionDetails = () => {


    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/subscriptions/details/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status == 200) {
          response.json().then(data => {
            setSubscriptionDetails(data)

          })
        }
        else if (response.status == 400) {
          response.json().then(data => {
            console.log(data)
          })
        }



      }).catch(err => {

      })

  }
  const getStripePublicKey = () => {

    fetch(`${BASE_URL}/api/subscriptions/config/`).then((result) => {
      result.json().then((data) => {

        loadStripe(data.publicKey).then((stripe) => {
          setStripe(stripe)
        })
      })
    }).catch(err => {
      console.log(err, 'err')
    })
  }

  const getSubscriptionPackages = () => {

    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/subscriptions/subscription-packages/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    }).then((result) => {
      result.json().then((data) => {
        console.log(data)
        setSubscriptionPackages(data)
      })
    }).catch(err => {
      console.log(err, 'err')
    })
  }

  const gotoStripePaymentPage = (e) => {
    if (stripe) {

      const token = localStorage.getItem('token')
      const JWTToken = JSON.parse(token)

      fetch(`${BASE_URL}/api/subscriptions/create-checkout-session/`, {
        headers: {
          "Authorization": "Bearer " + JWTToken
        }
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => {

              return stripe.redirectToCheckout({ sessionId: data.sessionId })

            })
          }
          else if (response.status === 401) {
            localStorage.clear()
          window.location.href = '/'
          }
        })

      //     fetch('').then(result=>{
      //         result.json().then(data=>{
      //             console.log(data)
      //             return stripe.redirectToCheckout({sessionId: data.sessionId})
      //         })
      //     }).catch(err=>{
      //         console.log(err,'error')
      //     })

    }
  }
  return (
    <>
      <div className="rna-container">

      </div>
      <Container fluid>

        <Card>
          <Card.Header>
            <Card.Title as="h4">Subscription</Card.Title>

          </Card.Header>
          <Card.Body>
            <Row className="justify-content-center">

              <Col md="9">

                {
                  subscriptionDetails.subscribe ? <Alert className="m-2" variant="info">


                    <h4 style={{ fontWeight: "500" }} className=""><b>You already have a monthly subscription!</b></h4>
                    <span>Starting Date : {subscriptionDetails.start_date}</span>
                    <br />
                    <span >End Date : {subscriptionDetails.end_date}</span>



                  </Alert> : <div className="pricing-table">
                    {
                      subscriptionPackages.map((p) => {
                        return <div key={p.id} className="pricing-card" data-color={p.color} style={{ borderColor: colors[p.color] }}>
                          <h3 className="pricing-card-header" style={{ backgroundColor: colors[p.color] }}>{p.name}</h3>
                          <div className="price" style={{ color: colors[p.color] }}><sup>{p.currency}</sup>{p.price}<span>/{p.period}</span></div>
                          <ul style={{ listStyle: "none", marginLeft: "-30px" }} className="text-center">
                            <li ><strong>Unlimited</strong> Access</li>
                            <li ><strong>For</strong> </li>
                            <li><strong>1</strong> {p.period === "monthly" ? "Month" : "Year"} Duration</li>

                          </ul>
                          <button data-color={p.color} style={{ color: colors[p.color], borderColor: colors[p.color] }} onClick={(e) => gotoStripePaymentPage(e)} className="order-btn"> Pay Now</button>
                        </div>

                      })

                    }
                  </div>
                }

                {/* <div className="container mt-5">
                    <button type="submit" className="btn btn-primary" onClick={(e)=>gotoStripePaymentPage(e)} id="submitBtn">Subscribe</button>
              </div>  */}

              </Col>

            </Row>
            <br></br>
            <br></br>

          </Card.Body>
        </Card>

      </Container>
    </>
  );
}

export default subscription;
