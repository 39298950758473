import React, { useState } from "react";
import axios from "axios";
import { popup } from "utils/popup";
import currentDateTime from "utils/currentDateTime";
import BankAccountDropdown from "./BankAccountDropdown";

import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col
}
  from "react-bootstrap";
import Sidebar from "components/Sidebar/Sidebar";
import sidebarImage from "assets/img/sidebar-3.jpg";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import { Link } from "react-router-dom";
import toast from "../utils/toast";
import { useHistory } from "react-router-dom";
import routes from "routes.js";
import { BASE_URL } from "apiUrl";


function addPaidTax() {

  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const mainPanel = React.useRef(null);


  const [banks, setBanks] = useState([])
  const [bank, setBank] = useState(false)
  const [amount, setAmount] = useState(0)
  const [dateTime, setDateTime] = useState(currentDateTime)
  const [description, setDescription] = useState("")
  const [formErros, setFormErrors] = useState({})
  const [selected, setSelected] = useState("cash")
  const history = useHistory()



  React.useEffect(() => {
    let now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    let currentDateTime = now.toISOString().slice(0, 16)
    getAllBanks()
    setDateTime(currentDateTime)
    console.log(currentDateTime)
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);


  const formValidation = () => {

    if (!amount) {
      setFormErrors({
        ...formErros,
        amount: {
          error: true,
          message: "Please fill the amount "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else if (amount.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        amount: {
          error: true,
          message: "Please fill the amount "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else if (isNaN(amount)) {
      setFormErrors({
        ...formErros,
        amount: {
          error: true,
          message: "Only digits allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else {

      delete formErros.amount

    }

    return true

  }



  const getAllBanks = () => {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/bank-management/banks/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setBanks(data)

          })
        }
        else if (response.status === 401) {
          //alert('not authenticated')
          localStorage.clear()
          window.location.href = '/'
        }
      })
  }


  const selectedOption = (e) => {
    setSelected(e.target.value)

  }



  const addPaidTax = async (e) => {
    e.preventDefault()
    if (formValidation()) {


      const token = localStorage.getItem('token')
      const JWTToken = JSON.parse(token)
      const rawDate = new Date(dateTime + 'Z')
      console.log(rawDate)
      const body = JSON.stringify({
        bank: bank,
        amount: amount,
        description: description,
        type: selected,
        date: rawDate.toISOString()

      })
      console.log(body)

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + JWTToken
        }
      }

      const url = `${BASE_URL}/api/tax-management/add-paid-tax/`


      try {
        const resp = await axios.post(url, body, config)
        console.log(resp)
        if (resp.status === 201) {

          popup.success("Paid tax added successfully");
          history.push('/admin/tax')


        }
        else if (resp.status === 400) {
          popup.success("Paid tax not added successfully");
        }
        else if(resp.status === 401){
          localStorage.clear()
          window.location.href = '/'
        }

      }
      catch (err) {
        console.log(err)
      }




    }
  }

  const bankSelected = (e) => {
    setBank(e.target.value)

  }

  console.log(banks)
  return (
    <>
      <div className="wrapper">
        <Sidebar activeLink="/tax" color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Container fluid>
              <Row>





                <Col md="8" className="order-md-1">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">Add Paid Tax</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={(e) => addPaidTax(e)}>




                        <Row>
                          <Col md="12">
                            <Form.Group className="">
                              <label>Select Option</label>
                              <Form.Control

                                name="select"
                                as="select"
                                onChange={(e) => { selectedOption(e) }}
                                required={true}
                              >
                                <option value="bank" >Bank</option>
                                <option value="cash" selected>Cash</option>

                              </Form.Control>
                            </Form.Group>
                          </Col>





                        </Row>
                        <Row>


                          {
                            selected == "bank" ? <Col md="12">
                              {/* <Form.Group className="">
                          <label>Bank Account</label>
                          <Form.Control
                             
                              name="bank"
                              as="select"
                              onChange={(e)=>{bankSelected(e)}}  
                              required={true}        
                              
                          >
                          <option value="" selected>Select Bank</option>
                              
                                  {
                                      
                                      banks.map(bank=>{
                                          return <option value={bank.id} key={bank.id}>{bank.bank_name} {bank.branch_name} Acc No ({bank.account_no})</option>
                                      })
                                  }
                              
                          </Form.Control>
                        </Form.Group> */}
                              <BankAccountDropdown banks={banks} setBank={setBank} />
                            </Col> : ""
                          }


                        </Row>
                        <Row>
                          <Col sm="12" >
                            <Form.Group>
                              <label>Date & Time</label>
                              <Form.Control
                                defaultValue={currentDateTime}
                                className={formErros.dateTime ? "errors" : ""}
                                placeholder="Date & Time"
                                type="datetime-local"
                                onChange={(e) => setDateTime(e.target.value)}
                                required={true}
                              ></Form.Control>
                              {formErros.dateTime ? <p style={{ color: "red" }}>*{formErros.dateTime.message}</p> : ""}

                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <label>Amount</label>

                              <Form.Control
                                className={formErros.amount ? "errors" : ""}

                                placeholder="Amount"
                                type="text"
                                name="amount"
                                onChange={(e) => setAmount(e.target.value)}
                              >

                              </Form.Control>
                              {formErros.amount ? <p style={{ color: "red" }}>*{formErros.amount.message}</p> : ""}

                            </Form.Group>
                          </Col>
                        </Row>







                        {/* <Col md="12">
                      <Form.Group>
                        <label>Description</label>
                        <Form.Control
                          cols="80"
                          
                          placeholder="Here can be your description"
                          rows="4"
                          as="textarea"
                          name="description"
                          onChange={(e)=>setDescription(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col> */}

                        <Row>


                        </Row>

                        <br />
                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                        >
                          Add Paid Tax
                        </Button>
                        <div className="clearfix"></div>

                      </Form>
                    </Card.Body>
                  </Card>
                </Col>


              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </div>


    </>
  );
}

export default addPaidTax;
