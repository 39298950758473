import { useState,useEffect} from "react"
import "../assets/css/bank-account-dropdown.css"

const MilestoneDropdown = ({milestones,setMilestone})=>{
    
    const [data,setData]=useState([]);
    const [allData,setAllData] = useState([])
    const [openDropdown,setOpenDropdown] = useState(false)
    const [displayText,setDisplayText] = useState("Select Milestone")
    const [totalItems,setTotalItems] = useState(4)
    
    
    useEffect(()=>{
        if(milestones.length>0){
          setData(milestones.slice(0, totalItems))
        }
        setAllData(milestones)
    },[milestones])

    

    const milestoneSelect = (e)=>{
        let milestone = JSON.parse(e.target.value)
        let dispTxt = milestone.title
        setDisplayText(dispTxt)
        setMilestone(milestone.id)
        setOpenDropdown(!openDropdown)
       
    }



      

      const searchMilestone = (e)=>{
        console.log(e.target.value)
        let query = e.target.value.trim()
        if(query!==""){
          let matched = allData.filter((milestone)=>milestone.title.toUpperCase().includes(query.toUpperCase()))
          console.log(matched)
          if(matched.length>0){
            setData(matched)
          }else {
            setData(allData.slice(0, 4))
          }
        }else{

        }
      }

      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
    
      const onScroll = async (e) => {
        var maxScrollPosition = e.target.scrollHeight - e.target.clientHeight
        const currentScroll = e.target.scrollTop
        if(currentScroll===maxScrollPosition){
            let items =  totalItems+4
            await sleep(1 * 1000)
            setData(allData.slice(0, items))
            setTotalItems(items)
        }
        
      };

    return <>
    <div className="select_box_wrapper ">
  <div className="custom_select_box" onClick={()=>{setOpenDropdown(!openDropdown)}}>
    <div className="custom_select_box_selected_label">
      <label className="custom_select_box_selected_label_text" style={{cursor:"pointer"}}>{displayText}</label>
    </div>
    <div className="custom_select_box_dropdown_icon">
      <a className="custom_select_box_dropdown_icon_button"><i className="fas fa-angle-down"></i></a>
    </div>
  </div>
  {
    openDropdown?<div onScroll={onScroll} className="custom_select_dropdown" >
    <ul className="custom_select_dropdown_list_wrapper">
      <li className="custom_select_dropdown_list_search">
          <input  className="custom_select_dropdown_list_search_input" placeholder="Search Milestone" 
          onChange={(e)=>{searchMilestone(e)}}
          type="text" />
      </li>
      {data.map((item)=>{
                    
                    return <li key={item.id} className="custom_select_dropdown_list">
                      <input className="custom_select_dropdown_list_radio" type="radio" value={JSON.stringify(item)} onChange={(e)=>{milestoneSelect(e)}} /><span className="custom_select_dropdown_list_label">{item.title} </span>
                    </li>}
      )} 
      
      </ul>
    </div>:null
  }
</div>

    </>
}
export default MilestoneDropdown