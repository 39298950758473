import React, { useState } from "react";
import axios from "axios";
import { popup } from "utils/popup";
import "assets/css/invoice.css"
import moment from "moment";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col
}
  from "react-bootstrap";
import Sidebar from "components/Sidebar/Sidebar";
import sidebarImage from "assets/img/sidebar-3.jpg";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import { Link } from "react-router-dom";
import toast from "../utils/toast";
import { useParams } from "react-router-dom";
import routes from "routes.js";
import { BASE_URL } from "apiUrl";

function SalaryInvoice() {

  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const mainPanel = React.useRef(null);


  const [paymentDetails, setPaymentDetails] = useState(null)
  const [takePrint, setTakePrint] = useState(false)
  const { id } = useParams()




  React.useEffect(() => {


    getPaymentDetails()
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location, takePrint]);




  const sendSalarySlip = async (e) => {

    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
    const body = JSON.stringify({
      slip_id: id
    })

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + JWTToken
      }
    }

    const url = `${BASE_URL}/api/salary-management/send-salary-slip/`


    try {
      const resp = await axios.post(url, body, config)
      console.log(resp)
      if (resp.status === 200) {

        popup.success("Salary slip send successfully");

      }
      else if (resp.status === 400) {
        popup.success("Salary slip not send successfully");
      }

    }
    catch (err) {
      console.log(err)
    }
  }


  const getPaymentDetails = () => {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/salary-management/payment-details/${id}/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {

            setPaymentDetails(data)

          })
        }
        else if (response.status === 401) {
          //alert('not authenticated')
          localStorage.clear()
          window.location.href = '/'
        }
      })
  }



  console.log(paymentDetails)
  return (
    <>
      <div className="wrapper">
        <Sidebar activeLink="/salary" color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Container fluid>
              <Row>
                <Col md="12">
                  <Card>
                    {
                      paymentDetails ? <div id="invoice_printable" className="container bootstrap snippets bootdeys">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="panel panel-default invoice" id="invoice">
                              <div className="panel-body">
                                <div className="invoice-ribbon"><div className="ribbon-inner">PAID</div></div>
                                <div className="row">
                                  <div className="col-sm-6 mt-3 top-left">
                                    <img
                                      src={paymentDetails ? BASE_URL + paymentDetails.company_logo : ""}
                                      width="130px"
                                      onError={({ currentTarget }) => {
                                        console.log('on error')
                                        currentTarget.onerror = null;
                                        currentTarget.src = require("assets/img/logo.png").default
                                      }}
                                    />

                                  </div>
                                  <div className="col-sm-6 top-right mt-5 " >
                                    <h3 className="marginright">Salary-Slip #{paymentDetails.id}</h3>

                                  </div>
                                </div>
                                <hr />
                                <div className="row">
                                  <div className="col-md-4 from text-left pl-5">
                                    <p className="lead marginbottom">From : {paymentDetails.company_name}</p>
                                    <p className="cap">Bank Name : {paymentDetails.sender_bank_details.bank_name}</p>
                                    <p className="cap">Account No : {paymentDetails.sender_bank_details.account_no}</p>

                                  </div>
                                  <div className="col-md-4 to text-left pl-5">
                                    <p className="lead marginbottom cap">To : {paymentDetails.paid_to.first_name} {paymentDetails.paid_to.last_name}</p>
                                    <p className="cap">Address: {paymentDetails.paid_to.address}</p>
                                    <p className="cap">Phone: {paymentDetails.paid_to.mobile_number}</p>
                                    <p className="cap">Bank Name : {paymentDetails.paid_to.bank_name}</p>
                                    <p className="cap">Account No : {paymentDetails.paid_to.account_no}</p>
                                  </div>
                                  <div className="col-md-4 text-left pl-5  cap payment-details">
                                    <p className="lead marginbottom payment-info">Payment details</p>
                                    <p className="cap">Date/Time: {paymentDetails?.paid_at ? moment(paymentDetails.paid_at.split('Z')[0]).format('MMMM DD, YYYY h:mm:ss A') : ''}</p>

                                    <p className="cap">Total Amount: {localStorage.getItem('currency')}{parseFloat(parseFloat(paymentDetails.salary_amount) + parseFloat(paymentDetails.bonus) + parseFloat(paymentDetails.allowance) - parseFloat(paymentDetails.tax_deduction)).toFixed(2)}</p>
                                    <p className="cap">Description: {paymentDetails.description}</p>
                                  </div>
                                </div>
                                <div className="row table-row">
                                  <table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th className="text-center" style={{ width: '5%' }}>#</th>
                                        <th style={{ width: '50%', paddingLeft: "30px" }}>Item</th>
                                        <th className="text-right" style={{ width: '10%' }}>Total Amount</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td >1</td>
                                        <td>Basic Salary</td>
                                        <td >{localStorage.getItem('currency')}{parseFloat(paymentDetails.salary_amount).toFixed(2)}</td>
                                      </tr>
                                      <tr>
                                        <td >2</td>
                                        <td>Allowance</td>
                                        <td >{localStorage.getItem('currency')}{parseFloat(paymentDetails.allowance).toFixed(2)}</td>
                                      </tr>
                                      <tr>
                                        <td >3</td>
                                        <td>Bonus</td>
                                        <td >{localStorage.getItem('currency')}{parseFloat(paymentDetails.bonus).toFixed(2)}</td>
                                      </tr>
                                      <tr>
                                        <td >4</td>
                                        <td>Tax Deduction</td>
                                        <td >{localStorage.getItem('currency')}{parseFloat(paymentDetails.tax_deduction).toFixed(2)}</td>
                                      </tr>



                                    </tbody>
                                  </table>
                                </div>
                                <div className="row">
                                  <div className="col-md-6  text-left margintop">
                                    <p className="lead marginbottom">THANK YOU!</p>
                                    <button onClick={() => sendSalarySlip()} className="btn btn-primary"  ><i className="fas fa-paper-plane" /> Send Salary Slip</button>

                                    <a href={`${BASE_URL}/api/salary-management/download-salary-slip/${id}/`}>
                                      <button className="btn btn-success ml-2" id="invoice-print" ><i className="fa fa-print" /> Print Invoice</button>
                                    </a>
                                  </div>
                                  <div className="col-md-6 text-right pull-right invoice-total">
                                    <p>Total Salary : {localStorage.getItem('currency')}{parseFloat(parseFloat(paymentDetails.salary_amount) + parseFloat(paymentDetails.bonus) + parseFloat(paymentDetails.allowance)).toFixed(2)}</p>
                                    <p>Tax Deduction : {localStorage.getItem('currency')}{parseFloat(paymentDetails.tax_deduction).toFixed(2)}</p>

                                    <p>Net Salary : {localStorage.getItem('currency')}{parseFloat(parseFloat(paymentDetails.salary_amount) + parseFloat(paymentDetails.bonus) + parseFloat(paymentDetails.allowance) - parseFloat(paymentDetails.tax_deduction)).toFixed(2)} </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> : ""
                    }
                  </Card>
                </Col>

              </Row>


            </Container>





          </div>
          <Footer />
        </div>




      </div>


    </>
  );
}

export default SalaryInvoice;
