import React,{useState,useEffect} from "react";
import { BASE_URL } from "apiUrl";
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import moment from "moment";
const MyPaginate = styled(ReactPaginate).attrs({
  
})`
  margin-bottom: 2rem;
  margin-top:30px;
  display:flex;
  justify-content: center;
  list-style-type: none;
  padding: 0 5rem;
  li a {
    margin: 4px;
    float:left;
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.selected a {
    background-color: #1DC7EA;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table,
  NavDropdown,

} from "react-bootstrap";
import toast from "../utils/toast";

const EmployeeTransactionHistory =({bankID})=> {
    const [transactions,setTransactions] = useState([])
    const [data,setdata] = useState([])
    const [employeeID,setEmployeeID] = useState(null)
    useEffect(()=>{
    

        const token = localStorage.getItem('token')
        const JWTToken = JSON.parse(token)
       
        fetch(`${BASE_URL}/api/employee-management/employees/`,{
          headers:{
              "Authorization" : "Bearer "+JWTToken
          }
          })
        .then((response) =>{
           if(response.status===200){
            response.json().then((data)=>{
              setdata(data)
              
            })
           }
           })
      },[])
    
     function TransactionHistory(empID)
     {
        let item={bankID,empID};
        const token = localStorage.getItem('token')
        const JWTToken = JSON.parse(token)
   
        const body = JSON.stringify(item)
        const url = `${BASE_URL}/api/bank-management/employee-bank-transactions/`
        fetch(url,{
         method: 'POST',
         headers:{
             "Authorization" : "Bearer "+JWTToken,     
             'Content-Type':'application/json',  
         },
           body:body
       }).then(response=>{
         
           if(response.status===200){
               response.json().then(data=>{
                console.log(data)
                setTransactions(data.salaries)
               })
           }
           else if (response.status===400){
                console.log(400)
                setTransactions([])
           }
           else if(response.status===401){
            localStorage.clear()
            window.location.href = '/'
           }
        })


     }


    const EmployeeTransaction = ({currentItems})=>{
      return <>
      {currentItems.map((item)=>
                  <tr>
                  <td>{item.account_no}</td>
                  <td>{item.bank_name}</td>
                  <td>{item.salary_amount}</td>
                  <td>{item.description}</td>
                  <td>{item?.paid_at ? moment(item.paid_at.split('Z')[0]).format('MMMM DD, YYYY h:mm:ss A') : ''} </td>
                 
                </tr>
      )}
      </>
    }

     const PaginateEmployeeTransactionHistory = ({ itemsPerPage })=> {
      const [currentItems, setCurrentItems] = useState(transactions);
      const [pageCount, setPageCount] = useState(0);
      const [itemOffset, setItemOffset] = useState(0);
    
      useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(transactions.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(transactions.length / itemsPerPage));
      }, [itemOffset, itemsPerPage]);
    
      const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % transactions.length;
        setItemOffset(newOffset);
      };
    
      return (
        <>
          
                    
                      
      
          <div class="tableWrap">
                  <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">Account # (Paid To)</th>
                      <th className="border-0">Bank (Paid To) </th>
                      <th className="border-0">Total Amount</th>
                      <th className="border-0">Description</th>
                      <th className="border-0">Date/Time</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                  
                    <EmployeeTransaction 
                    currentItems={currentItems}
                    />
    
                  </tbody>
          </Table>
          </div>
          <MyPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
          />
        
        </>
      );
    }
  
   
     
  
  return (
    <>
     
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Employee Transaction History</Card.Title>
                <br/>
                <select className="form-control" onChange={(e)=>{
                    console.log(e.target.value)
                    if(e.target.value.trim()!=="Select employee for transactions"){
                        TransactionHistory(e.target.value)
                    }else{
                        setTransactions([])
                    }
                    
                }}>
                    <option name={null} value={null}> Select employee for transactions </option>
                    {
                        data.map((emp)=>{
                            return <option key={emp.id} name={emp.id} value={emp.id}>{emp.first_name} {emp.last_name}</option>
                        })
                    }
                   
                </select>
                

              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                  
                  <PaginateEmployeeTransactionHistory itemsPerPage={15} />

              </Card.Body>
            </Card>
          </Col>
    
            
      
    </>
  );
}

export default EmployeeTransactionHistory;
