import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useState } from 'react'


const MobileNumberField = ({mobileNumber, name, setMobileNumber, refr=null, onkeydown=()=>{}})=>{
    
    return <>
         <PhoneInput
            ref={refr}
            className='form-control'
            placeholder="Phone Number"
            value={mobileNumber}
            onChange={(value)=> {setMobileNumber({target:{name, value}})}}
            onKeyDown={onkeydown}
        />
    </>
}
export default MobileNumberField