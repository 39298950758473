import React, { useEffect, useState } from 'react'
import toast from "../utils/toast";
import {Link} from "react-router-dom"
import "../assets/css/register.css"
import { useHistory } from 'react-router-dom';
import { BASE_URL } from 'apiUrl';
import { popup } from "utils/popup";

function login() {
    const[email,setemail]=useState("");
    const[password,setpass]=useState("");
    const [formErros,setFormErrors]=useState({})
    const [submit,setSubmit]=useState(false)

    const history=useHistory();
    useEffect(()=>{
        if(localStorage.getItem('token'))
        {
            history.push("/admin/dashboard");
        }
    },[submit])
    async function login(e)
    
    {
        e.preventDefault()

        if (formValidation()){
            if(email!="" && password!="")
        {
            let item={email,password};
            fetch(`${BASE_URL}/api/token/`,{
                         method:"POST",
                         body:JSON.stringify(item),
                         headers:{
                             "Content-Type":'application/json',
                             "Accept":'application/json'
                         }
                     }).then(response=>{
                        if(response.status===200){
                            response.json().then(data=>{
                                
                                const body=JSON.stringify({
                                        "refresh":data.refresh
                                    })
                                console.log(body)
                                fetch(`${BASE_URL}/api/token/refresh/`,{
                                    method: 'POST',
                                    headers:{
                                        'Content-Type':'application/json',
                                        
                                    },
                                    body:body
                                }).then(response=>{
                                    if(response.status===200){
                                        response.json().then(data=>{
                                            localStorage.setItem("token",JSON.stringify(data.access))
                                            getUser()
                                        })
                                        
                                    }
                                })
                                
                            })
                            
                        }
                        else if (response.status === 401) {
                           
                            popup.error("Your email or password is not valid")
                            setSubmit(!submit)
                           
                        }

            }).catch(err=>{
                alert(err)
            })
        }
           
        }
        
    }


    const getUser = ()=>{
        try{
          const token = localStorage.getItem('token')
          const JWTToken = JSON.parse(token)
   
      fetch(`${BASE_URL}/api/user/profile/`,{
        headers:{
            "Authorization" : "Bearer "+JWTToken
        }
        })
      .then((response) =>{
         if(response.status===200){
          response.json().then((resp)=>{
            let twoFactor = resp.two_factor_auth
            localStorage.setItem("two_factor",JSON.stringify(twoFactor))
            localStorage.setItem("user_email",JSON.stringify(resp.email))
            localStorage.setItem("user",JSON.stringify(resp))
            if(twoFactor){
              //popup.success("Veriefy your OTP")
            }else{
              popup.success("Login successfully")
            }
            history.push("/admin/user/")
            
          })
         }
         })
        }catch{
          popup.success("Login successfully")
          history.push("/admin/user/")

        }
      }
  
    
      const formValidation = ()=>{
          //email validation

        if(!email){
            setFormErrors({
              ...formErros,
              email:{
                error:true,
                message:"Please fill the email"
              }
            })
            return false
        }else if(!validateEmail(email)){
          setFormErrors({
            ...formErros,
            email:{
              error:true,
              message:"Please enter a valid email"
            }
          })
          return false
        }
        
        else{
               
          delete formErros.email
          
        }
        //password validation

        if((!password)||password.trim().length<=0){
            setFormErrors({
              ...formErros,
              password:{
                error:true,
                message:"*Please fill password"
              }
            })
            return false
          }

          else if(!password.trim().length<=0){
            setFormErrors({
              ...formErros,
              password:{
                error:false,
                message:""
              }
            })
      
          }
          
          else{
                   
            delete formErros.position
            
          }
          
          return true

      }


      const validateEmail=(email)=> {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return (
        
        <div className="bod">





        {/* <div className="main-con container-fluid px-1 py-5 mx-auto">
            <div className="row d-flex justify-content-center">
                <div className="col-xl-7 col-lg-8 col-md-9 col-11 text-center">
                
                <div className="card">
                <h3>Login</h3>
                <br/>
                    <form className="form-card" onSubmit={(e)=>login(e)}>
                    
                    <div className="row justify-content-between text-left">
                        <div className="form-group col-12 flex-column d-flex"> <label className="form-control-label px-3">Email<span className="text-danger"> *</span></label> <input type="email" value={email} id="email" name="email" placeholder="example@gmail.com"  onChange={(e)=>setemail(e.target.value)}/> </div>
                        
                    </div>
                    
                    
                    <div className="row justify-content-between text-left">
                        <div className="form-group col-12 flex-column d-flex"> <label className="form-control-label px-3">Password<span className="text-danger"> *</span></label> <input type="password" value={password} id="ans" name="password" placeholder="**********" onChange={(e)=>setpass(e.target.value)}/> </div>
                    </div>
                    <div className="row justify-content-end">
                        <div className="form-group col-sm-6"> <button type="submit" className=" btn btn-primary">Login</button> </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div> */}
        <div className="container-fluid">
    <div className="container-fluid register" >
    <div className="row">
      <div className="col-md-5 register-left">
        <img src={require("assets/img/office_hassle_512x512.png").default} alt="" />
        <h3>Welcome</h3>
        <p> <b style={{fontWeight:"600"}}>OfficeHassle By ENTSPOS</b> <br/> &copy; OfficeHassle.com {new Date().getFullYear()}</p>
        
        <p></p>
        </div>
      <div className="col-md-6 register-right">
      <ul className="nav nav-tabs text-right nav-justified" id="myTab" role="tablist">
                            
                            <li className="nav-item text-center mt-4">
                            <span>Don't have an account yet?  </span>
                            <Link  to="/register/" style={{color:"#0062cc"}}><b> REGISTER </b></Link>
                            </li>
                            </ul>
        <form onSubmit={(e)=>login(e)}>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <h3 className="register-heading"><span style={{fontSize:"12px",fontWeight:"500"}}>OR</span><br/>Login</h3>
            <div className="row register-form">
              <div className="col-md-12">
                
                <div className="form-group">
                  <input type="email" className={formErros.email?"errors form-control ":"form-control"}
                            value={email} id="email" name="email" placeholder="Enter your email"  onChange={(e)=>setemail(e.target.value)} />
                {formErros.email?<p style={{color:"red"}}>*{formErros.email.message}</p>:""}
                
                </div>
                
                
              </div>
              <div className="col-md-12">
                
                
                <div className="form-group">
                  <input  className={formErros.password?formErros.password.error?"errors form-control ":"form-control":"form-control"}
                             type="password"  placeholder="Enter your password" value={password} id="ans" name="password"  onChange={(e)=>setpass(e.target.value)} />
                {formErros.password?<p style={{color:"red"}}>{formErros.password.message}</p>:""}
                        
                </div>
                
                <input  type="submit" className="btnRegister col-md-3" style={{marginTop:"-2px",padding:"7px"}} value="Login" />
              <br/>
              <br/>
              <div className="form-group"><br/></div>
              <div className="form-group"><br/></div>
             
              </div>
            </div>
          </div>
          
        </div>
        </form>
      </div>
    </div>
  </div>
</div>
        </div>
    )
}

export default login
