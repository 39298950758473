import React from 'react'

const LockedContent = ({ fontSize, top, left, width }) => {

    const styles = {
        position: 'absolute',
        top: top,
        left: left,
        transform: 'translate(-50%, -50%)',
        zIndex: '1',
        textAlign: 'center',
        height: '80%',
        width: width || '100%' + "important",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"

    }
    return (
        <div style={styles}>
            <p style={{
                fontSize: fontSize + "px",
                color: '#0e0d0d',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',
            }}>Premium Feature Locked  <i class="fas fa-lock"></i><br></br>
                <span style={{ fontSize: fontSize / 2 + "px" }}>Subscribe to a plan to unlock this feature.</span>
            </p>
        </div>
    )
}

export default LockedContent