// import firebase from 'firebase'

// firebase.initializeApp(firebaseConfig);
import firebase from "firebase/app";
import "firebase/messaging";

var firebaseConfig = { 
  apiKey: "AIzaSyA3MyDmds0TI_HEGMK-IXTjzi9DGclzJwk",
authDomain: "management-sys-d0e86.firebaseapp.com",
projectId: "management-sys-d0e86",
storageBucket: "management-sys-d0e86.appspot.com",
messagingSenderId: "921549919435",
appId: "1:921549919435:web:21b1226a1cd6e96d9b374e"
}

firebase.initializeApp(firebaseConfig);

export default firebase
