import React, { useEffect, useState } from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import Sidebar from "components/Sidebar/Sidebar";
import sidebarImage from "assets/img/sidebar-3.jpg";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import routes from "routes.js";
import { useParams } from "react-router-dom";
import { BASE_URL } from "apiUrl";

function salaryHistory() {
  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const [data, setdata] = useState([]);
  const mainPanel = React.useRef(null);
  const history = useHistory();
  useEffect(() => {


    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/salary-management/salary-history/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setdata(data)
            console.log(setdata)
          })
        }
        else if (response.status === 401) {
          //alert('not authenticated')
          localStorage.clear()
          window.location.href = '/'
        }
      })




  }, [])


  console.log(data);

  return (
    <>
      <div className="wrapper">
        <Sidebar color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Container fluid>
              <Row>
                <Col md="12">
                  <Card className="strpied-tabled-with-hover">
                    <Card.Header>
                      <Card.Title as="h4">Salary History</Card.Title>
                    </Card.Header>
                    <Card.Body className="table-full-width table-responsive px-0">
                      <Table className="table-hover table-striped">
                        <thead>
                          <tr>
                            <th className="border-0">Employee Name</th>
                            <th className="border-0">Bank Name</th>
                            <th className="border-0">Account no</th>
                            <th className="border-0">Salary</th>
                            <th className="border-0">Date/Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((item) =>
                            <tr>
                              <td>{item.paid_to.first_name} {item.paid_to.last_name}</td>
                              <td>{item.bank_name}</td>
                              <td>{item.account_no}</td>
                              <td>{item.salary_amount}</td>
                              <td>{item.paid_at}</td>
                            </tr>
                          )}


                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>

              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default salaryHistory;
