import React, { useState } from "react";
import { BASE_URL } from "apiUrl";
import { popup } from "utils/popup";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import Sidebar from "components/Sidebar/Sidebar";
import sidebarImage from "assets/img/sidebar-3.jpg";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import { Link } from "react-router-dom";
import toast from "../utils/toast";
import routes from "routes.js";

function addBank() {

  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const mainPanel = React.useRef(null);

  const [bank_name, setbankname] = useState("");
  const [account_no, setaccountno] = useState("");
  const [account_title, setaccounttitle] = useState("");
  const [branch_name, setbranchname] = useState("");

  const [currentInput, setCurrentInput] = useState(1);
  const inputRefs = React.useRef([]);

  const [formErros, setFormErrors] = useState({})



  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);



  const formValidation = () => {


    //bank name validation 
    if (!bank_name) {
      setFormErrors({
        ...formErros,
        bank_name: {
          error: true,
          message: "Please fill the bank name"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    } else if (bank_name.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        bank_name: {
          error: true,
          message: "Please fill the bank name"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else if (!isNaN(bank_name)) {
      setFormErrors({
        ...formErros,
        bank_name: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else {

      delete formErros.bank_name

    }

    //validate account no 

    if (!account_no) {
      setFormErrors({
        ...formErros,
        account_no: {
          error: true,
          message: "Please fill the account no "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else if (account_no.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        account_no: {
          error: true,
          message: "Please fill the account no "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else if (isNaN(account_no)) {
      setFormErrors({
        ...formErros,
        account_no: {
          error: true,
          message: "Only digits allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else {

      delete formErros.account_no

    }
    //validate account title

    if (!account_title) {
      setFormErrors({
        ...formErros,
        account_title: {
          error: true,
          message: "Please fill the account title "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else if (account_title.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        account_title: {
          error: true,
          message: "Please fill the account title "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else if (!isNaN(account_title)) {
      setFormErrors({
        ...formErros,
        account_title: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else {

      delete formErros.account_title

    }

    //branch name
    if ((!branch_name) || branch_name.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        branch_name: {
          error: true,
          message: "Please fill branch name"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else if (!isNaN(branch_name)) {
      setFormErrors({
        ...formErros,
        branch_name: {
          error: true,
          message: "Only character allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else {

      delete formErros.branch_name

    }
    return true


  }

  function setbank(e) {
    e.preventDefault();

    if (formValidation()) {


      let item = { bank_name, account_no, branch_name, account_title };
      const token = localStorage.getItem('token')
      const JWTToken = JSON.parse(token)

      const body = JSON.stringify(item)
      const url = `${BASE_URL}/api/bank-management/add-bank/`
      fetch(url, {
        method: 'POST',
        headers: {
          "Authorization": "Bearer " + JWTToken,
          'Content-Type': 'application/json',
        },
        body: body
      }).then(response => {

        if (response.status === 201) {
          popup.success("Bank added successfully");
          setbankname('')
          setbranchname('')
          setaccountno('')
          setaccounttitle('')
        }
        else if (response.status === 400) {

        }
        else if(response.status === 401){
          localStorage.clear()
          window.location.href = '/'
        }
      })



    }


  }


   const handleKeyDown = (event, index) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.current.length) {
        inputRefs.current[nextIndex].focus();
        setCurrentInput(nextIndex);
        return 
      }
      // here submit the form
      console.log('Submiting.....');
      setbank(event);
      return 
    }else if (event.key === 'Backspace') {
      const currentValue = event.target.value;
      if (currentValue === '' && index > 0) {
        const prevIndex = index - 1;
        inputRefs.current[prevIndex].focus();
        setCurrentInput(prevIndex);
      }
     return 
    }
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar activeLink="/bank" color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Container fluid>
              <Row>
                <Col md="8">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">Add Bank</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={(e) => setbank(e)}>

                        <Row>
                          <Col sm="12" sx="12" md="6">
                            <Form.Group>
                              <label>Bank name</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[0] = el)}
                                className={formErros.bank_name ? "errors" : ""}
                                placeholder="Bank name"
                                type="text"
                                value={bank_name}
                                onChange={(e) => setbankname(e.target.value)}
                                onKeyDown={(event) => handleKeyDown(event, 0)}
                              ></Form.Control>
                              {formErros.bank_name ? <p style={{ color: "red" }}>*{formErros.bank_name.message}</p> : ""}

                            </Form.Group>
                          </Col>
                          <Col sm="12" sx="12" md="6">
                            <Form.Group>
                              <label>Account no</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[1] = el)}
                                className={formErros.account_no ? "errors" : ""}
                                placeholder="Account no"
                                type="text"
                                value={account_no}
                                onChange={(e) => setaccountno(e.target.value)}
                                onKeyDown={(event) => handleKeyDown(event, 1)}
                              ></Form.Control>
                              {formErros.account_no ? <p style={{ color: "red" }}>*{formErros.account_no.message}</p> : ""}

                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="12" sx="12" md="6">
                            <Form.Group>
                              <label>Account title</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[2] = el)}
                                className={formErros.account_title ? "errors" : ""}
                                placeholder="Account title"
                                type="text"
                                value={account_title}
                                onChange={(e) => setaccounttitle(e.target.value)}
                                onKeyDown={(event) => handleKeyDown(event, 2)}
                              ></Form.Control>
                              {formErros.account_title ? <p style={{ color: "red" }}>*{formErros.account_title.message}</p> : ""}

                            </Form.Group>
                          </Col>
                          <Col sm="12" xs="12" md="6">
                            <Form.Group>
                              <label>Bank branch</label>
                              <Form.Control
                                ref={(el) => (inputRefs.current[3] = el)}
                                className={formErros.branch_name ? "errors" : ""}
                                placeholder="Bank branch"
                                type="text"
                                value={branch_name}
                                onChange={(e) => setbranchname(e.target.value)}
                                onKeyDown={(event) => handleKeyDown(event, 3)}
                              ></Form.Control>
                              {formErros.branch_name ? <p style={{ color: "red" }}>*{formErros.branch_name.message}</p> : ""}

                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>

                        </Row>
                        <br />
                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                          onClick={setbank}
                        >
                          Add Bank
                        </Button>
                        <div className="clearfix"></div>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>

              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </div>


    </>
  );
}

export default addBank;
