import React, { useState } from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import Sidebar from "components/Sidebar/Sidebar";
import AdminNavbar from "components/Navbars/AdminNavbar";
import sidebarImage from "assets/img/sidebar-3.jpg";
import Footer from "components/Footer/Footer";
import routes from "routes.js";

function paymentFailed() {

  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const mainPanel = React.useRef(null);


  return (
    <>
      <div className="wrapper">
        <Sidebar color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Container fluid>
              <Row>
                <Col md="12">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">Payment Failed</Card.Title>
                    </Card.Header>
                    <Card.Body>


                      <div className="container-fluid p-4 col-md-7 text-center">

                        <div className="alert-box ">
                          <div className="alert alert-danger">
                            <div className="alert-icon mr-5 pr-5">
                              <i style={{ fontSize: "100px", fontWeight: "900" }} class="fas fa-times-circle"></i>
                            </div>
                            <div className="alert-message text-center">
                              <h4><strong style={{ fontWeight: "909" }}>Failed!</strong> Payment failed.</h4>
                            </div>
                          </div>
                        </div>
                      </div>


                    </Card.Body>
                  </Card>
                </Col>

              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </div>


    </>
  );
}

export default paymentFailed;
