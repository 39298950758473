import React, { useState } from "react";
import axios from "axios";
import { popup } from "utils/popup";

import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col
}
  from "react-bootstrap";
import Sidebar from "components/Sidebar/Sidebar";
import sidebarImage from "assets/img/sidebar-3.jpg";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import { Link } from "react-router-dom";
import toast from "../utils/toast";
import { useHistory } from "react-router-dom";
import routes from "routes.js";
import { useParams } from "react-router-dom";
import { BASE_URL } from "apiUrl";
import EditBankAccountDropdown from "./EditBankAccountDropdown";
function editPaidTax() {

  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const mainPanel = React.useRef(null);


  const [banks, setBanks] = useState([])
  const [bank, setBank] = useState(false)
  const [dateTime, setDateTime] = useState(null)
  const [amount, setAmount] = useState()
  const [description, setDescription] = useState("")
  const [formErros, setFormErrors] = useState({})
  const [selected, setSelected] = useState("cash")
  const history = useHistory()
  const { id } = useParams()
  const [data, setdata] = useState({})



  React.useEffect(() => {

    getAllBanks()
    getPaidTaxData()

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);


  const formValidation = () => {

    if (!amount) {
      setFormErrors({
        ...formErros,
        amount: {
          error: true,
          message: "Please fill the amount "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else if (toString(amount).trim().length <= 0) {
      setFormErrors({
        ...formErros,
        amount: {
          error: true,
          message: "Please fill the amount "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else if (isNaN(amount)) {
      setFormErrors({
        ...formErros,
        amount: {
          error: true,
          message: "Only digits allowed"
        }
      })
      popup.error("Form not submited ! Please fill all required fields")
      return false
    }
    else {

      delete formErros.amount

    }



    return true

  }


  async function getPaidTaxData() {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
    let item = id
    let url = `${BASE_URL}/api/tax-management/paid-tax/${item}/`
    let result = await fetch(url, {
      method: "GET",
      headers: {

        "Authorization": "Bearer " + JWTToken
      }
    })

    result = await result.json();
    setDescription(result.description)
    let date = new Date(result.date)
    date = date.toISOString().split(".")[0].slice(0, -3)
    setDateTime(date)
    console.log(date)
    setAmount(result.amount)
    if (result.bank) {
      setBank(result.bank.id)
    }
    setSelected(result.type)


  }



  const getAllBanks = () => {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/bank-management/banks/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setBanks(data)

          })
        }
        else if (response.status === 401) {
          //alert('not authenticated')
          localStorage.clear()
          window.location.href = '/'
        }
      })
  }


  const selectedOption = (e) => {
    setBank(false)
    setSelected(e.target.value)
  }



  const editPaidTax = async (e) => {
    e.preventDefault()
    if (formValidation()) {

      const token = localStorage.getItem('token')
      const JWTToken = JSON.parse(token)
      const rawDate = new Date(dateTime + 'Z')
      const body = JSON.stringify({
        bank: bank,
        amount: amount,
        description: description,
        type: selected,
        date: rawDate.toISOString()

      })
      console.log(body)
      const url = `${BASE_URL}/api/tax-management/paid-tax/${id}/`
      fetch(url, {
        method: 'PUT',
        headers: {
          "Authorization": "Bearer " + JWTToken,
          'Content-Type': 'application/json'

        },
        body: body

      }).then(response => {
        if (response.status === 200) {
          response.json().then(data => {
            setdata(data)
            popup.success("Paid tax updated successfully");
            history.push('/admin/tax')
          })
        }
        else if (response.status === 400) {
          popup.success("Paid tax not updated successfully");
        }
        else if(response.status === 401){
          localStorage.clear()
          window.location.href = '/'
        }
      })


    }
  }

  const bankSelected = (e) => {
    console.log(e.target.value)
    setBank(e.target.value)

  }

  console.log(banks)
  return (
    <>
      <div className="wrapper">
        <Sidebar activeLink="/expense" color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Container fluid>
              <Row>





                <Col md="8" className="order-md-1">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">Update Paid Tax</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={(e) => editPaidTax(e)}>




                        <Row>
                          <Col md="12">
                            <Form.Group className="">
                              <label>Select Option</label>
                              <Form.Control
                                required
                                name="select"
                                as="select"
                                onChange={(e) => { selectedOption(e) }}
                              >
                                <option value="bank" selected={selected == "bank" ? true : false}>Bank</option>
                                <option value="cash" selected={selected == "cash" ? true : false}>Cash</option>

                              </Form.Control>
                            </Form.Group>
                          </Col>





                        </Row>
                        <Row>


                          {
                            selected == "bank" ? <Col md="12">
                              {/* <Form.Group className="">
                          <label>Bank Account</label>
                          <Form.Control
                              required
                              name="bank"
                              as="select"
                              onChange={(e)=>{bankSelected(e)}}          
                              
                          >
                          <option value="" selected>Select Bank</option>
                              
                                  {
                                      
                                      banks.map(b=>{
                                          return <option value={b.id} key={b.id} selected={b.id==bank.id?true:false}>{b.bank_name} {b.branch_name} Acc No ({b.account_no})</option>
                                      })
                                  }
                              
                          </Form.Control>
                        </Form.Group> */}
                              <EditBankAccountDropdown banks={banks} selectedBank={bank} setBank={setBank} />
                            </Col> : ""
                          }


                        </Row>

                        <Row>
                          <Col sm="12" >
                            <Form.Group>
                              <label>Date & Time</label>
                              <Form.Control
                                defaultValue={dateTime}
                                className={formErros.dateTime ? "errors" : ""}
                                placeholder="Date & Time"
                                type="datetime-local"
                                onChange={(e) => setDateTime(e.target.value)}
                                required={true}
                              ></Form.Control>
                              {formErros.dateTime ? <p style={{ color: "red" }}>*{formErros.dateTime.message}</p> : ""}

                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>

                          <Col md="12">
                            <Form.Group>
                              <label>Amount</label>

                              <Form.Control
                                className={formErros.amount ? "errors" : ""}
                                defaultValue={amount}
                                placeholder="Amount"
                                type="text"
                                name="amount"
                                onChange={(e) => setAmount(e.target.value)}
                              >

                              </Form.Control>
                              {formErros.amount ? <p style={{ color: "red" }}>*{formErros.amount.message}</p> : ""}

                            </Form.Group>
                          </Col>
                        </Row>







                        {/* <Col md="12">
                      <Form.Group>
                        <label>Description</label>
                        <Form.Control
                          cols="80"
                          defaultValue={description}
                          placeholder="Here can be your description"
                          rows="4"
                          as="textarea"
                          name="description"
                          onChange={(e)=>setDescription(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col> */}

                        <Row>


                        </Row>

                        <br />
                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                        >
                          Edit Paid Tax
                        </Button>
                        <div className="clearfix"></div>

                      </Form>
                    </Card.Body>
                  </Card>
                </Col>


              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </div>


    </>
  );
}

export default editPaidTax;
