import React, { useEffect, useState } from "react";
import { popup } from "utils/popup";
import toast from "../utils/toast";
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import SweetAlert from 'react-bootstrap-sweetalert';
import "../assets/css/table.css"
const MyPaginate = styled(ReactPaginate).attrs({
  
})`
  margin-bottom: 2rem;
  margin-top:30px;
  display:flex;
  justify-content: center;
  list-style-type: none;
  padding: 0 5rem;
  li a {
    margin: 4px;
    float:left;
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.selected a {
    background-color: #1DC7EA;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  NavDropdown,
  Form
} from "react-bootstrap";
import { useHistory,Link } from "react-router-dom";
import { BASE_URL } from "apiUrl";

function TableList() {
  const [data,setdata]=useState([]);
  const [allData,setAllData] = useState([])
  const [deleted,setDeleted]=useState(false)
  const [pressDelete,setPressDelete]=useState(false)
  const [id,setId] = useState(null)
  const history = useHistory();
  
  useEffect(()=>{
    

    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
   
    fetch(`${BASE_URL}/api/employee-management/employees/`,{
      headers:{
          "Authorization" : "Bearer "+JWTToken
      }
      })
    .then((response) =>{
       if(response.status===200){
        response.json().then((data)=>{
          setdata(data)
          setAllData(data)
          console.log(setdata)
        })
       }
       else if(response.status===401){
         //alert('not authenticated')
         localStorage.clear()
          window.location.href = '/'
       }
       })


  },[deleted])


  function selectUser(id)
  {
    console.log(id);
    history.push(`/editEmployee/${id}`);
  }


  function deleteUser(id)
  {

        const token = localStorage.getItem('token')
        const JWTToken = JSON.parse(token)
        let item=id
        let url = `${BASE_URL}/api/employee-management/employee/${item}/`
        fetch(url,{
            method:"DELETE",
            headers:{
               
                "Authorization" : "Bearer "+JWTToken
            }
        }).then(response=>{
          if(response.status===200){
            
            popup.success("Employee deleted successfully");
            setDeleted(!deleted)
          }
          else if(response.status===400){
            popup.success("Employee not deleted successfully");
          }
        })


  }

  const resendCredentials = (empId)=>{
    const token = localStorage.getItem('token')
        const JWTToken = JSON.parse(token)
        let item=id
        let url = `${BASE_URL}/api/employee-management/resend-employee-credentials/${empId}/`
        fetch(url,{
            method:"GET",
            headers:{
               
                "Authorization" : "Bearer "+JWTToken
            }
        }).then(response=>{
          if(response.status===200){
            
            popup.success("Credentials resend successfully");
          }
          else if(response.status===400){
            response.json().then(data=>{
              console.log(data)
              popup.error(data.error.slice(0,73));
            })
          }
        })

  }

  console.log(data);
  
  const Employe = ({currentItems})=>{
    const navDropdownTitle = (<i className="fas fa-ellipsis-v"></i>);

    return <>
      {currentItems.map((item)=>
                  <tr>
                  <td>{item.first_name}</td>
                  <td>{item.last_name}</td>
                  <td>{item.cnic}</td>
                  <td>{item.position}</td>
                  <td>{item.address}</td>
                  <td>{item.city}</td>
                  <td>{item.provience}</td>
                  <td>{item.country}</td>
                  
                  <td>
                  <NavDropdown     title={navDropdownTitle}  id="basic-nav-dropdown">

                    {/* <NavDropdown.Item onClick={()=>{resendCredentials(item.id);setId(item.id)}}>Resend Credentials</NavDropdown.Item> */}
                    {/* <NavDropdown.Divider /> */}
                    <NavDropdown.Item onClick={()=>selectUser(item.id)}>Edit</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={()=>{setPressDelete(true);setId(item.id)}}>Delete</NavDropdown.Item>
                  
                    </NavDropdown>
                  </td>
                </tr>
                  )}
    </>
  }

  const PaginatEmployees = ({ itemsPerPage })=> {
    const [currentItems, setCurrentItems] = useState(data);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
  
    useEffect(() => {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(data.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);
  
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % data.length;
      setItemOffset(newOffset);
    };
  
    return (
      <>
        <div class="tableWrap">
        <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">First Name</th>
                      <th className="border-0">Last Name </th>
                      <th className="border-0">CNIC</th>
                      <th className="border-0">Postion</th>
                      <th className="border-0">Street Address</th>
                      <th className="border-0">City</th>
                      <th className="border-0">Provience</th>
                      <th className="border-0">Country</th>
                      <th className="border-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                  <Employe currentItems={currentItems} />
                  </tbody>
                </Table>
              </div>
        
        <MyPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      
      </>
    );
  }

  const searchEmployee = (e)=>{
    console.log(e.target.value)
    let query = e.target.value.trim()
    if(query!==""){
      let matchedBanks = data.filter((emp)=>emp.first_name.toUpperCase()===query.toUpperCase()||emp.last_name.toUpperCase()===query.toUpperCase()||emp.cnic===query)
      console.log(matchedBanks)
      if(matchedBanks.length>0){
        setdata(matchedBanks)
      }else {
        setdata(allData)
      }
    }
  }

  return (
    <>
    {
      pressDelete?
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={()=>{deleteUser(id);setPressDelete(false);setId(null)}}
        onCancel={()=>{setPressDelete(false);setId(null)}}
        focusCancelBtn
      >
        You will not be able to recover employee data!
      </SweetAlert>:""
    }
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">List of Employees</Card.Title>
                <div className="text-right">
                <Link to="/addEmployee"><Button className="btn btn-primary">Add Employee</Button></Link>
                </div>
                <br/>
                <div>
                <Form.Control
                          defaultValue=""
                          placeholder="Search Employee By First Name Or Last Name Or CNIC...🔍"
                          type="text"
                          onChange={(e)=>{searchEmployee(e)}}
                        >
                        
                </Form.Control>

                </div>
              
              </Card.Header>
              <Card.Body style={{overflowX:"hidden",overflowY:"auto"}} className="table-full-width table-responsive px-0">
                <PaginatEmployees itemsPerPage={15} />
              </Card.Body>
            </Card>
          </Col>
    
        </Row>
      </Container>
    </>
  );
}

export default TableList;
