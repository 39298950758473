import React,{useState,useEffect} from "react";
import { useHistory } from "react-router-dom";

import {
  Navbar,
  Nav,
  Container,
} 
from "react-bootstrap";

function ProjectManagementNavbar() {
    const [selectedMenu,setSelectedMenu] = useState('projects')
    const history = useHistory()
     
     return (
    
      <Navbar bg="light" className="custom-nav" expand="lg" style={{marginTop:"-10px"}}>
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link style={location.pathname==="/admin/projectManagement/projects"?{color:"#1DC7EA"}:{}} onClick={(e)=>{
              e.preventDefault()
              setSelectedMenu('projects')
              history.push('/admin/projectManagement/projects')
            }}>Projects</Nav.Link>
            <Nav.Link style={location.pathname==="/admin/projectManagement/milestones"?{color:"#1DC7EA"}:{}} onClick={(e)=>{
              e.preventDefault()
              setSelectedMenu('milestones')
              history.push('/admin/projectManagement/milestones')
            }}>Milestones</Nav.Link>
            <Nav.Link style={location.pathname==="/admin/projectManagement/tasks"?{color:"#1DC7EA"}:{}} onClick={(e)=>{
              e.preventDefault()
              setSelectedMenu('tasks')
              history.push('/admin/projectManagement/tasks')
            }}>Tasks</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
     
  )
}

export default ProjectManagementNavbar;