import React, { useState } from "react";
import { popup } from "utils/popup";
import axios from "axios";
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  ProgressBar
}
  from "react-bootstrap";
import Sidebar from "components/Sidebar/Sidebar";
import sidebarImage from "assets/img/sidebar-3.jpg";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import { Link } from "react-router-dom";
import toast from "../utils/toast";
import { useHistory } from "react-router-dom";
import routes from "routes.js";
import { BASE_URL } from "apiUrl";
import BankAccountDropdown from "./BankAccountDropdown";
import EmployeeAccountDropdown from "./EmployeeAccountDropdown";
import LockedContent from "components/LockedContent/LockedContent";
function PaySalar() {

  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const mainPanel = React.useRef(null);

  const [loaded, setLoaded] = useState(0)
  const [showLoading, setShowLoading] = useState(false)
  const [employees, setEmployees] = useState([])
  const [banks, setBanks] = useState([])
  const [employee, setEmployee] = useState(null)
  const [bank, setBank] = useState(null)
  const [dateTime, setDateTime] = useState(null)
  const [salary, setSalary] = useState(0)
  const [transactionCharges, setTransactionCharges] = useState(0)
  const [bonus, setBonus] = useState(0)
  const [allowance, setAllowance] = useState(0)
  const [taxDeduction, setTaxDeduction] = useState(0)
  const [description, setDescription] = useState("")
  const [sendSalarySlip, setSendSalarySlip] = useState(false)
  const [scheduleSalary, setScheduleSalary] = useState(false)
  const [scheduleDate, setScheduleDate] = useState(null)
  const [formErros, setFormErrors] = useState({})
  const [haveSubscription, setHaveSubscription] = useState(false)

  const [currentInput, setCurrentInput] = useState(1);
  const inputRefs = React.useRef([]);

  const history = useHistory()



  React.useEffect(() => {

    getAllEmployees()
    getAllBanks()

    let now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    setDateTime(now.toISOString().slice(0, 16))
    console.log(now)
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location, dateTime]);


  const formValidation = () => {
    console.log(bank)
    if (bank === null) {
      setFormErrors({
        ...formErros,
        bank: {
          error: true,
          message: "Please select bank account"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else {

      delete formErros.bank

    }


    console.log(employee)
    if (employee === null) {
      setFormErrors({
        ...formErros,
        employee: {
          error: true,
          message: "Please select employee "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else {

      delete formErros.employee

    }

    if (!salary) {
      setFormErrors({
        ...formErros,
        salary: {
          error: true,
          message: "Please fill the salary amount "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else if (salary.trim().length <= 0) {
      setFormErrors({
        ...formErros,
        salary: {
          error: true,
          message: "Please enter the amount "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else if (isNaN(salary)) {
      setFormErrors({
        ...formErros,
        salary: {
          error: true,
          message: "Only digits allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else {

      delete formErros.salary

    }

    if (!scheduleDate && scheduleSalary) {
      setFormErrors({
        ...formErros,
        scheduleDate: {
          error: true,
          message: "Please select the salary schedule date"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else {
      delete formErros.scheduleDate
    }

    return true

  }



  const getAllBanks = () => {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/bank-management/banks/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setBanks(data)

          })
        }
        else if (response.status === 401) {
          //alert('not authenticated')
          localStorage.clear()
          window.location.href = '/'
        }
      })
  }

  const getAllEmployees = () => {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/employee-management/employees/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          setHaveSubscription(response.headers.get('have-subscription') === "True" ? true : false)
          response.json().then((data) => {
            setEmployees(data)

          })
        }
        else if (response.status === 401) {
          //alert('not authenticated')
          localStorage.clear()
          window.location.href = '/'
        }
      })


  }

  const bankSelected = (e) => {
    console.log(e.target.value)
    banks.map(bank => {
      if (bank.account_no === e.target.value) {
        setBank(bank.id)
      } else {
        setBank(null)
      }
    })
  }

  const employeeSelected = (e) => {

    console.log(e.target.value)
    employees.map(emp => {
      if (emp.account_no === e.target.value) {
        setEmployee(emp.id)
        console.log(emp.id)
      } else {
        setEmployee(null)
      }
    })

  }

  const paySalary = async (e) => {
    e.preventDefault()
    if (formValidation()) {
      setShowLoading(true)
      if (bank !== "" && employee !== "" && salary !== 0) {
        console.log('bank id ' + bank)
        console.log('employee id ' + employee)
        console.log('salary ' + salary)

        const token = localStorage.getItem('token')
        const JWTToken = JSON.parse(token)
        const taxDeductionAmount = taxDeduction
        const rawDate = new Date(dateTime + 'Z')
        const body = JSON.stringify({
          bank_id: bank,
          employee_id: employee,
          salary_amount: salary,
          allowance: allowance,
          description: description,
          tax_deduction: taxDeductionAmount,
          send_salary_slip: sendSalarySlip,
          transaction_charges: transactionCharges,
          bonus: bonus,
          paid_at: rawDate.toISOString(),
          is_salary_schedule: scheduleSalary,
          schedule_date: scheduleDate,

        })

        const config = {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + JWTToken
          },
          onUploadProgress: (p) => {
            // console.log(Math.ceil((p.loaded/p.total)*100))
            // setLoaded(Math.ceil((p.loaded/p.total)*100))
            setLoaded(p.loaded)
          }
        }

        const url = `${BASE_URL}/api/salary-management/pay-salary/`


        try {
          const resp = await axios.post(url, body, config)
          console.log(resp)
          if (resp.status === 201) {

            popup.success("Salary paid successfully");
            const path = `/salaryInvoice/${resp.data.id}`
            history.push(path)

          }
          else if (resp.status === 400) {
            popup.success("Salary not successfully");
          }

        }
        catch (err) {
          console.log(err)
        }
        finally {
          setShowLoading(false)
        }
      }
    }
  }

  console.log(banks)


   const handleKeyDown = (event, index) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.current.length) {
        inputRefs.current[nextIndex].focus();
        setCurrentInput(nextIndex);
        return 
      }
      // here submit the form
      console.log('Submiting.....');
      paySalary(event);
      return 
    }else if (event.key === 'Backspace') {
      const currentValue = event.target.value;
      if (currentValue === '' && index > 0) {
        const prevIndex = index - 1;
        inputRefs.current[prevIndex].focus();
        setCurrentInput(prevIndex);
      }
     return 
    }
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar activeLink="/salary" color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Container fluid>
              <Row>

                <Col md="4" className="order-md-2">

                  {
                    employee ? <>

                      {
                        employees.map(emp => {
                          if (emp.id == employee) {
                            return <Card key={emp.id} className="card-user">
                              <div className="card-image">
                                <img
                                  alt="..."
                                  src={
                                    require("assets/img/photo-1431578500526-4d9613015464.jpeg")
                                      .default
                                  }
                                ></img>
                              </div>
                              <Card.Body>
                                <div className="author">
                                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <img
                                      alt="..."
                                      className="avatar border-gray"
                                      src={BASE_URL + emp.image}
                                    ></img>

                                  </a>
                                  <p style={{ fontSize: "15px", textAlign: "left" }}><b>Name :</b> {emp.first_name} {emp.last_name}</p>
                                  <p style={{ fontSize: "15px", textAlign: "left" }}><b>Email :</b> {emp.email}</p>
                                  <p style={{ fontSize: "15px", textAlign: "left" }}><b>Mobile No :</b> {emp.mobile_number}</p>

                                </div>


                              </Card.Body>

                            </Card>
                          }
                        })
                      }
                    </> : ""
                  }

                  {
                    bank ? <>

                      {
                        banks.map(b => {
                          if (b.id == bank) {
                            console.log(b.id, bank)
                            return <Card className="card-user">

                              <Card.Body>
                                <div >
                                  <p style={{ fontWeight: "600" }} className="text-center">{b.bank_name} Details</p>
                                  <hr />
                                  <p>Account # : {b.account_no}</p>
                                  <p>Branch : {b.branch_name}</p>
                                  <p>Available Ballance :  {b.total_ballance <= 0 ? <span style={{ color: "red", fontWeight: "600" }}>Out of Balance</span> : `${localStorage.getItem("currency")} ${b.total_ballance}`} </p>
                                </div>
                              </Card.Body>

                            </Card>

                          }
                        })
                      }
                    </> : ""
                  }

                  <Card className="card-user">

                    <Card.Body>
                      <div >
                        <p style={{ fontWeight: "600" }} className="text-center">Salary Calculator</p>
                        <hr />
                        <p>1.Basic Salary : {localStorage.getItem("currency")} {parseFloat(salary).toFixed(2)} </p>
                        <p>2.Allowance : {localStorage.getItem("currency")} {parseFloat(allowance).toFixed(2)}</p>
                        <p>3.Bonus : {localStorage.getItem("currency")} {parseFloat(bonus).toFixed(2)}</p>
                        <p>4.Tax Deduction : {localStorage.getItem("currency")} {parseFloat(taxDeduction).toFixed(2)} </p>
                        <hr />
                        <div style={{ float: "right" }}>
                          <p style={{ fontWeight: "600" }}>Total Salary : {localStorage.getItem("currency")} {parseFloat(parseFloat(salary) + parseFloat(allowance) + parseFloat(bonus)).toFixed(2)}</p>
                          <p style={{ fontWeight: "600" }}>Tax Deduction : {localStorage.getItem("currency")} {parseFloat(taxDeduction).toFixed(2)}</p>
                          <p style={{ fontWeight: "600" }}>Net Salary : {localStorage.getItem("currency")} {parseFloat(parseFloat(salary) + parseFloat(allowance) + parseFloat(bonus) - parseFloat(taxDeduction)).toFixed(2)}</p>
                        </div>

                      </div>
                    </Card.Body>

                  </Card>



                </Col>


                <Col md="8" className="order-md-1">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">Pay Salary</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={(e) => paySalary(e)}>




                        <Row>
                          <Col md="6">
                            {/* <Form.Group >
                        <label>Bank Account</label>
                        
                        <div >
                        <input placeholder="Select Bank Account" 
                        className={formErros.bank?"errors form-control":"form-control"}
                        type="text" list="banks"  onChange={(e)=>{bankSelected(e)}}  
                        />
                        <datalist id="banks" >
                          
                                {
                                    
                                    banks.map(bank=>{
                                        return <option value={bank.account_no} key={bank.id}>{bank.bank_name+" "+bank.branch_name}</option>
                                    })
                                }
                          </datalist> 
                        </div>
                        {formErros.bank?<p style={{color:"red"}}>*{formErros.bank.message}</p>:""}
                        
                      </Form.Group> */}
                            <div className="form-group">
                              <label>Bank Account</label>
                              <BankAccountDropdown 
                                banks={banks} 
                                setBank={setBank} 
                              />
                              {formErros.bank ? <p style={{ color: "red" }}>*{formErros.bank.message}</p> : ""}
                            </div>


                          </Col>
                          <Col md="6">
                            {/* <Form.Group >
                        <label>Employee Account</label>
                        <div>
                        <input placeholder="Select Employee Account" 
                        className={formErros.employee?"errors form-control":"form-control"}
                        type="text" list="employees"  onChange={(e)=>{employeeSelected(e)}} 
                        />

                    <datalist id="employees" >
                                {
                                    
                                    employees.map(emp=>{
                                        return <option value={emp.account_no} key={emp.id} > {emp.first_name} {emp.last_name}  </option>
                                    })
                                }
                      </datalist>    
                        </div>
                        {formErros.employee?<p style={{color:"red"}}>*{formErros.employee.message}</p>:""}
                        
                      </Form.Group> */}

                            <div className="form-group">
                              <label>Employee</label>
                              <EmployeeAccountDropdown 
                                employees={employees} 
                                setEmployee={setEmployee} 
                              />
                              {formErros.employee ? <p style={{ color: "red" }}>*{formErros.employee.message}</p> : ""}

                            </div>


                          </Col>

                        </Row>
                      
                        <Col sm="12" >
                          <Form.Group>
                            <label>Date & Time</label>
                            <Form.Control
                              ref={(el) => (inputRefs.current[0] = el)} 
                              defaultValue={dateTime}
                              className={formErros.dateTime ? "errors" : ""}
                              placeholder="Date & Time"
                              type="datetime-local"
                              onChange={(e) => setDateTime(e.target.value)}
                              required={true}
                              onKeyDown={(event) => handleKeyDown(event, 0)}
                            ></Form.Control>
                            {formErros.dateTime ? <p style={{ color: "red" }}>*{formErros.dateTime.message}</p> : ""}

                          </Form.Group>
                        </Col>

                        <Row>
                          <Col md="3">
                            <Form.Group>
                              <label>Salary Amount</label>

                              <Form.Control
                                ref={(el) => (inputRefs.current[1] = el)} 
                                className={formErros.salary ? "errors" : ""}
                                placeholder="Salary Amount"
                                type="text"
                                name="salary_amount"
                                onChange={(e) => setSalary(e.target.value)}
                                onKeyDown={(event) => handleKeyDown(event, 1)}
                              >

                              </Form.Control>
                              {formErros.salary ? <p style={{ color: "red" }}>*{formErros.salary.message}</p> : ""}

                            </Form.Group>
                          </Col>


                          <Col md="3">
                            <Form.Group>
                              <label>Bonus Amount</label>

                              <Form.Control
                                ref={(el) => (inputRefs.current[2] = el)} 
                                className={formErros.bonus ? "errors" : ""}
                                placeholder="Bonus Amount"
                                type="text"
                                name="bonus"
                                onChange={(e) => setBonus(e.target.value)}
                                onKeyDown={(event) => handleKeyDown(event, 2)}
                              >

                              </Form.Control>
                              {formErros.bonus ? <p style={{ color: "red" }}>*{formErros.bonus.message}</p> : ""}

                            </Form.Group>
                          </Col>

                          <Col md="3">
                            <Form.Group>
                              <label>Allowance (If any)</label>

                              <Form.Control
                                ref={(el) => (inputRefs.current[3] = el)} 
                                placeholder="Salary Amount"
                                type="text"
                                name="allowance"
                                onChange={(e) => setAllowance(e.target.value)}
                                onKeyDown={(event) => handleKeyDown(event, 3)}
                              >

                              </Form.Control>

                            </Form.Group>
                          </Col>


                          <Col md="3">
                            <Form.Group>
                              <label>Tax Deduction  (If any )</label>

                              <Form.Control
                                ref={(el) => (inputRefs.current[4] = el)} 
                                placeholder="Tax Deduction Amount"
                                type="text"
                                name="tax_deduction"
                                onChange={(e) => setTaxDeduction(e.target.value)}
                                onKeyDown={(event) => handleKeyDown(event, 4)}
                              >

                              </Form.Control>

                            </Form.Group>
                          </Col>

                        </Row>


                        <Col md="12">
                          <Form.Group>
                            <label>Description</label>
                            <Form.Control
                              ref={(el) => (inputRefs.current[5] = el)} 
                              cols="80"
                              placeholder="Here can be your description"
                              rows="4"
                              as="textarea"
                              name="description"
                              onChange={(e) => setDescription(e.target.value)}
                              onKeyDown={(event) => handleKeyDown(event, 5)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>


                        <Row>
                           <Col md="6">
                            <Form.Group>
                              <label>Transaction Charges</label>

                              <Form.Control
                                ref={(el) => (inputRefs.current[6] = el)} 
                                className={formErros.transactionCharges ? "errors" : ""}
                                placeholder="Transaction Charges"
                                type="text"
                                name="transactionCharges"
                                onChange={(e) => setTransactionCharges(e.target.value)}
                                onKeyDown={(event) => handleKeyDown(event, 6)}
                              >

                              </Form.Control>
                              {formErros.transactionCharges ? <p style={{ color: "red" }}>*{formErros.transactionCharges.message}</p> : ""}

                            </Form.Group>
                          </Col>
                          <Col md="6" style={{marginTop:'30px'}}>
                            {!haveSubscription && <LockedContent fontSize={12} top={'60%'} left={'50%'} />}
                            <Form.Group className={`  ${!haveSubscription && " blury-effect"} `}>
                              <label>Send Salary Slip</label>
                              <input 
                                className="mt-2 ml-3" 
                                type="checkbox"
                                name="send_salary_slip"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSendSalarySlip(true)
                                  } else {
                                    setSendSalarySlip(false)
                                  }
                                }} 
                                />

                            </Form.Group>
                          </Col>

                       
                        </Row>

                        <Col md="12">
                          {!haveSubscription && <LockedContent fontSize={12} top={'60%'} left={'50%'} />}

                          <Form.Group className={`  ${!haveSubscription && " blury-effect"} `}>
                            <label>Schedule Salary</label>
                            <input
                              className="mt-2 ml-3" 
                              type="checkbox"
                              name="is_schedule_salary"
                              onChange={(e) => {
                                e.target.checked ? setScheduleSalary(true) : setScheduleSalary(false)
                              }} 
                              />

                          </Form.Group>
                        </Col>

                        {
                          scheduleSalary ?
                            <Col sm="12" >
                              <Form.Group>
                                <label>Schedule Date</label>
                                <Form.Control
                                  className={formErros.scheduleDate ? "errors" : ""}
                                  placeholder="Date"
                                  type="date"
                                  onChange={(e) => setScheduleDate(e.target.value)}
                                ></Form.Control>
                                {formErros.scheduleDate ? <p style={{ color: "red" }}>*{formErros.scheduleDate.message}</p> : ""}

                              </Form.Group>
                            </Col>
                            :
                            <></>
                        }

                        <Row>


                        </Row>

                        <br></br>
                        {
                          showLoading ? <><ProgressBar animated variant="success" now={loaded} /><br></br></> : ""
                        }

                        <div className="text-right">
                          <Button
                            className="btn-fill pull-right"
                            type="submit"
                            variant="info"
                          >
                            Pay Salary
                          </Button>


                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>


              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </div>


    </>
  );
}

export default PaySalar;
