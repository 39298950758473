import { post } from 'jquery';
import { Link } from 'react-router-dom';
import { popup } from "utils/popup";
import "../assets/css/register.css"
import React, { useEffect, useState } from 'react';
import {
    Badge,
    Button,
    Card,
    Navbar,
    Image,
    Nav,
    Table,
    Container,
    Row,
    InputGroup,
    Form,
    Col,
    
  } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import toast from "../utils/toast";
import { BASE_URL } from 'apiUrl';
const register=()=>{
    
    const history=useHistory();
    const [data,setData]=useState({})
    const [emailSend,setEmailSend] = useState(false)
    const [activeUser,setActiveUser] = useState(false)
    const [alreadyExist,setAlreadyExsit] = useState(false)
    const [formErros,setFormErrors]=useState({})
    const [loading,setLoading] = useState(false)


    useEffect(()=>{
      if(localStorage.getItem('token'))
        {
            history.push("/admin/dashboard");
        }
    },[])
    
    const formValidation = ()=>{
        
      if(!data.first_name){
          setFormErrors({
            ...formErros,
            first_name:{
              error:true,
              message:"Please fill the first name"
            }
          })
          
          return false
      }
      
      
      else if(data.first_name.trim().length<3){
        setFormErrors({
          ...formErros,
          first_name:{
            error:true,
            message:"First name must be contain upto 3 characters"
          }
        })
        
        return false
      }
      else if(!isNaN(data.first_name)){
        setFormErrors({
          ...formErros,
          first_name:{
            error:true,
            message:"Only character allowed"
          }
        })
        
        return false
      }
      else{
       
        delete formErros.first_name
        
      }
      console.log(formErros)
      
      //last name validation
      if(!data.last_name){
        setFormErrors({
          ...formErros,
          last_name:{
            error:true,
            message:"Please fill the last name"
          }
        })
        
        return false
    }else if(data.last_name.trim().length<3){
      setFormErrors({
        ...formErros,
        last_name:{
          error:true,
          message:"Last name must be contain upto 3 characters"
        }
      })
      
      return false
    }
    else if(!isNaN(data.last_name)){
      setFormErrors({
        ...formErros,
        last_name:{
          error:true,
          message:"Only character allowed"
        }
      })
      
      return false
    }
    else{
      
      delete formErros.last_name
     
    }


    //validating for email


  if(!data.email){
    setFormErrors({
      ...formErros,
      email:{
        error:true,
        message:"Please fill the email"
      }
    })
    
    return false
}else if(!validateEmail(data.email)){
  setFormErrors({
    ...formErros,
    email:{
      error:true,
      message:"Please enter a valid email"
    }
  })
  
  return false
}else{
       
  delete formErros.email
  
}
    //validation for mobile number 

    if(!data.phone_no){
      setFormErrors({
        ...formErros,
        phone_no:{
          error:true,
          message:"Please fill the mobile number"
        }
      })
      
      return false
  }else if(!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(data.phone_no)){
    setFormErrors({
      ...formErros,
      phone_no:{
        error:true,
        message:"Please enter a valid mobile number e.g. +923001234567"
      }
    })
    
    return false
  }else{
       
    delete formErros.phone_no
    
  }


//validation for company name 



if((!data.company_name)||data.company_name.trim().length<=0){
  setFormErrors({
    ...formErros,
    company_name:{
      error:true,
      message:"Please fill company name"
    }
  })
  return false
}
else if(!isNaN(data.company_name)){
  setFormErrors({
    ...formErros,
    company_name:{
      error:true,
      message:"Only character allowed"
    }
  })
  return false
}
else{
         
  delete formErros.company_name
  
}


  //validation for password
  

  if(!data.password){
    setFormErrors({
      ...formErros,
      password:{
        error:true,
        message:"Please fill the password"
      }
    })
    
    return false
}else if(!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(data.password)){
  setFormErrors({
    ...formErros,
    password:{
      error:true,
      message:" Password must contains minimum eight characters, at least one letter , one number and one special character"
    }
  })
  console.log('not pass')
  
  return false
}else{
     
  delete formErros.password
  
}


  //validation for password 2
  

  if(!data.password2){
    setFormErrors({
      ...formErros,
      password2:{
        error:true,
        message:"Please confirm your password"
      }
    })
    
    return false
}else if(data.password!==data.password2){
  setFormErrors({
    ...formErros,
    password2:{
      error:true,
      message:"Your password not matched"
    }
  })
 
  return false
}else{
     
  delete formErros.password2
  
}



return true
}


    const validateEmail=(email)=> {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }


    function signUp(e)
    {
        
        e.preventDefault();
        
        if(formValidation()){
          setLoading(true)
          const body = JSON.stringify(data)
         const url = `${BASE_URL}/api/user/sign-up/`
         fetch(url,{
          method: 'POST',
          headers:{
              
              'Content-Type':'application/json',  
          },
            body:body
        }).then(response=>{
          
            response.json().then(data=>{
                
                if(data.created==true){
                    popup.success(data.message);
                    setEmailSend(data.email_send)
                    setActiveUser(data.active_user)
                    setAlreadyExsit(data.mail_already_exist)
                    //history.push('/login/')
                }
                else if (data.created==false){
                    console.log(data)
                    popup.error(data.message)
                    setEmailSend(data.email_send)
                    setActiveUser(data.active_user)
                    setAlreadyExsit(data.mail_already_exist)
                }
                setLoading(false)
            })
         })
        
        }
         
    
         
     }


    


    const changeData= (e)=>{
        
            setData({
                ...data,
                [e.target.name]:e.target.value
            })
        
    }

    const registerPage= (e)=>{
      e.preventDefault()
      history.push('/register/')
    }

    const loginPage= (e)=>{
      e.preventDefault()
      history.push('/login/')
    }

    return(
        <div className="container-fluid bod">

  <div className="container-fluid">
    <div className="container-fluid register">
    <div className="row">
      <div className="col-md-5 register-left">
        <img src={require("assets/img/office_hassle_512x512.png").default} alt="" />
        <h3>Welcome</h3>
        <p><b style={{fontWeight:"600"}}>OfficeHassle By ENTSPOS</b> <br/> &copy;OfficeHassle.com {new Date().getFullYear()}</p>
        <p></p>
        </div>
      <div className="col-md-7   register-right">
       {
        !emailSend?<><ul className="nav nav-tabs text-right nav-justified" id="myTab" role="tablist">
                            
        <li className="nav-item text-center mt-4">
        <span>Already have an account? </span>
        <Link  to="/login/" style={{color:"#0062cc"}}><b> LOGIN </b></Link>
        </li>
        
    </ul>
        <form onSubmit={(e)=>signUp(e)}>
        <div className="tab-content" id="myTabContent">

        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

        <h3 className="register-heading"><span style={{fontSize:"12px",fontWeight:"500"}}>OR</span><br/>Register</h3>
        <div className="row register-form text-center">
        <div className="row col-md-12">
        <div className="col-md-6">

        <div className="form-group">
        <input type="text" className={formErros.first_name?"errors form-control ":"form-control"} id="fname" name="first_name" placeholder="Enter your first name" onChange={(e)=>changeData(e)} />
        {formErros.first_name?<p className="text-left" style={{color:"red"}}>*{formErros.first_name.message}</p>:""}

        </div>


        </div>
        <div className="col-md-6">
        <div className="form-group ">
        <input type="text" className={formErros.last_name?"errors form-control ":"form-control"} id="lname" name="last_name" placeholder="Enter your last name" onChange={(e)=>changeData(e)} />
        {formErros.last_name?<p className="text-left" style={{color:"red"}}>*{formErros.last_name.message}</p>:""}

        </div>
        </div>





        </div>
        <div className="row col-md-12">


        <div className="form-group col-md-6">
        <input type="email" className={formErros.email?"errors form-control ":"form-control"}  name="email"  placeholder="Enter your email"  onChange={e=>{changeData(e)}} />
        {formErros.email?<p className="text-left" style={{color:"red"}}>*{formErros.email.message}</p>:""}

        </div>

        <div className="form-group col-md-6">
        <input type="text"  className="form-control" name="phone_no" placeholder="Your mobile number" onChange={e=>{changeData(e)}} />
        {formErros.phone_no?<p className="text-left" style={{color:"red"}}>*{formErros.phone_no.message}</p>:""}

        </div>


        </div>
        <div className="row col-md-12">

        <div className="form-group col-md-12">
        <input type="text"  className={formErros.company_name?"errors form-control ":"form-control"} name="company_name" placeholder="Enter your company name" onChange={e=>{changeData(e)}} />
        {formErros.company_name?<p className="text-left" style={{color:"red"}}>*{formErros.company_name.message}</p>:""}

        </div>

        </div>
        <div className="row col-md-12">

        <div className="form-group col-md-6">
        <input   className={formErros.password?"errors form-control ":"form-control"} type="password" id="ans" name="password" placeholder="Enter your password" onChange={e=>{changeData(e)}}/>
        {formErros.password?<p className="text-left" style={{color:"red"}}>*{formErros.password.message}</p>:""}

        </div>

        <div className="form-group col-md-6">
        <input   className={formErros.password2?"errors form-control ":"form-control"} type="password"  name="password2" placeholder="Confirm your password" onChange={e=>{changeData(e)}}/>
        {formErros.password2?<p className="text-left" style={{color:"red"}}>*{formErros.password2.message}</p>:""}

        </div>

        <div className="col-md-6">

        </div>  
        <div className="col-md-6">

        {
          loading?<div className="spinner-border text-info" role="status">
          <span className="visually-hidden"></span>
        </div>:<input type="submit" className="btnRegister" defaultValue="Register" />

        }
        


        </div>

        </div>

        </div>

        </div>

        </div>
        </form></>:<div className='mt-5'>
        <ul className="nav nav-tabs text-right nav-justified" id="myTab" role="tablist">
                            
                            <li className="nav-item text-center mt-4">
                            
                            <i className="fas fa-paper-plane" style={{fontSize:"80px",color:"#00c6ff"}}></i>
                            <p className="mt-2" style={{color:"black"}}>An email send to your provided email address.<br/> Please check your inbox for activate your account. If you not found email in inbox please check your spam folder.</p>
                            </li>
                            
        </ul>
           
        </div>
       }
      </div>
    </div>
  </div>
</div>

</div>
    )
}
export default register;