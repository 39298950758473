
import Dashboard from "views/Dashboard.js";
import UserProfile from "views/UserProfile.js";
import TableList from "views/TableList.js";
import salary from "views/Salary";
import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
import Maps from "views/Maps.js";
import Account from "views/Account";
import expense from "views/expense";
import Notifications from "views/Notifications.js";
import Upgrade from "views/Upgrade.js";
import subscription from "views/subscription";
import Clients from "views/Clients";
import Meetings from "views/Meetings";
import Settings from "views/settings";
import tax from "views/tax";
import Projects from "views/Projects";
import Milestones from "views/milestones";
import Tasks from "views/tasks";
import Invoices from "views/Invoices";
import Talent from "views/Talent";

const dashboardRoutes = [
  
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fas fa-chart-line",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "User Profile",
    icon: "nc-icon nc-circle-09",
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/table",
    name: "Employee Manage..",
    icon: "fas fa-user-friends",
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/projectManagement",
    name: "Project Management",
    icon: "fas fa-project-diagram",
    component: Projects,
    layout: "/admin",
  },
  {
    path: "/accessTalent",
    name: "Access Talent",
    icon: "fas fa-users",
    component: Talent,
    layout: "/admin",
  },
  {
    path: "/projectManagement/milestones",
    name: "Project Management",
    icon: "fas fa-project-diagram",
    component: Milestones,
    layout: "/admin",
  },
  {
    path: "/projectManagement/tasks",
    name: "Project Management",
    icon: "fas fa-project-diagram",
    component: Tasks,
    layout: "/admin",
  },
  
  {
    path: "/bank",
    name: "Bank Management",
    icon: "fas fa-university",
    component: Account,
    layout: "/admin",
  },
  {
    path: "/salary",
    name: "Salary Management",
    icon: "fas fa-coins",
    component: salary,
    layout: "/admin",
  },
  {
    path: "/tax",
    name: "Tax Management",
    icon: "fas fa-money-check",
    component: tax,
    layout: "/admin",
  },
  {
    path: "/expense",
    name: "Expense Management",
    icon: "fas fa-wallet",
    component: expense,
    layout: "/admin",
  },
  {
    path: "/clients",
    name: "Client Management",
    icon: "fas fa-handshake",
    component: Clients,
    layout: "/admin",
  },
  {
    path: "/invoices",
    name: "Invoice Management",
    icon: "fas fa-file-invoice-dollar",
    component: Invoices,
    layout: "/admin",
  },
  {
    path: "/meetings",
    name: "Meeting Management",
    icon: "fab fa-meetup",
    component: Meetings,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "fas fa-cogs",
    component: Settings,
    layout: "/admin",
  },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "fas fa-map-marker-alt",
  //   component: Maps,
  //   layout: "/admin",
  // },
  
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "fas fa-bell",
  //   component: Notifications,
  //   layout: "/admin",
  // },
  {
    path: "/subscription",
    name: "Subscription",
    icon: "fab fa-cc-stripe",
    component: subscription,
    layout: "/admin",
  },
  
];

export default dashboardRoutes;
