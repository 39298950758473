import React, { useEffect, useState } from "react";
import toast from "../utils/toast";
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import SweetAlert from 'react-bootstrap-sweetalert';
import { popup } from "utils/popup";
import moment from 'moment';

const MyPaginate = styled(ReactPaginate).attrs({

})`
  margin-bottom: 2rem;
  margin-top:30px;
  display:flex;
  justify-content: center;
  list-style-type: none;
  padding: 0 5rem;
  li a {
    margin: 4px;
    float:left;
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.selected a {
    background-color: #1DC7EA;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  InputGroup,
  Form,
  Col,
  NavDropdown
} from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { BASE_URL } from "apiUrl";
import GenerateSheetModel from "./GenerateSheetModel";
import LockedContent from "components/LockedContent/LockedContent";

function TableList() {
  const [data, setdata] = useState([]);
  const [allData, setAllData] = useState([])
  const [deleted, setDeleted] = useState(false)
  const [currency, setCurrency] = useState('')
  const [pressDelete, setPressDelete] = useState(false)
  const [openGenerateSheet, setOpenGenerateSheet] = useState(false)
  const [id, setId] = useState(null)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true)
  const [haveSubscription, setHaveSubscription] = useState(false)
  const history = useHistory();



  const getCurrency = () => {

    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/user/profile/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((resp) => {

            setCurrency(resp.currency)
            localStorage.setItem('currency', resp.currency)

          })
        }
        else if (response.status === 401) {
          localStorage.clear()
          window.location.href = '/'
        }
      })
  }


  useEffect(() => {

    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/salary-management/salary-history/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          setHaveSubscription(response.headers.get('have-subscription') === "True" ? true : false)
          response.json().then((data) => {
            setdata(data)
            setAllData(data)
            console.log(setdata)
          })
        }
        else if (response.status === 401) {
          //alert('not authenticated')
          localStorage.clear()
          window.location.href = '/'
        }
      })

    getCurrency()
  }, [deleted])

  function invoiceSalary(id) {
    console.log(id)
    history.push(`/salaryInvoice/${id}`);
  }

  function editSalary(id) {
    console.log(id)
    history.push(`/editSalary/${id}`);
  }

  function deleteSalary(id) {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/salary-management/payment-delete/${id}/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            popup.success('Salary deleted Successfully');
            setDeleted(!deleted)

          })
        }
        else if (response.status === 401) {
          //alert('not authenticated')
          localStorage.clear()
          window.location.href = '/'
        }
      })

  }

  const Salaries = ({ currentItems }) => {
    const navDropdownTitle = (<i className="fas fa-ellipsis-v"></i>);

    return <>
      {currentItems.map((item) =>
        <tr>
          <td>{item.paid_to.first_name} {item.paid_to.last_name}</td>
          <td>{item.paid_to.bank_name}</td>
          <td>{item.paid_to.account_no}</td>
          <td>{currency} {item.salary_amount}</td>
          <td>{item?.paid_at ? moment(item.paid_at.split('Z')[0]).format('MMMM DD, YYYY h:mm:ss A') : ''} </td>
          <td style={{ textAlign: "center" }}>{item.is_send_mail ? <i style={{ color: "green" }} class="fas fa-check-circle"></i> : <i style={{ color: "red" }} class="fas fa-times-circle"></i>}</td>
          <td>
            <NavDropdown title={navDropdownTitle} id="basic-nav-dropdown">

              <NavDropdown.Item onClick={() => invoiceSalary(item.id)}>Salary Slip</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => editSalary(item.id)}>Edit Salary</NavDropdown.Item>

              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => { setPressDelete(true); setId(item.id) }}>Delete</NavDropdown.Item>
            </NavDropdown>
          </td>


        </tr>
      )}

    </>
  }

  const PaginateSalary = ({ itemsPerPage }) => {
    const [currentItems, setCurrentItems] = useState(data);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(data.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % data.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <div class="tableWrap">
          <Table className="table-hover table-striped">
            <thead>
              <tr>
                <th className="border-0">Employee Name</th>
                <th className="border-0">Employee Bank </th>
                <th className="border-0">Employee Account No</th>
                <th className="border-0">Salary Amount</th>
                <th className="border-0">Date/Time</th>
                <th className="border-0">Slip Send</th>
                <th className="border-0"> Actions</th>

              </tr>
            </thead>
            <tbody>

              <Salaries currentItems={currentItems} />

            </tbody>
          </Table>
        </div>
        <MyPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />

      </>
    );
  }

  const searchSalary = (e) => {
    console.log(e.target.value)
    let query = e.target.value.trim()
    if (query !== "") {
      let matchedBanks = data.filter((sal) => sal.paid_to.first_name.toUpperCase() === query.toUpperCase() || sal.paid_to.last_name.toUpperCase() === query.toUpperCase() || sal.paid_to.account_no === query)
      console.log(matchedBanks)
      if (matchedBanks.length > 0) {
        setdata(matchedBanks)
      } else {
        setdata(allData)
      }
    }
  }

  return (
    <>
      {
        pressDelete ?
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            title="Are you sure?"
            onConfirm={() => { deleteSalary(id); setPressDelete(false); setId(null) }}
            onCancel={() => { setPressDelete(false); setId(null) }}
            focusCancelBtn
          >
            You will not be able to recover salary data!
          </SweetAlert> : ""
      }
      <Container fluid>
        <GenerateSheetModel handleShow={handleShow} show={show} handleClose={handleClose} />
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Paid Salaries</Card.Title>
                <div className="text-center" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "100%" }}>
                  <div style={{ position: "relative" }}>
                    {!haveSubscription && <LockedContent fontSize={10} top={'70%'} left={'50%'} width={"100%"} />}
                    <Link to="#" onClick={(e) => {
                      e.preventDefault()
                      handleShow()
                    }} className={`mr-3  ${!haveSubscription && " blury-effect"}`}><Button style={{ width: "100%" }} className="btn btn-success  ">Generate Salary Sheet</Button></Link>
                  </div>
                  <div style={{ position: "relative" }}>
                    {!haveSubscription && <LockedContent fontSize={10} top={'70%'} left={'45%'} width={"113%"} />}
                    <Link className={` ${!haveSubscription && " blury-effect"}   `} to="/paySalaries/" ><Button style={{ width: "100%" }} className="btn btn-warning ">Pay Salaries</Button></Link>
                  </div>
                  <Link className="ml-3" to="/paySalary/" ><Button style={{ width: "100%" }} className="btn btn-primary  ">Pay Salary</Button></Link>

                </div>
                <div>
                  <br />
                  <Form.Control
                    defaultValue=""
                    placeholder="Search Salary By Employee First Name Or Employee Last Name Or Employee Accout No...🔍"
                    type="text"
                    onChange={(e) => { searchSalary(e) }}
                  >

                  </Form.Control>

                </div>

              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <PaginateSalary itemsPerPage={15} />
              </Card.Body>
            </Card>
          </Col>

        </Row>

        <div className="clearfix"></div>
      </Container>
    </>
  );
}

export default TableList;
