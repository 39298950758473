import "../assets/css/register.css"
import { Link,useHistory } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { popup } from "utils/popup";
import toast from "utils/toast";
import { BASE_URL } from "apiUrl";
import axios from "axios";

const TwoFactorAuthentication = ()=>{
    const history = useHistory()
    const char1 = useRef(null)
    const char2 = useRef(null)
    const char3 = useRef(null)
    const char4 = useRef(null)
   
    const [values,setValues] = useState({
        val1 : null,
        val2 : null,
        val3 : null,
        val4 : null
    })

    const JWTToken = JSON.parse(localStorage.getItem('token'))
    const twoFactorAuth = JSON.parse(localStorage.getItem('two_factor'))
    const [otp,setOtp] = useState(null)
    
    useEffect(()=>{
        if(JWTToken===null||twoFactorAuth===null)
        {
            history.push("/login");
        }else{
            sendVerieficationMail()
        }
        if(values.val1 === null && char1.current) {char1.current.focus()}
    },[])

    const sendVerieficationMail = async ()=>{
            
            const config = {
                headers:{
                    "Content-Type": "application/json",
                    "Authorization" : "Bearer "+JWTToken
                }
            }

            const url = `${BASE_URL}/api/user/send-login-otp/`
            const body = JSON.stringify({})

            try{
                const resp = await axios.get(url,config)
                if (resp.status===200){
                    popup.success("OTP send successfully!");
                    setOtp(resp.data.otp)
                }
                else if (resp.status===400){
                    popup.error("For some reasons OTP not send successfully!");
                }
            }
            catch(err){
                console.log(err)
                popup.error("For some reasons OTP not send successfully! ");
            }
    }

    const updateValues = (e)=>{
        let v = {
            ...values,
            [e.target.name]: e.target.value===""?null:e.target.value
        }
        
        setValues(v)
        if(v.val1===null && char1.current) {char1.current.focus()}
        if (v.val1!==null && char2.current) {char2.current.focus()}
        if (v.val1!==null && v.val2!==null && char3.current) {char3.current.focus()}
        if (v.val1!==null && v.val2!==null && v.val3!==null && char4.current) {char4.current.focus()}

    }

    const veriefyOTP = ()=>{
        let enterOTP = values.val1+values.val2+values.val3+values.val4
        
        if(enterOTP==otp){
            localStorage.setItem("two_factor",JSON.stringify(false))
            popup.success("Login successfully")
            history.push("/admin/user/")
        }else{
            popup.error("OTP not matched!")
        }
    }
    
    return(
        <div className="container-fluid bod">

  <div className="container-fluid">
    <div className="container-fluid register">
    <div className="row">
      <div className="col-md-5 register-left">
        <img src={require("assets/img/office_hassle_512x512.png").default} alt="" />
        <h3>Welcome</h3>
        <p>Management System <br/> &copy; OfficeHassle.com {new Date().getFullYear()}</p>
        <p></p>
        </div>
      <div className="col-md-7   register-right">
       <div >
        <ul className="nav nav-tabs text-right nav-justified" id="myTab" role="tablist">
                            
                            <li className="nav-item text-center mt-4">
                            
                            <i className="fas fa-paper-plane" style={{fontSize:"80px",color:"#00c6ff"}}></i>
                            
                            
                            <p className="mt-4" style={{color:"black"}}>An email with OTP send to your provided mail {JSON.parse(localStorage.getItem("user_email"))} for two factor authentication. <br/>Enter OTP</p>
                            
                            <div>
                                <input name="val1" onChange={(e)=>{updateValues(e)}} ref={char1} maxLength="1" style={{height:"100px",width:"100px",fontSize:"50px",textAlign:"center",margin:"5px"}} type="text" />
                                <input name="val2" onChange={(e)=>{updateValues(e)}} ref={char2} maxLength="1" style={{height:"100px",width:"100px",fontSize:"50px",textAlign:"center",margin:"5px"}} type="text" />
                                <input name="val3" onChange={(e)=>{updateValues(e)}} ref={char3} maxLength="1" style={{height:"100px",width:"100px",fontSize:"50px",textAlign:"center",margin:"5px"}} type="text" />
                                <input name="val4" onChange={(e)=>{updateValues(e)}} ref={char4} maxLength="1" style={{height:"100px",width:"100px",fontSize:"50px",textAlign:"center",margin:"5px"}} type="text" />
                            </div>
                            <button type="button" onClick={veriefyOTP} className="btn btn-primary pr-4 pl-4 mt-4 mb-3">
                                Verify OTP
                            </button>
                            <br></br>
                            <span className="mb-3" style={{color:"#7A869A",cursor:"pointer"}} onClick={()=>{sendVerieficationMail()}}>Send OTP Again</span><br></br>
                            <Link onClick={(e)=>{
                                e.preventDefault()
                                localStorage.removeItem('token');
                                localStorage.removeItem('two_factor');
                                localStorage.removeItem('user_email');
                                history.push('/login')
                                }}  style={{color:"#0062cc"}}><b > LOGIN AGAIN</b></Link>
                            
                            </li>
                            
                            
        </ul>
           
        </div>
       
      </div>
    </div>
  </div>
</div>

</div>
    )
}
export default TwoFactorAuthentication