import React, { useEffect, useState } from "react";
import SweetAlert from 'react-bootstrap-sweetalert';
import { popup } from "utils/popup";
import toast from "../utils/toast";
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
const MyPaginate = styled(ReactPaginate).attrs({
  
})`
  margin-bottom: 2rem;
  margin-top:30px;
  display:flex;
  justify-content: center;
  list-style-type: none;
  padding: 0 5rem;
  li a {
    margin: 4px;
    float:left;
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.selected a {
    background-color: #1DC7EA;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  NavDropdown,
  Form
} from "react-bootstrap";
import { useHistory,Link } from "react-router-dom";
import { BASE_URL } from "apiUrl";
function Clients() {

  const [data,setData]=useState([]);
  const [allData,setAllData] = useState([])
  const [deleted,setDeleted]=useState(false)
  const [currency,setCurrency]=useState("")
  const [pressDelete,setPressDelete]=useState(false)
  const [id,setId] = useState(null)
  const history = useHistory();


  
  useEffect(()=>{
   getAllClients()
  },[deleted])


  const getAllClients = ()=>{
      
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
   
    fetch(`${BASE_URL}/api/client-management/clients/`,{
      headers:{
          "Authorization" : "Bearer "+JWTToken
      }
      })
    .then((response) =>{
       if(response.status===200){
        response.json().then((data)=>{
          setData(data)
          setAllData(data)
          console.log(setData)
        })
       }
       else if(response.status===401){
        // alert('not authenticated')
        localStorage.clear()
        window.location.href = '/'
       }
       })

       
  }

  const editClients=(id)=>{
    history.push(`/editClient/${id}/`)
  }

  const deleteClient = (id)=>{
    console.log(id)
    const token = localStorage.getItem('token')
        const JWTToken = JSON.parse(token)
        let item=id
        let url = `${BASE_URL}/api/client-management/client/${item}/`
        fetch(url,{
            method:"DELETE",
            headers:{
               
                "Authorization" : "Bearer "+JWTToken
            }
        }).then(response=>{
          if(response.status===200){
            
            popup.success("Client deleted successfully");
            setDeleted(!deleted)
          }
          else if(response.status===400){
            popup.error("Client not deleted successfully");
          }
          else if(response.status === 401){
            localStorage.clear()
            window.location.href = '/'
          }
        })
       
  }
  
  const recieveMoney = (id)=>{
    history.push(`/recievedMoney/${id}`)
  }

  const Clients = ({currentItems})=>{
    const navDropdownTitle = (<i className="fas fa-ellipsis-v"></i>);
    return <>
    {currentItems.map((item)=>
                  <tr>
                  <td style={{textTransform:"capitalize"}}>{item.first_name} {item.last_name}</td>
                  <td>{item.phone_no}</td>
                  <td>{item.email}</td>
                  <td>{item.cnic}</td>
                  
                  <NavDropdown title={navDropdownTitle}   id="basic-nav-dropdown">
                  
                    <NavDropdown.Item onClick={()=>editClients(item.id)}>Edit</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={()=>{setPressDelete(true);setId(item.id)}}>Delete</NavDropdown.Item>
                    <NavDropdown.Divider/>
                    <NavDropdown.Item onClick={()=>recieveMoney(item.id)}>Recieved Money</NavDropdown.Item>
                  </NavDropdown>
                  </tr>
                  )} 
                
      </>
  }
  const PaginateClients = ({ itemsPerPage })=> {
    const [currentItems, setCurrentItems] = useState(data);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
  
    useEffect(() => {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(data.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);
  
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % data.length;
      setItemOffset(newOffset);
    };
  
    return (
      <>
      <div class="tableWrap">
        <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">Full Name</th>
                      <th className="border-0">Mobile Number</th>
                      <th className="border-0">Email</th>
                      <th className="border-0">Client ID</th>
                      <th className="border-0">Actions</th>

                    </tr>
                  </thead>
                  <tbody>
                    <Clients currentItems={currentItems}/>
                  </tbody>
                </Table>
                </div>
        <MyPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      
      </>
    );
  }

  const searchClient = (e)=>{
    console.log(e.target.value)
    let query = e.target.value.trim()
    if(query!==""){
      let matched = data.filter((cl)=>cl.first_name.toUpperCase()+" "+cl.last_name.toUpperCase()===query.toUpperCase()||cl.cnic===query)
      console.log(matched)
      if(matched.length>0){
        setData(matched)
      }else {
        setData(allData)
      }
    }
  }
  
  return (
    <>
    {
      pressDelete?
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={()=>{deleteClient(id);setPressDelete(false);setId(null)}}
        onCancel={()=>{setPressDelete(false);setId(null)}}
        focusCancelBtn
      >
        You will not be able to recover client data!
      </SweetAlert>:""
  }
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">List of Clients</Card.Title>
                <div className="text-right"><Link to="/addClient/"><Button className="btn btn-primary">Add Client</Button></Link></div>
                <br/>
                <Form.Control
                          defaultValue=""
                          placeholder="Search Client Name Or Client ID...🔍"
                          type="text"
                          onChange={(e)=>{searchClient(e)}}
                        >
                        
                </Form.Control>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
              <PaginateClients itemsPerPage={5} />
              </Card.Body>
            </Card>
          </Col>
    
        </Row>
      </Container>
   
    </>
  );
}

export default Clients;
