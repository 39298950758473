import firebase from "./firebase";
import "firebase/messaging";

const messaging = firebase.messaging();

const publicKey = "BMHIlkC7J3IT3CRHmzt1mAiPR4bBj02p4ElNJhB2n5gHU5FQEIpxrs94Yh9NSzbF-1bVaFrijFbGP60FY35GhvQ";


export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
      console.log('hello')
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });