
import React, { Component } from "react";

import { Dropdown, Badge, Button, Form } from "react-bootstrap";

import sideBarImage1 from "assets/img/sidebar-1.jpg";
import sideBarImage2 from "assets/img/sidebar-2.jpg";
import sideBarImage3 from "assets/img/sidebar-3.jpg";
import sideBarImage4 from "assets/img/sidebar-4.jpg";
import { SketchPicker } from 'react-color';
import { useState } from "react";
import { useEffect } from "react";

function FixedPlugin({
  hasImage,
  setHasImage,
  color,
  setColor,
  image,
  setImage,
}) {
  console.log(color)
  const [openColorPicker, setOpenColorPicker] = useState(false)


  const handleImageSelected = (file) => {
    if (file?.name) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        localStorage.setItem('sidebarImage', JSON.stringify(reader.result))
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    } else {
      setImage(file)
      localStorage.setItem('sidebarImage', JSON.stringify(file))
    }
  };

  const handleColorSelected = (color) => {
    setColor(color)
    localStorage.setItem('sidebarColor', JSON.stringify(color))
  }

  return (
    <div className="fixed-plugin">
      <Dropdown>
        <Dropdown.Toggle
          id="dropdown-fixed-plugin"
          variant=""
          className="text-white border-0 opacity-100"
        >
          <i className="fas fa-cogs fa-2x mt-1"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <li className="adjustments-line d-flex align-items-center justify-content-between">
            <p>Background Image</p>
            <Form.Check
              type="switch"
              id="custom-switch-1-image"
              checked={hasImage}
              onChange={setHasImage}
            />
          </li>
          <li className="adjustments-line mt-3">
            <p>Filters</p>
            <div className="pull-right">
              <Badge
                variant="secondary"
                className={color === "#00000082" ? "active" : ""}
                onClick={() => handleColorSelected("#00000082")}
              ></Badge>
              <Badge
                variant="azure"
                className={color === "#F0FFFF82" ? "active" : ""}
                onClick={() => handleColorSelected("#F0FFFF82")}
              ></Badge>
              <Badge
                variant="green"
                className={color === "#00800082" ? "active" : ""}
                onClick={() => handleColorSelected("#00800082")}
              ></Badge>
              <Badge
                variant="orange"
                className={color === "#FFA50082" ? "active" : ""}
                onClick={() => handleColorSelected("#FFA50082")}
              ></Badge>
              <Badge
                variant="red"
                className={color === "#FF000082" ? "active" : ""}
                onClick={() => handleColorSelected("#FF000082")}
              ></Badge>
              <Badge
                variant="purple"
                className={color === "#80008082" ? "active" : ""}
                onClick={() => handleColorSelected("#80008082")}
              ></Badge>

              <span
                onClick={() => setOpenColorPicker(!openColorPicker)}
                style={{ cursor: "pointer", marginRight: "10px", marginLeft: "10px", backgroundColor: 'ff0000!important', color: "#007BFF", width: "40px", height: "40px", fontSize: '22px' }}
              >
                <i class="fas fa-eye-dropper"></i>
              </span>

            </div>


            {
              openColorPicker && <div style={{ position: "absolute", marginLeft: "17px", marginTop: "26px" }}>
                <SketchPicker
                  color={color}
                  onChangeComplete={(e) => { handleColorSelected(e.hex + '82') }} />
              </div>
            }

            <div className="clearfix"></div>
          </li>
          <li className="header-title">Sidebar Images</li>
          <li className={image === sideBarImage1 ? "active" : ""}>
            <a
              className="img-holder switch-trigger d-block"
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                handleImageSelected(sideBarImage1);
              }}
            >
              <img alt="..." src={sideBarImage1}></img>
            </a>
          </li>
          <li className={image === sideBarImage2 ? "active" : ""}>
            <a
              className="img-holder switch-trigger d-block"
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                handleImageSelected(sideBarImage2);
              }}
            >
              <img alt="..." src={sideBarImage2}></img>
            </a>
          </li>
          <li className={image === sideBarImage3 ? "active" : ""}>
            <a
              className="img-holder switch-trigger d-block"
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                handleImageSelected(sideBarImage3);
              }}
            >
              <img alt="..." src={sideBarImage3}></img>
            </a>
          </li>
          <li className={image === sideBarImage4 ? "active" : ""}>
            <a
              className="img-holder switch-trigger d-block"
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                handleImageSelected(sideBarImage4);
              }}
            >
              <img alt="..." src={sideBarImage4}></img>
            </a>
          </li>

          <li className="button-container mb-3">
            <input
              type="file"
              className="form-control"
              aria-label="Select Image"
              accept=".png, .jpeg, .jpg"
              onChange={(e) => { handleImageSelected(e.target.files[0]) }}
            />
          </li>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default FixedPlugin;
