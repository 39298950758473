import { useState,useEffect } from "react"
import "../assets/css/bank-account-dropdown.css"

const EditClientDropdown = ({clients,setClients, selectedClient})=>{
    
    const [data,setData]=useState([]);
    const [allData,setAllData] = useState([])
    const [openDropdown,setOpenDropdown] = useState(false)
    const [displayText,setDisplayText] = useState("Select Client")
    const [totalItems,setTotalItems] = useState(4)

    useEffect(()=>{
      if(clients.length>0){
        setData(clients.slice(0, totalItems))
      }
      setAllData(clients)
      setSelectClient(selectedClient)
    },[clients, selectedClient])

    console.log('selected client ==================', selectedClient)

    const setSelectClient = ()=>{
        let selectCl = clients.find(cl=>cl.id==selectedClient)
        console.log(clients)
        if(selectCl){
            let dispTxt = selectCl.first_name+" " + selectCl.last_name
            setDisplayText(dispTxt)
            setClients(selectCl.id)
            console.log('dispTxt==============', dispTxt)
        }
    }

    const clientSelect = (e)=>{
        let client = JSON.parse(e.target.value)
        let dispTxt = client.first_name+" "+client.last_name
        setDisplayText(dispTxt)
        setClients(client.id)
        setOpenDropdown(!openDropdown)
        
    }

    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }


    const onScroll = async (e) => {
      var maxScrollPosition = e.target.scrollHeight - e.target.clientHeight
      const currentScroll = e.target.scrollTop
      if(currentScroll===maxScrollPosition){
          let items = totalItems+4
          await sleep(1 * 1000)
          setData(allData.slice(0, items))
          setTotalItems(items)
      }
    };
    


      
      const searchClient = (e)=>{
        console.log(e.target.value)
        let query = e.target.value.trim()
        if(query!==""){
          let matched = allData.filter((cl)=>cl.first_name.toUpperCase()+" "+cl.last_name.toUpperCase()===query.toUpperCase()||cl.first_name.toUpperCase()===query.toUpperCase()||cl.last_name.toUpperCase()===query.toUpperCase())
          console.log(matched)
          if(matched.length>0){
            setData(matched)
          }else {
            setData(allData.slice(0, 4))
          }
        }else{

        }
      }
    
    return <>
    <div className="select_box_wrapper ">
  <div className="custom_select_box" onClick={()=>{setOpenDropdown(!openDropdown)}}>
    <div className="custom_select_box_selected_label">
      <label className="custom_select_box_selected_label_text" style={{cursor:"pointer"}}>{displayText}</label>
    </div>
    <div className="custom_select_box_dropdown_icon">
      <a className="custom_select_box_dropdown_icon_button"><i className="fas fa-angle-down"></i></a>
    </div>
  </div>
  {
    openDropdown?<div onScroll={onScroll} className="custom_select_dropdown">
    <ul className="custom_select_dropdown_list_wrapper">
      <li className="custom_select_dropdown_list_search">
          <input  className="custom_select_dropdown_list_search_input" placeholder="Search Client" 
          onChange={(e)=>{searchClient(e)}}
          type="text" />
      </li>
      {data.map((item)=>{
                    
                    return <li key={item.id} className="custom_select_dropdown_list">
                      <input className="custom_select_dropdown_list_radio" type="radio" value={JSON.stringify(item)} onChange={(e)=>{clientSelect(e)}} /><span style={{textTransform:"capitalize!important"}} className="custom_select_dropdown_list_label">{item.first_name} {item.last_name} </span>
                    </li>}
      )} 
      </ul>
    </div>:null
  }
</div>

    </>
}
export default EditClientDropdown