import React, { useState, useEffect } from "react";
import { popup } from "utils/popup";
import SweetAlert from 'react-bootstrap-sweetalert';
import { BASE_URL } from "apiUrl";
import { useHistory } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import moment from "moment";
const MyPaginate = styled(ReactPaginate).attrs({

})`
  margin-bottom: 2rem;
  margin-top:30px;
  display:flex;
  justify-content: center;
  list-style-type: none;
  padding: 0 5rem;
  li a {
    margin: 4px;
    float:left;
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.selected a {
    background-color: #1DC7EA;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;


import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table,
  NavDropdown
}
  from "react-bootstrap";
import Sidebar from "components/Sidebar/Sidebar";
import sidebarImage from "assets/img/sidebar-3.jpg";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import { Link } from "react-router-dom";
import toast from "../utils/toast";

import routes from "routes.js";
import SendInvoiceModal from "./SendInvoiceModal";

function Invoices() {

  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const [data, setdata] = useState([])
  const [allData, setAllData] = useState([])
  const [deleted, setDeleted] = useState(false)
  const [currency, setCurrency] = useState('')
  const mainPanel = React.useRef(null);
  const [pressDelete, setPressDelete] = useState(false)
  const [id, setId] = useState(null)
  const [sendInvoice, setSendInvoice] = useState(false)
  const history = useHistory()


  function deleteInvoice(id) {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
    let item = id
    let url = `${BASE_URL}/api/invoice-management/invoice/${item}/`
    fetch(url, {
      method: "DELETE",
      headers: {

        "Authorization": "Bearer " + JWTToken
      }
    }).then(response => {
      if (response.status === 200) {

        popup.success("Invoice deleted successfully");
        setDeleted(!deleted)
      }
      else if (response.status === 400) {
        popup.success("Invoice not deleted successfully");
      }
    })

  }


  const getCurrency = () => {

    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/user/profile/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((resp) => {

            setCurrency(resp.currency)

          })
        }
        else if (response.status === 401) {
          localStorage.clear()
          window.location.href = '/'
        }
      })
  }



  React.useEffect(() => {

    getAllInvoices()
    getCurrency()


  }, [deleted]);



  const getAllInvoices = () => {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/invoice-management/invoices/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setdata(data)
            setAllData(data)
            console.log(data)

          })
        }
        else if (response.status === 401) {
          //alert('not authenticated')
          localStorage.clear()
          window.location.href = '/'
        }
      })
  }

  function editInvoice(id) {
    console.log(id);
    history.push(`/editInvoice/${id}`);
  }

  const Invoices = ({ currentItems }) => {
    const navDropdownTitle = (<i className="fas fa-ellipsis-v"></i>);

    return <>
      {currentItems.map((item) =>
        <tr>
          <td style={{ textTransform: "capitalize" }}>{item.number} </td>

          <td>{item?.client?.first_name} {item?.client?.last_name}</td>
          <td >{item?.project?.title ? item?.project?.title : item.custom_project}</td>
          <td>{currency} {item.total_amount}</td>
          <td>{item?.due_date ? moment(item.due_date).format('MMMM DD, YYYY') : ''}</td>

          <NavDropdown title={navDropdownTitle} id="basic-nav-dropdown">

            <NavDropdown.Item onClick={() => editInvoice(item.id)}>Edit</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={() => { setPressDelete(true); setId(item.id) }}>Delete</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={() => { setSendInvoice(true); setId(item.id) }}>Send Invoice</NavDropdown.Item>
            <NavDropdown.Divider />
          </NavDropdown>
        </tr>
      )}

    </>
  }

  const PaginateExpense = ({ itemsPerPage }) => {
    const [currentItems, setCurrentItems] = useState(data);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(data.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % data.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <div class="tableWrap">
          <Table className="table-hover table-striped">
            <thead>
              <tr>
                <th className="border-0">Invoice Number</th>
                <th className="border-0">Client</th>
                <th className="border-0">Project</th>
                <th className="border-0">Total Amount</th>
                <th className="border-0">Due Date</th>
                <th className="border-0">Actions</th>
              </tr>
            </thead>
            <tbody>

              <Invoices currentItems={currentItems} />

            </tbody>
          </Table>
        </div>
        <MyPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />

      </>
    );
  }

  const searchInvoice = (e) => {
    console.log(e.target.value)
    let query = e.target.value.trim()
    if (query !== "") {
      let matched = data.filter((exp) => exp.number === query)
      console.log(matched)
      if (matched.length > 0) {
        setdata(matched)
      } else {
        setdata(allData)
      }
    }
  }

  return (
    <>
      {
        pressDelete ?
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            title="Are you sure?"
            onConfirm={() => { deleteInvoice(id); setPressDelete(false); setId(null) }}
            onCancel={() => { setPressDelete(false); setId(null) }}
            focusCancelBtn
          >
            You will not be able to recover expense data!
          </SweetAlert> : ""
      }
      {
        sendInvoice ? <SendInvoiceModal show={true} handleClose = {()=> setSendInvoice(false)} handleShow={()=> setSendInvoice(false)} invoiceId = {id}/> : <></>
      }
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">List of Invoices</Card.Title>
                <div className="text-right"><Link to="/addInvoice"><Button className="btn btn-primary">Add Invoice</Button></Link></div>
                <br />
                <Form.Control
                  defaultValue=""
                  placeholder="Search Invoice By Number...🔍"
                  type="text"
                  onChange={(e) => { searchInvoice(e) }}
                >

                </Form.Control>

              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <PaginateExpense itemsPerPage={15} />
              </Card.Body>
            </Card>
          </Col>

        </Row>
      </Container>

    </>

  )
}

export default Invoices;
