import React, { useState } from "react";
import axios from "axios";
import { popup } from "utils/popup";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col
}
  from "react-bootstrap";
import Sidebar from "components/Sidebar/Sidebar";
import sidebarImage from "assets/img/sidebar-3.jpg";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import { Link } from "react-router-dom";
import toast from "../utils/toast";
import { useHistory, useParams } from "react-router-dom";
import routes from "routes.js";
import { BASE_URL } from "apiUrl";
import EditBankAccountDropdown from "./EditBankAccountDropdown";
import EditEmployeeAccountDropdown from "./EditEmployeeAccountDropdown";
function EditSalary() {

  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const mainPanel = React.useRef(null);


  const [employees, setEmployees] = useState([])
  const [banks, setBanks] = useState([])
  const [employee, setEmployee] = useState(null)
  const [bank, setBank] = useState("")
  const [dateTime, setDateTime] = useState(null)
  const [salary, setSalary] = useState(0)
  const [allowance, setAllowance] = useState(0)
  const [taxDeduction, setTaxDeduction] = useState(0)
  const [transactionCharges, setTransactionCharges] = useState(0)
  const [bonus, setBonus] = useState(0)
  const [description, setDescription] = useState("")
  const [scheduleSalary, setScheduleSalary] = useState(false)
  const [scheduleDate, setScheduleDate] = useState(null)
  const [formErros, setFormErrors] = useState({})
  
  const [currentInput, setCurrentInput] = useState(1);
  const inputRefs = React.useRef([]);

  const history = useHistory()

  let { id } = useParams();


  React.useEffect(() => {

    getAllEmployees()
    getAllBanks()
    getSalaryDetails()
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);


  const formValidation = () => {


    if (!salary) {
      setFormErrors({
        ...formErros,
        salary: {
          error: true,
          message: "Please fill the salary amount "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else if (salary.length <= 0) {
      setFormErrors({
        ...formErros,
        salary: {
          error: true,
          message: "Please enter the amount "
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }

    else if (isNaN(salary)) {
      setFormErrors({
        ...formErros,
        salary: {
          error: true,
          message: "Only digits allowed"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else {

      delete formErros.salary

    }
    if (!scheduleDate && scheduleSalary) {
      setFormErrors({
        ...formErros,
        scheduleDate: {
          error: true,
          message: "Please select the salary schedule date"
        }
      })
      popup.error("Form not submited! Please fill all required fields")
      return false
    }
    else {
      delete formErros.scheduleDate
    }

    return true

  }



  const getAllBanks = () => {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/bank-management/banks/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setBanks(data)

          })
        }
        else if (response.status === 401) {
          //alert('not authenticated')
          localStorage.clear()
          window.location.href = '/'
        }
      })
  }

  const getAllEmployees = () => {
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/employee-management/employees/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setEmployees(data)

          })
        }
        else if (response.status === 401) {
          //alert('not authenticated')
          localStorage.clear()
          window.location.href = '/'
        }
      })


  }

  const bankSelected = (e) => {
    setBank(e.target.value)

  }

  const employeeSelected = (e) => {
    setEmployee(e.target.value)

  }
  const getSalaryDetails = () => {
    let token = localStorage.getItem('token')
    let JWTToken = JSON.parse(token)

    fetch(`${BASE_URL}/api/salary-management/payment-details/${id}/`, {
      headers: {
        "Authorization": "Bearer " + JWTToken
      }
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            console.log(data)
            setEmployee(data.paid_to.id)
            setBank(data.sender_bank_details.id)
            let date = new Date(data.paid_at)
            date = date.toISOString().split(".")[0].slice(0, -3)
            setDateTime(date)
            setSalary(data.salary_amount)
            console.log(data.salary_amount)
            setAllowance(data.allowance)
            setTaxDeduction(data.tax_deduction)
            setDescription(data.description)
            setTransactionCharges(data.transaction_charges)
            setBonus(data.bonus)
            setScheduleSalary(data.schedule_salary)
            if (data.schedule_salary) {
              let schdate = new Date(data.schedule_salary.pay_at)
              schdate = schdate.toISOString().substring(0, 10)
              setScheduleDate(schdate)
            }
          })
        }
        else if (response.status === 401) {
          //alert('not authenticated')
          localStorage.clear()
          window.location.href = '/'
        }
      })

  }


  const editSalary = async (e) => {
    e.preventDefault()
    if (formValidation()) {
      if (bank !== "" && employee !== "" && salary !== 0) {
        console.log('bank id ' + bank)
        console.log('employee id ' + employee)
        console.log('salary ' + salary)

        const token = localStorage.getItem('token')
        const JWTToken = JSON.parse(token)
        const taxDeductionAmount = taxDeduction
        const rawDate = new Date(dateTime + 'Z')
        const body = JSON.stringify({
          bank_id: bank,
          employee_id: employee,
          salary_amount: salary,
          allowance: allowance,
          description: description,
          tax_deduction: taxDeductionAmount,
          transaction_charges: transactionCharges,
          bonus: bonus,
          is_salary_schedule: scheduleSalary,
          schedule_date: scheduleDate,
          paid_at: rawDate.toISOString(),
        })

        const config = {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + JWTToken
          }
        }

        const url = `${BASE_URL}/api/salary-management/update-salary/${id}/`


        try {
          const resp = await axios.put(url, body, config)
          console.log(resp)
          if (resp.status === 200) {

            popup.success("Salary updated successfully");
            const path = `/salaryInvoice/${resp.data.id}`
            history.push(path)

          }
          else if (resp.status === 400) {
            popup.success("Salary not updated successfully");
          }

        }
        catch (err) {
          console.log(err)
        }

      }
    }
  }

  console.log(banks)

   const handleKeyDown = (event, index) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.current.length) {
        inputRefs.current[nextIndex].focus();
        setCurrentInput(nextIndex);
        return 
      }
      // here submit the form
      console.log('Submiting.....');
      editSalary(event);
      return 
    }else if (event.key === 'Backspace') {
      const currentValue = event.target.value;
      if (currentValue === '' && index > 0) {
        const prevIndex = index - 1;
        inputRefs.current[prevIndex].focus();
        setCurrentInput(prevIndex);
      }
     return 
    }
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar activeLink="/salary" color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Container fluid>
              <Row>



                {
                  employee ? <Col md="4" className="order-md-2">

                    {
                      employees.map(emp => {
                        if (emp.id == employee) {
                          return <Card className="card-user">
                            <div className="card-image">
                              <img
                                alt="..."
                                src={
                                  require("assets/img/photo-1431578500526-4d9613015464.jpeg")
                                    .default
                                }
                              ></img>
                            </div>
                            <Card.Body>
                              <div className="author">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                  <img
                                    alt="..."
                                    className="avatar border-gray"
                                    src={BASE_URL + emp.image}
                                  ></img>

                                </a>
                                <p style={{ fontSize: "15px" }}><b>Name :</b><br />{emp.first_name} {emp.last_name}</p>
                                <p style={{ fontSize: "15px" }}><b>Email :</b><br />{emp.email}</p>
                                <p style={{ fontSize: "15px" }}><b>Mobile No :</b><br />{emp.mobile_number}</p>

                              </div>


                            </Card.Body>

                          </Card>
                        }
                      })
                    }
                  </Col> : ""
                }



                <Col md="8" className="order-md-1">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">Edit Salary</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={(e) => editSalary(e)}>




                        <Row>
                          <Col md="6">
                            {/* <Form.Group >
                        <label>Bank Account</label>
                        <Form.Control
                            required
                            name="bank"
                            as="select"
                            onChange={(e)=>{bankSelected(e)}}          
                            
                        >
                        <option value="" selected>Select Bank</option>
                            
                                {
                                    
                                    banks.map(b=>{
                                        return <option value={b.id} selected={b.id===bank} key={b.id}>{b.bank_name} {b.branch_name} Acc No ({b.account_no})</option>
                                    })
                                }
                            
                        </Form.Control>
                      </Form.Group> */}
                            <div className="form-group">
                              <label>Bank Account</label>
                              <EditBankAccountDropdown banks={banks} setBank={setBank} selectedBank={bank} />
                            </div>
                          </Col>

                        <Col md="6">
                            {/* <Form.Group >
                        <label>Employee</label>
                        <Form.Control
                            required
                            name="employee"
                            as="select"
                            onChange={(e)=>{employeeSelected(e)}}          
                            
                        >
                        <option value="" selected>Select Employee</option>
                            
                                {
                                    
                                    employees.map(emp=>{
                                       
                                        return <option value={emp.id} selected={emp.id===employee} key={emp.id}>{emp.first_name} {emp.last_name}  Acc No ({emp.account_no})</option>
                                    })
                                }
                            
                        </Form.Control>
                      </Form.Group> */}
                            <div className="form-group">
                              <label>Employee</label>
                              <EditEmployeeAccountDropdown employees={employees} setEmployee={setEmployee} selectedEmp={employee} />

                            </div>

                          </Col>



                        </Row>
                      
                        <Col sm="12" >
                          <Form.Group>
                            <label>Date & Time</label>
                            <Form.Control
                              ref={(el) => (inputRefs.current[0] = el)}
                              value={dateTime}
                              placeholder="Date & Time"
                              type="datetime-local"
                              onChange={(e) => setDateTime(e.target.value)}
                              required={true}
                              onKeyDown={(event) => handleKeyDown(event, 0)}
                            ></Form.Control>

                          </Form.Group>
                        </Col>

                        <Row>
                          <Col md="3">
                          <Form.Group>
                            <label>Salary Amount</label>

                            <Form.Control
                              ref={(el) => (inputRefs.current[1] = el)}
                              className={formErros.salary ? "errors" : ""}
                              value={salary}
                              placeholder="Salary Amount"
                              type="text"
                              name="salary_amount"
                              onChange={(e) => setSalary(e.target.value)}
                              onKeyDown={(event) => handleKeyDown(event, 1)}
                            >

                            </Form.Control>
                            {formErros.salary ? <p style={{ color: "red" }}>*{formErros.salary.message}</p> : ""}

                          </Form.Group>
                        </Col>

                        <Col md="3">
                          <Form.Group>
                            <label>Bonus Amount</label>

                            <Form.Control
                              ref={(el) => (inputRefs.current[2] = el)}
                              className={formErros.bonus ? "errors" : ""}
                              value={bonus}
                              placeholder="Bonus Amount"
                              type="text"
                              name="bonus"
                              onChange={(e) => setBonus(e.target.value)}
                              onKeyDown={(event) => handleKeyDown(event, 2)}
                            >

                            </Form.Control>
                            {formErros.bonus ? <p style={{ color: "red" }}>*{formErros.bonus.message}</p> : ""}

                          </Form.Group>
                        </Col>



                        <Col md="3">
                          <Form.Group>
                            <label>Allowance (If any)</label>

                            <Form.Control
                              ref={(el) => (inputRefs.current[3] = el)}
                              value={allowance}
                              placeholder="Allowance"
                              type="text"
                              name="allowance"
                              onChange={(e) => setAllowance(e.target.value)}
                              onKeyDown={(event) => handleKeyDown(event, 3)}
                            >

                            </Form.Control>

                          </Form.Group>
                        </Col>


                        <Col md="3">
                          <Form.Group>
                            <label>Tax Deduction (If any )</label>

                            <Form.Control
                              ref={(el) => (inputRefs.current[4] = el)}
                              value={taxDeduction}
                              placeholder="Tax Deduction Amount"
                              type="text"
                              name="tax_deduction"
                              onChange={(e) => setTaxDeduction(e.target.value)}
                              onKeyDown={(event) => handleKeyDown(event, 4)}
                            >

                            </Form.Control>

                          </Form.Group>
                        </Col>
                        </Row>

                        <Col md="12">
                          <Form.Group>
                            <label>Description</label>
                            <Form.Control
                              ref={(el) => (inputRefs.current[5] = el)}
                              cols="80"
                              defaultValue={description}
                              placeholder="Here can be your description"
                              rows="4"
                              as="textarea"
                              name="description"
                              onChange={(e) => setDescription(e.target.value)}
                              onKeyDown={(event) => handleKeyDown(event, 5)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col md="6">
                          <Form.Group>
                            <label>Transaction Charges</label>

                            <Form.Control
                              ref={(el) => (inputRefs.current[6] = el)}
                              className={formErros.transactionCharges ? "errors" : ""}
                              value={transactionCharges}
                              placeholder="Transaction Charges"
                              type="text"
                              name="transactionCharges"
                              onChange={(e) => setTransactionCharges(e.target.value)}
                              onKeyDown={(event) => handleKeyDown(event, 6)}
                            >

                            </Form.Control>
                            {formErros.transactionCharges ? <p style={{ color: "red" }}>*{formErros.transactionCharges.message}</p> : ""}

                          </Form.Group>
                        </Col>

                        <Col md="12">
                          <Form.Group>
                            <label>Schedule Salary</label>
                            <input className="mt-2 ml-3" type="checkbox"
                              checked={scheduleSalary}
                              name="is_schedule_salary"
                              onChange={(e) => {
                                e.target.checked ? setScheduleSalary(true) : setScheduleSalary(false)
                              }} />

                          </Form.Group>
                        </Col>

                        {
                          scheduleSalary ?
                            <Col sm="12" >
                              <Form.Group>
                                <label>Schedule Date</label>
                                <Form.Control
                                  defaultValue={scheduleDate}
                                  className={formErros.scheduleDate ? "errors" : ""}
                                  placeholder="Date"
                                  type="date"
                                  onChange={(e) => setScheduleDate(e.target.value)}
                                ></Form.Control>
                                {formErros.scheduleDate ? <p style={{ color: "red" }}>*{formErros.scheduleDate.message}</p> : ""}

                              </Form.Group>
                            </Col>
                            :
                            <></>
                        }


                        <Row>


                        </Row>

                        <br />
                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                        >
                          Edit Salary
                        </Button>
                        <div className="clearfix"></div>

                      </Form>
                    </Card.Body>
                  </Card>
                </Col>


              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </div>


    </>
  );
}

export default EditSalary;
