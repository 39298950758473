
import React, { Component, useEffect, useState } from "react";
import { Link, useHistory,useLocation } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import routes from "routes.js";
import firebase from "../../firebase"
import { onMessageListener } from "firebaseNotifications";
import axios from "axios";
import { toast } from "react-toast-notification";
import {NotificationContainer, NotificationManager} from 'react-notifications';


import "firebase/messaging";
import { BASE_URL } from "apiUrl";


function Header() {
   const [notifications,setNotifications]=useState([])

   
   const getAllNotifications = ()=>{
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
   
    fetch(`${BASE_URL}/api/notification-management/notifications/`,{
      headers:{
          "Authorization" : "Bearer "+JWTToken
      }
      })
    .then((response) =>{
       if(response.status===200){
        response.json().then((data)=>{
          setNotifications(data)
          console.log(data)
        })
       }
       else if(response.status===400){
         //alert('not authenticated')
       }
       })

   }
   

  const getNotificationToken = async ()=>{
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
    try{
    const resp =await fetch(`${BASE_URL}/api/notification-tokens/notification-token/`,{
      headers:{
          "Authorization" : "Bearer "+JWTToken
      }
      })
      const response = await resp.json()
      
      if(response.token){
        return response.token
      }
      else{
        return false
      }
    }
    catch(err){
      console.log(err)
    }
   
  }
  
  
  const setNotificationToken=async ()=>{
    const publicKey = "BMHIlkC7J3IT3CRHmzt1mAiPR4bBj02p4ElNJhB2n5gHU5FQEIpxrs94Yh9NSzbF-1bVaFrijFbGP60FY35GhvQ";

    const messaging = firebase.messaging()
    try{
    const token = await messaging.getToken({ vapidKey: publicKey })
    console.log(token)
    getNotificationToken().then(tkn=>{
      if(tkn!=token){


        const tokenjwt = localStorage.getItem('token')
           const JWTToken = JSON.parse(tokenjwt)
            const item={
              token:token
            }
            const body = JSON.stringify(item)
           
            const url =  `${BASE_URL}/api/notification-tokens/add-notification-token/`
            fetch(url,{
             method: 'POST',
             headers:{
                 "Authorization" : "Bearer "+JWTToken,     
                 'Content-Type':'application/json',  
             },
               body:body
           }).then(response=>{
             
               if(response.status===200){
                console.log('token added succesffuly')
               
  
               }
               else if (response.status===400){
                console.log("token not added successfully");
               }
            })
      
  
  
      }
      else{
        console.log('already added')
      }
      
    })
    
         

        
    
    }
    catch(err){
      console.log(err)
    }
    
  }

  useEffect(()=>{setNotificationToken();getAllNotifications();},[])
  
  const location = useLocation();
  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };
  const history=useHistory();
 function logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('two_factor');
  localStorage.removeItem('user_email');
  localStorage.removeItem('user');
  document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
  history.push('/login')
}
const userProfile=(e)=>{
  e.preventDefault()
  const user = JSON.parse(localStorage.getItem("user"))
  if(user.role==="admin"){
    history.push('/admin/user/')
  }else{
    history.push('/employee/profile/')
  }
 
}
  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "";
  };

  
    const messaging = firebase.messaging()
    messaging.onMessage((payload)=>{
    
      
      NotificationManager.warning( '', payload.notification.title, 3000);
      getAllNotifications()
    })


    
    const gotoNotificationPage= (e)=>{
      e.preventDefault()
      const user = JSON.parse(localStorage.getItem("user"))
      if(user.role==="admin"){
        history.push('/admin/notifications/')
      }else{
        history.push('/employee/notifications/')
      }
    }



  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
          
        <Button
            variant="dark"
            style={{backgroundColor:'white',color:'rgba(0,0,0,.5)',borderColor:'rgba(0,0,0,.5)'}}
            className="d-lg-none btn-fill d-flex justify-content-center align-items-center  p-2"
            onClick={mobileSidebarToggle}
          >
           <i className="fas fa-bars"></i>
            
          </Button>
      {/* <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
        </Navbar.Toggle> */}

          <Navbar.Brand
            href="#home"
            onClick={(e) => e.preventDefault()}
            className="mr-2"
          >
            {getBrandText()}
          </Navbar.Brand>
        </div>
        {/* <Button
            variant="dark"
            className="d-lg-none btn-fill d-flex justify-content-center align-items-center  p-2"
            onClick={mobileSidebarToggle}
          >
            <i className="fas fa-ellipsis-v"></i>
            
          </Button> */}
          <Navbar.Toggle style={{backgroundColor:'transparent',color:'rgba(0,0,0,.5)',borderColor:'rgba(0,0,0,.5)',padding:"6px 9px 5px 9px",borderWidth:"0px"}} aria-controls="basic-navbar-nav" className="mr-2">
          <i className="fas fa-ellipsis-v"></i>
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav">
          
          <Nav className="ml-auto" navbar>
            
          <Dropdown as={Nav.Item}>
              <Dropdown.Toggle
                as={Nav.Link}
                data-toggle="dropdown"
                id="dropdown-67443507"
                variant="default"
                className="m-0"
               
              >
                <i className="nc-icon nc-planet" style={{fontSize:"20px"}}></i>
                {
                  notifications?<span className="notification">{notifications.length}</span>:""
                }
                <span className="d-lg-none ml-1">Notification</span>
              </Dropdown.Toggle>
              {
                notifications?<Dropdown.Menu>
                {
                  notifications.length===0?<p style={{padding:"15px 20px 0px 20px",color:"#9A9A9A"}}>No notifications</p>:""
                }
                {
                 notifications.map((notification)=>{
                   return (
                    <Dropdown.Item
                   
                    className=""
                    
                    onClick={(e) => gotoNotificationPage(e)}
                  ><i style={{marginTop:'0px'}} className="nc-icon nc-bell-55"></i>
                    {notification.title.substr(0,20)}...&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Dropdown.Item>
                   )
                 }) 
                }
              </Dropdown.Menu>:""
              }
            </Dropdown>

              

            <Dropdown as={Nav.Item} >
              <Dropdown.Toggle
              
                aria-expanded={false}
                aria-haspopup={true}
                as={Nav.Link}
                data-toggle="dropdown"
                id="navbarDropdownMenuLink"
                variant="default"
                className="m-0"
              >
                <span className="no-icon">
                <i className="nc-icon nc-circle-09" style={{fontSize:"20px"}}/>
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu aria-labelledby="navbarDropdownMenuLink">
              <Dropdown.Item
                 
                  onClick={(e) => userProfile(e)}
                >
                  User Profile
                </Dropdown.Item>

                <Dropdown.Item
                  
                  onClick={logout}
                >
                  <span className="no-icon" >Log Out</span>
              
                </Dropdown.Item>
                
              </Dropdown.Menu>
            </Dropdown>

            <Nav.Item>
              <Nav.Link
                className="m-0"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-icon"></span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className="m-0"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-icon"></span>
              </Nav.Link>
            </Nav.Item>
            
            
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;

