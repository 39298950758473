import React, { useState, useEffect } from "react";
import { BASE_URL } from "apiUrl";
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import moment from "moment";
const MyPaginate = styled(ReactPaginate).attrs({

})`
  margin-bottom: 2rem;
  margin-top:30px;
  display:flex;
  justify-content: center;
  list-style-type: none;
  padding: 0 5rem;
  li a {
    margin: 4px;
    float:left;
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.selected a {
    background-color: #1DC7EA;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table,
  NavDropdown
} from "react-bootstrap";
import Sidebar from "components/Sidebar/Sidebar";
import toast from "../utils/toast";
import sidebarImage from "assets/img/sidebar-3.jpg";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import { Link } from "react-router-dom";
import routes from "routes.js";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import EmployeeTransactionHistory from "./employeeTransactionHistory";

function bankTransactionHistory() {

  const [image, setImage] = React.useState(JSON.parse(localStorage.getItem('sidebarImage')) || sidebarImage);
  const [color, setColor] = React.useState(JSON.parse(localStorage.getItem('sidebarColor')) || "#00000082");

  const [hasImage, setHasImage] = React.useState(true);
  const [data, setdata] = useState([]);
  const [salaries, setSalaries] = useState([])
  const [expenses, setExpenses] = useState([])
  const mainPanel = React.useRef(null);
  const [submited, setSubmited] = useState(false)
  let history = useHistory();
  let { id } = useParams();
  console.log(id)


  React.useEffect(() => {
    TransactionHistory();
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);



  async function TransactionHistory() {
    let item = { id };
    const token = localStorage.getItem('token')
    const JWTToken = JSON.parse(token)
    const url = `${BASE_URL}/api/bank-management/bank/get-bank-transactions-history/${id}/`

    let result = await fetch(url, {
      method: "GET",

      headers: {
        "Authorization": "Bearer " + JWTToken,
        "Content-Type": 'application/json',
        "Accept": 'application/json'
      }
    })
    result = await result.json().then((resp) => {
      console.log(resp)
      setdata(resp)
      setSalaries(resp.salaries)
      setExpenses(resp.expenses)
      console.log(resp)
      setSubmited(!submited)
    });
    console.log(result);

  }

  const SalaryTransaction = ({ currentItems }) => {
    return <>
      {currentItems.map((item) =>
        <tr>
          <td>{item.account_no}</td>
          <td>{item.bank_name}</td>
          <td>{item.salary_amount}</td>
          <td>{item.description}</td>
          <td>{item?.paid_at ? moment(item.paid_at.split('Z')[0]).format('MMMM DD, YYYY h:mm:ss A') : ''} </td>

        </tr>
      )}
    </>
  }
  const PaginateSalariesTransactionHistory = ({ itemsPerPage }) => {
    const [currentItems, setCurrentItems] = useState(salaries);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(salaries.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(salaries.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % salaries.length;
      setItemOffset(newOffset);
    };

    return (
      <>




        <div class="tableWrap">
          <Table className="table-hover table-striped">
            <thead>
              <tr>
                <th className="border-0">Account # (Paid To)</th>
                <th className="border-0">Bank (Paid To) </th>
                <th className="border-0">Total Amount</th>
                <th className="border-0">Description</th>
                <th className="border-0">Date/Time</th>

              </tr>
            </thead>
            <tbody>

              <SalaryTransaction currentItems={currentItems} />

            </tbody>
          </Table>
        </div>
        <MyPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />

      </>
    );
  }

  const ExpensesTransaction = ({ currentItems }) => {
    return <>
      {currentItems.map((item) =>
        <tr>

          <td>{item.amount}</td>
          <td>{item.description}</td>
          <td>{item?.date ? moment(item.date.split('Z')[0]).format('MMMM DD, YYYY h:mm:ss A') : ''} </td>

        </tr>
      )}
    </>
  }

  const PaginateExpensesTransactionHistory = ({ itemsPerPage }) => {
    const [currentItems, setCurrentItems] = useState(expenses);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(expenses.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(expenses.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % expenses.length;
      setItemOffset(newOffset);
    };

    return (
      <>




        <div class="tableWrap">
          <Table className="table-hover table-striped">
            <thead>
              <tr>
                <th className="border-0">Total Amount</th>
                <th className="border-0">Description</th>
                <th className="border-0">Date/Time</th>

              </tr>
            </thead>
            <tbody>

              <ExpensesTransaction currentItems={currentItems} />

            </tbody>
          </Table>
        </div>
        <MyPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />

      </>
    );
  }



  return (
    <>
      <div className="wrapper">
        <Sidebar activeLink="/bank" color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">


            <Container fluid>
              <Row>
                <Col md="12">
                  <Card className="strpied-tabled-with-hover">
                    <Card.Header>
                      <Card.Title as="h4">Salaries Transaction History</Card.Title>
                    </Card.Header>
                    <Card.Body className="table-full-width table-responsive px-0">

                      <PaginateSalariesTransactionHistory itemsPerPage={15} />

                    </Card.Body>
                  </Card>
                </Col>

              </Row>
            </Container>

            <Container fluid>
              <Row>
                <Col md="12">
                  <Card className="strpied-tabled-with-hover">
                    <Card.Header>
                      <Card.Title as="h4">Expenses Transaction History</Card.Title>
                    </Card.Header>
                    <Card.Body className="table-full-width table-responsive px-0">

                      <PaginateExpensesTransactionHistory itemsPerPage={15} />

                    </Card.Body>
                  </Card>
                </Col>

              </Row>
            </Container>
            <Container fluid>
              <Row>
                <EmployeeTransactionHistory bankID={id} />
              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </div>


    </>
  );
}

export default bankTransactionHistory;
